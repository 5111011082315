.select-darker {
  --caret-color: var(--color-primary-darker);
  --caret-weight: bold;
  --border-color: var(--color-primary-darker);
}

.select-emphasize-disabled {
  --caret-color: var(--color-primary-darker);
  --caret-weight: bold;
  --border-color: var(--color-grey-darkest);
}
