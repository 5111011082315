.aula-menu {
  display: flex;
  flex-direction: row;
  width: var(--menu-item-width);
  text-align: center;
  font-size: 0.65rem;
  position: fixed;
  bottom: 0;
  z-index: $aula-menu-mobile-z-index;

  @include breakpoint-lg() {
    flex-direction: column;
    top: var(--menu-top-bar-height);
    z-index: $aula-menu-desktop-z-index;
  }

  @include breakpoint-tablet-portrait() {
    position: fixed;
    bottom: 0;
    width: 100%;
    top: unset;
    flex-wrap: inherit;
  }

  @include breakpoint-lg-down() {
    > * {
      flex-grow: 1;
    }
  }

  .badge-notification {
    top: -5px;
    left: -10px;

    .badge {
      width: 20px;
      height: 20px;
    }
  }
  .menu-text {
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 5px;
    text-transform: capitalize;
  }

  .menu-link {
    --aula-badge-box-shadow-color: var(--color-grey-base);
    background-color: var(--menu-link-background-color);
    text-decoration: none;
    position: relative;
    height: var(--menu-item-height);
    color: var(--color-primary-darker);
    flex-shrink: 0;

    &:hover {
      --aula-badge-box-shadow-color: var(--color-grey-dark);
      background-color: var(--color-grey-dark);
    }

    &.router-link-active:not(:hover) {
      --aula-badge-box-shadow-color: var(--color-primary-darker);
    }

    &.menu-administration {
      @include breakpoint-lg-down() {
        display: none;
      }
    }
  }

  .menu-icon {
    font-size: var(--menu-icon-size);
    display: inline-block;
    object-fit: contain;
  }

  img.menu-icon {
    --menu-icon-size: var(--default-menu-icon-size);
    width: var(--menu-icon-size);
  }

  .menu-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100%;

    &.more:hover {
      --aula-badge-box-shadow-color: var(--color-primary-darker);
      color: var(--color-white);
    }

    &.more {
      --aula-badge-box-shadow-color: var(--color-grey-darker);
      background-color: var(--color-grey-darker);
      border: 0;
      cursor: pointer;
      flex-shrink: 0;
      height: 55px;

      @include breakpoint-lg() {
        width: 100%;
      }

      @include breakpoint-xl() {
        width: 100%;
        line-height: 1.5;
        height: 66px;
      }

      @include breakpoint-tablet-portrait() {
        height: 80px;
      }

      .btn {
        &:hover {
          text-decoration: none;
        }
      }

      &:hover,
      &:focus-visible,
      &.show {
        .theme-employee & {
          background-color: $color-primary-darker-employee;
        }

        .theme-guardian & {
          background-color: $color-primary-darker-guardian;
        }

        .theme-child & {
          background-color: $color-primary-darker-child;
        }

        .theme-admin & {
          background-color: $color-primary-darker-admin;
        }

        .icon-Aula_elipses {
          color: $color-white;
        }
      }
    }
  }

  .more-menu-item {
    padding: 25px 20px 25px 35px;
    position: relative;
    border-bottom: 1px solid $color-grey-base;
    font-size: 14px;
    cursor: pointer;

    .badge-notification {
      top: 20px;
      left: 30px;
    }

    .theme-employee & {
      color: $color-primary-darker-employee;
    }

    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }

    .theme-child & {
      color: $color-primary-darker-child;
    }

    .theme-admin & {
      color: $color-primary-darker-admin;
    }

    &:hover, &:focus-visible {
      background: $color-primary-darker-guardian;
      color: #fff;
    }

    .menu-icon {
      font-size: 1.7rem;
      vertical-align: middle;
      display: inline-block;
      width: 40px;
      margin-right: 15px;
      text-align: center;
    }

    .more-menu-text {
      display: inline;
      text-transform: capitalize;
    }
  }

  .submenu-item {
    padding: 15px 20px;
    border-bottom: 1px solid $color-grey-base;
    line-height: 22px;

    &:hover {
      .theme-employee & {
        background: $color-primary-darker-employee;
      }

      .theme-guardian & {
        background: $color-primary-darker-guardian;
      }

      .theme-child & {
        background: $color-primary-darker-child;
      }

      .theme-admin & {
        background: $color-primary-darker-admin;
      }

      color: #fff;
    }
  }

  .new-content-icon {
    position: absolute;
    font-weight: bold;
    padding: 0 7px;
    height: 24px;
    background: $color-primary-darker;
    line-height: 20px;
    left: 0;
    bottom: 6px;
    font-size: 0.75rem;
    color: $color-grey-base;
    border-radius: 12px;
    border: solid 2px $color-grey-base;

    @include breakpoint-lg() {
      left: 29px;
      bottom: 49px;
    }
  }

  .menu-link:hover .new-content-icon {
    color: $color-grey-dark;
    border: solid 2px $color-grey-dark;
  }

  .router-link-active .new-content-icon {
    color: $color-primary-darker !important;
    border: solid 2px $color-primary-darker !important;
    background-color: $color-white !important;
  }

  .router-link-active .menu-item,
  .router-link-exact-active .menu-item,
  .menu-link:focus-visible,
  .stepup-menu:focus-visible {
    color: var(--color-white);
  }

  .more {
    position: relative;

    &:hover,
    &:active {
      #more-menu {
        display: initial;
      }
    }
  }

  #more-menu {
    text-align: left;
    position: fixed;
    bottom: 55px;
    top: 58px;
    left: 0;
    width: 100%;
    background-color: $color-grey-dark;
    text-transform: uppercase;
    overflow: auto;

    @include breakpoint-lg() {
      position: absolute;
      width: 295px;
      left: var(--menu-item-width);
      top: 0;
      bottom: 0;
    }

    @include breakpoint-tablet-portrait() {
      width: 100%;
      left: 0;
      top: unset;
    }

    a {
      text-decoration: none;
    }

    &.hidden {
      display: none !important;
    }
  }
}

.mobile-page-navbar.navbar {
  background: $color-grey-base;
  border-radius: 0;
  padding: 0;
  border: none;
  align-content: flex-start;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-transform: translate3d(0, 0, 0);

  .navbar-nav {
    flex-direction: row;
  }

  @-moz-document url-prefix() {
    .navbar-nav {
      margin-top: 0;
    }
  }

  .nav-item {
    margin-bottom: 0;

    &:hover,
    &.active {
      .nav-link {
        background: $color-grey-dark;

        .theme-employee & {
          color: $color-primary-darker-employee;
        }

        .theme-guardian & {
          color: $color-primary-darker-guardian;
        }

        .theme-child & {
          color: $color-primary-darker-child;
        }

        .theme-admin & {
          color: $color-primary-darker-admin;
        }
      }
    }

    &.active {
      .nav-link::after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: $color-grey-dark;
        border-width: 10px;
        margin-left: -10px;
        z-index: 1002;
      }
    }

    .nav-link {
      min-width: 120px;
      padding: 14px;
      font-weight: normal;
      max-height: 48px;
      min-height: 48px;
      border-right: 1px solid $color-white;
      background: transparent;
      border-radius: 0;
      text-align: center;
      position: relative;

      .theme-employee & {
        color: $color-primary-darker-employee;
      }

      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }

      .theme-child & {
        color: $color-primary-darker-child;
      }

      .theme-admin & {
        color: $color-primary-darker-admin;
      }
    }
  }
}

#aula-posts {
  .mobile-page-navbar.navbar {
    @include breakpoint-lg() {
      display: none;
    }
  }
}

.calendar-drawer {
  .mobile-page-navbar.navbar {
    @include breakpoint-lg() {
      display: none;
    }
  }
}
//Theme styling
.theme-admin {
  .router-link-active .menu-item,
  .router-link-exact-active .menu-item {
    background-color: $color-primary-darker-admin !important;
  }

  .menu-item.show {
    background-color: $color-primary-darker-admin !important;
  }

  .menu-item:hover {
    background-color: $color-primary-light-admin !important;
    color: $color-primary-darker-admin !important;
  }

  .more-menu-item {
    &:hover, &:focus-visible {
      background: $color-primary-darker-admin;
    }
  }
}

.theme-guardian {
  .router-link-active .menu-item,
  .router-link-exact-active .menu-item {
    background-color: $color-primary-darker-guardian !important;
  }

  .menu-link:focus-visible,
  .menu-link .menu-item.show {
    background-color: $color-primary-darker-guardian !important;
  }

  .more-menu-item {
    &:hover, &:focus-visible  {
      background: $color-primary-darker-guardian;
    }
  }
}

.theme-employee {
  .router-link-active .menu-item,
  .menu-link:focus-visible,
  .stepup-menu:focus-visible,
  .router-link-exact-active .menu-item {
    background-color: $color-primary-darker-employee !important;
  }

  .menu-link:focus-visible,
  .menu-link .menu-item.show {
    background-color: $color-primary-darker-employee !important;
  }

  .more-menu-item {
    &:hover, &:focus-visible  {
      background: $color-primary-darker-employee;
    }
  }
}

.theme-child {
  .router-link-active .menu-item,
  .router-link-exact-active .menu-item {
    background-color: $color-primary-darker-child !important;
  }

  .menu-link:focus-visible,
  .menu-link .menu-item.show {
    background-color: $color-primary-darker-child !important;
  }

  .more-menu-item {
    &:hover, &:focus-visible  {
      background: $color-primary-darker-child;
    }
  }
}
