:root {
  --app-color-base: #{$app-color-base};

  --color-primary-darker-admin: #{$color-primary-darker-admin};
  --color-primary-dark-admin: #{$color-primary-dark-admin};
  --color-primary-base-admin: #{$color-primary-base-admin};
  --color-primary-light-admin: #{$color-primary-light-admin};

  --color-primary-darker-employee: #{$color-primary-darker-employee};
  --color-primary-dark-employee: #{$color-primary-dark-employee};
  --color-primary-base-employee: #{$color-primary-base-employee};
  --color-primary-light-employee: #{$color-primary-light-employee};

  --color-primary-darker-guardian: #{$color-primary-darker-guardian};
  --color-primary-dark-guardian: #{$color-primary-dark-guardian};
  --color-primary-base-guardian: #{$color-primary-base-guardian};
  --color-primary-light-guardian: #{$color-primary-light-guardian};

  --color-primary-darker-child: #{$color-primary-darker-child};
  --color-primary-dark-child: #{$color-primary-dark-child};
  --color-primary-base-child: #{$color-primary-base-child};
  --color-primary-light-child: #{$color-primary-light-child};

  --color-primary-darker: #{$color-primary-darker};
  --color-primary-dark: #{$color-primary-dark};
  --color-primary-base: #{$color-primary-base};
  --color-primary-light: #{$color-primary-light};

  --color-grey-dunkel: #{$color-grey-dunkel};
  --color-grey-darker: #{$color-grey-darker};
  --color-grey-dark: #{$color-grey-dark};
  --color-grey-medium: #{$color-grey-medium};
  --color-grey-base: #{$color-grey-base};
  --color-grey-light: #{$color-grey-light};
  --color-grey-modal-light: #{$color-grey-modal-light};
  --color-grey-lighter: #{$color-grey-lighter};
  --color-grey-dim: #{$color-grey-dim};

  --color-white: #{$color-white};
  --color-black: #{$color-black};
  --color-grey: #{$color-grey};
  --color-dark-grey: #{$color-dark-grey};
  --color-grey-darkest: #{$color-grey-darkest};
  --color-darkblue: #{$color-darkblue};
  --color-blue: #{$color-blue};
  --color-lightblue: #{$color-lightblue};
  --color-translucent-white: #{rgba($color-white, 0.9)};

  --color-help-text: #{$color-help-text};
  --color-error-text: #{$color-error-text};
  --color-conversation-left: #{$color-conversation-left};
  --color-picker-button: #{$color-picker-button};
  --color-picker-button-green: #{$color-picker-button-green};
  --color-picker-button-blue: #{$color-picker-button-blue};

  --color-alert: #{$color-alert};
  --color-background-color: #{$overlay-background-color};
  --color-icon-button: #{$color-icon-button};
  --color-attention: #{$color-attention};

  --color-form-border: #{$color-form-border};
  --color-input-focus: #{$color-input-focus};
  --color-dropdown-focus: #{$color-dropdown-focus};
  --color-date-text: #{$color-date-text};
  --color-date-weekday: #{$color-date-weekday};
  --color-select-all: #{$color-select-all};
  --color-input-focus-background: #{$color-input-focus-background};

  --color-read-message: #{$color-read-message};

  --sticky-header-z-index: 10; /* Must have a higher than checkbox z-index */
  --modal-z-index: #{$modal-z-index}; /* Must be lower than $sub-modal-z-index */
  --selected-media-card-opacity: 45%;

  --box-shadow-base: 0px 4px 12px rgba(0, 0, 0, 0.25);
  --color-light-button: #{$color-primary-base};

  --default-layout-padding: 48px;

  --subscription-min-width-standard: 400px;
  --subscription-min-width-compact: 400px;
  --conversation-min-width: 448px;

  /* Menu */
  --menu-link-background-color: var(--color-grey-base);
  --menu-top-bar-height: 60px;
  --menu-top-bar-button-width: 60px;
  --default-menu-icon-size: 30px;
  --default-profile-image-size: 48px;

  --scrollbar-width: 11px;

  --ease-in-out: cubic-bezier(.4,0,.2,1);

  /* Sidebar */
  --sidebar-background: var(--color-white);
  --sidebar-foreground: var(--color-primary-darker);
  --sidebar-primary: var(--color-grey-base);
  --sidebar-primary_foreground: var(--color-primary-darker);
  --sidebar-accent: var(--color-grey-light);
  --sidebar-accent-foreground: var(--color-primary-darker);
  --sidebar-secondary: var(--color-grey-dark);
  --sidebar-secondary-foreground: var(--color-primary-dark);
}

.theme-employee {
  --color-primary-darker: #{$color-primary-darker-employee};
  --color-primary-dark: #{$color-primary-dark-employee};
  --color-primary-base: #{$color-primary-base-employee};
  --color-primary-light: #{$color-primary-light-employee};
}

.theme-guardian {
  --color-primary-darker: #{$color-primary-darker-guardian};
  --color-primary-dark: #{$color-primary-dark-guardian};
  --color-primary-base: #{$color-primary-base-guardian};
  --color-primary-light: #{$color-primary-light-guardian};
}

.theme-child {
  --color-primary-darker: #{$color-primary-darker-child};
  --color-primary-dark: #{$color-primary-dark-child};
  --color-primary-base: #{$color-primary-base-child};
  --color-primary-light: #{$color-primary-light-child};
}

.early-student {
  --menu-link-background-color: transparent;
}

.theme-admin {
  --color-primary-darker: #{$color-primary-darker-admin};
  --color-primary-dark: #{$color-primary-dark-admin};
  --color-primary-base: #{$color-primary-base-admin};
  --color-primary-light: #{$color-primary-light-admin};
}

html {
  overflow-x: hidden;
}

body {
  background-color: $color-grey-light;

  &.theme-employee {
    color: $color-primary-darker-employee;
  }

  &.theme-guardian {
    color: $color-primary-darker-guardian;
  }

  &.theme-child {
    color: $color-primary-darker-child;
  }

  &.theme-admin {
    color: $color-primary-darker-admin;
  }

  font-size: 15px;
}

body,
table td,
table th {
  font-family: 'Lato', sans-serif;
}

pre.reset-pre {
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  white-space: break-spaces;
}

.noscroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

.cursor-default {
  cursor: default !important;
}

a {
  color: var(--color-base, var(--color-primary-darker));

  &:hover {
    text-decoration: none;
    color: var(--color-base, var(--color-primary-darker));
  }
}

mark {
  &.text__highlight {
    background: unset;
  }

  padding: unset;
}
.page-title {
  outline: none;
}
.aula-modal-open,
.drawer-open {
  overflow: hidden;
  width: 100%;
  z-index: $aula-modal-open-z-index;
  position: fixed;
  &[data-overflowed] {
    padding-right: var(--scrollbar-width);
    @-moz-document url-prefix() {
      padding-right: 0;
    }
  }
}

.aula-open-do-not-reset-position {
  position: relative;
}

.highlight,
.text__highlight {
  font-weight: bolder !important;
  color: var(--highlighted-color, var(--color-primary-dark));

  .message-from-me .message-body & {
    color: $color-white;
  }
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-weight: 800;
}

h1,
.h1 {
  font-size: 32px;

  @include breakpoint-xl-down() {
    font-size: 24px;
  }
}

h2,
.h2 {
  font-size: 22px;

  @include breakpoint-sm-down() {
    font-size: 16px;
  }
}

.small-text {
  font-size: 12px;
}

.fs-8 {
  font-size: 8px;
}

button {
  cursor: pointer;
}

.row-no-margin-right {
  margin-right: 0;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.margin-right {
  margin-right: 10px;
}

.margin-left {
  margin-left: 10px;
}

.margin-top {
  margin-top: 10px;
}

.form-margin-bottom {
  margin-bottom: 10px;

  @include breakpoint-sm-down() {
    margin-bottom: 0;
  }
}

.margin-bottom {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.mr-28 {
  margin-right: 28px;
}

.pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.row-no-margin {
  margin-left: 0;
  margin-right: 0;
}

.no-mark {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.modal {
  text-align: left;
  color: $color-primary-darker;
}

.hide-mobile-down {
  @include breakpoint-lg-down() {
    display: none;
  }
}

.hide-mobile-up {
  @include breakpoint-lg() {
    display: none;
  }
}

.hide-tablet-down {
  @include breakpoint-lg-down() {
    display: none !important;
  }
}

.hide-tablet-up {
  @include breakpoint-lg() {
    display: none;
  }
}

.hide-desktop-up {
  @include breakpoint-xl() {
    display: none;
  }
}

.hide-desktop-down {
  @include breakpoint-xl-down() {
    display: none;
  }
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.p-r-35 {
  padding-right: 35px;
}

strong {
  font-weight: 700;
}

.red-border {
  border: 1px solid #b50050 !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hide {
  display: none;
}

.tooltip.b-tooltip {
  opacity: 1;
  &.bs-tooltip-auto[x-placement^='left'] .arrow::before,
  &.bs-tooltip-left .arrow::before {
    border-left-color: var(--color-white);
  }

  &.bs-tooltip-auto[x-placement^='right'] .arrow::before,
  &.bs-tooltip-right .arrow::before {
    border-right-color: var(--color-white);
  }

  &.bs-tooltip-auto[x-placement^='top'] .arrow::before,
  &.bs-tooltip-top .arrow::before {
    border-top-color: var(--color-white);
  }

  &.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: var(--color-white);
  }

  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: $group-tooltip-background;
    max-width: 300px !important;
    min-width: 150px !important;
    box-shadow: var(--box-shadow-base);
    color: var(--color-primary-darker);
  }
}

.headline-margin-bottom {
  margin-bottom: 30px;
}

.headline-margin-top {
  margin-top: 30px;
}

#focus-element,
#focus-element-adm {
  height: 0;
  width: 0;
  border: transparent;
  background: transparent;
  position: absolute;
  z-index: -1;
}

.picker-dialog {
  z-index: 1061 !important;
}

.datepicker-weeknumbers {
  position: absolute;
  z-index: 1001;
  top: 46px;
  left: -42px;
  list-style-type: none;
  color: #828993;
  font-size: 10px;
}

.datepicker-weeknumbers-seperator {
  position: absolute;
  z-index: 1001;
  top: 42px;
  left: 16px;
  height: calc(100% - 50px);
  border-right: 1px solid $color-grey-darker;
}

.hoverable-overlay {
  position: relative;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--color-primary-dark);
    transition: opacity 150ms ease-in;
    opacity: 0;
  }
  &:not([data-disabled]):hover::before {
    opacity: 0.2;
  }
  &[data-disabled] {
    cursor: default;
  }
}

.b-toaster.b-toaster-top-right .b-toaster-slot {
  max-width: 250px;
  top: 68px;
}
.b-toast-primary.b-toast-solid .toast {
  border: none;
  color: var(--color-white);
  max-width: 250px;
  background-color: var(--color-primary-base);
  .toast-header {
    background-color: var(--color-primary-base);
    color: var(--color-white);
    border: none;
    button.close {
      color: var(--color-white);
    }
  }
  .toast-body {
    padding-top: 0;
    padding-bottom: 24px;
    &::before {
      content: '\e91f';
      font-family: 'aula', Lato, serif;
      font-size: 1.5rem;
      line-height: 1rem;
      vertical-align: middle;
      padding-right: 4px;
    }
  }
}
.selectable-text {
  cursor: auto;
  user-select: text;
}
