.color-grey-light {
  --color-base: var(--color-grey-light);
}

.color-grey-base {
  --color-base: var(--color-grey-base);
}

.color-white {
  --color-base: var(--color-white);
}

.color-primary-darker {
  --color-base: var(--color-primary-darker);
}

.color-grey-dark {
  --color-base: var(--color-grey-dark);
}
