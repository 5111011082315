.search-container {
  background: $color-grey-base;
  font-size: 13px;
  padding: 10px;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
}

.table {
  color: var(--color-primary-darker);
}

.b-table {
  font-size: 14px;
  color: var(--color-primary-darker);
  border-width: 0;

  &.table {
    thead {
      tr:first-child {
        th:first-child {
          border-top-left-radius: 8px;
        }
        th:last-child {
          border-top-right-radius: 8px;
        }
      }
    }
    tbody {
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 8px;
        }
        td:last-child {
          border-bottom-right-radius: 8px;
        }
      }
      .table-active > td {
        background-color: var(--color-primary-base);
      }
    }
  }

  &.table > caption {
    caption-side: top;
    color: var(--color-primary-darker);
    font-weight: 900;
    font-size: 1rem;
    text-transform: uppercase;
  }

  &.table > tfoot > tr > td,
  &.table > tfoot > tr > th,
  &.table > thead > tr > td,
  &.table > thead > tr > th {
    position: relative;
    border: 2px solid transparent;
    vertical-align: top;

    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
    &:focus-visible[aria-sort]::after {
      box-shadow: 0 0 3px 1px var(--color-primary-darker);
    }


    &[aria-sort] {
      background-position: unset;
      background-image: none;
      padding-right: calc(0.75rem + 1rem);

      .icon-Aula_down-arrow {
        right: 28px;
      }

      &::after {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 16px;
        bottom: 16px;
        padding: 0 4px;
        right: 0.35em;
        font-size: 14px;
        color: var(--color-primary-darker);
        line-height: inherit;
        border-radius: 4px;
        font-family: 'aula';
        font-weight: normal;
        content: "\e992";
        speak: none;

        &:hover {
          background-color: var(--color-white);
        }
      }

      &[aria-sort='ascending']::after {
        opacity: 1;
        content: '\e987';
      }

      &[aria-sort='descending']::after {
        opacity: 1;
        content: '\e988';
      }
    }
  }

  th {
    background: $color-grey-base;
    padding: 17px;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: bold;
    font-size: 15px;
    .dropdown-menu {
      text-transform: none;
      color: var(--color-primary-darker);
      padding: 12px 16px;
      cursor: default;
      .custom-control:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }

  tbody tr {
    &:focus-visible {
      outline: 0;
      box-shadow: 0 0 2px 0.01rem var(--color-primary-darker);
    }

    pointer-events: var(--table-pointer-event, unset);
    background-color: $color-white;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: 150ms;

    td {
      position: relative;
      padding: 17px;
      border-top: none;
      border-right: 2px solid #f6f7f8;
      border-bottom: 2px solid #f6f7f8;
      cursor: pointer;
      vertical-align: middle;

      &:last-child {
        border-right: none;
      }

      .btn-link {
        text-decoration: var(--table-btn-link-decoration, underline);

        &.has-icon {
          text-decoration: none;
        }

        &:hover {
          background-color: $color-grey-base;
        }
      }

      i:not(.icon-Aula_exclamation_sign) {
        font-size: var(--table-icon-font-size, 20px);
      }
    }

    td::after {
      content: '';
      width: 2px;
      height: 2px;
      background-color: transparent;
      position: absolute;
      right: -2px;
      bottom: -2px;
    }

    &:hover,
    &:focus-visible {
      background: var(--color-grey-base);
    }
  }

  .b-table-empty-row {
    &:hover {
      background-color: var(--color-white);
    }
    td {
      cursor: default;
    }
  }

  .table-filtering {
    position: absolute;
    max-height: 500px;
    overflow: auto;
    right: 20px;
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .custom-control.custom-checkbox {
      cursor: pointer;

      .custom-control-input:active ~ .custom-control-indicator,
      .custom-control-input:checked ~ .custom-control-indicator {
        background-color: transparent;
        color: $color-primary-light;
      }

      .custom-control-input:hover ~ .custom-control-indicator {
        color: $color-primary-light;
        background-color: transparent;
      }

      .custom-control-description {
        font-weight: normal !important;
      }
    }

    &.datepicker {
      right: unset;
      left: 20px;
    }
  }
}

//Responsive table (documents, reported content)
.holiday-closed-days-container,
.specific-opening-hour-container,
.general-opening-hour-container,
.employee-vacation-request-container,
.guardian-vacation-request-container,
.guardian-vacation-announcement-container,
.aula-documentsSecureList-container,
.aula-documentsCommonList-container,
.reported-content-container,
.group-administration-container,
.group-members-container {
  .table {
    border-collapse: collapse;
    display: table;
    width: 100%;
    font-size: 14px;

    .table-row {
      background: #fff;
      display: table-row;
      cursor: pointer;

      &.disabled {
        background: $color-grey-darker;
        cursor: not-allowed;

        &:hover {
          .table-cell,
          .table-cell-small {
            background: $color-grey-darker !important;
          }
        }
      }

      &.header,
      &.body {
        .table-cell-small {
          border: solid 2px $color-grey-light;
          display: table-cell;
          padding: 15px;

          .table-cell {
            display: block;
            padding: 5px 0;
            border: 0;
          }

          @include breakpoint-xl() {
            display: contents;

            .table-cell {
              display: table-cell;
              padding: 15px;
              border-right: solid 2px transparent;
              border-top: solid 2px $color-grey-light;
              border-bottom: solid 2px $color-grey-light;
            }
          }
        }
      }

      &.header {
        background-color: $color-grey-dark;
        display: none;

        @include breakpoint-xl() {
          display: table-row;
        }

        .table-cell {
          display: table-cell;
          padding: 15px;
          border-right: solid 2px transparent;
          border-top: solid 2px $color-grey-light;
          border-bottom: solid 2px $color-grey-light;
          text-transform: uppercase;
          white-space: nowrap;

          &.icon {
            text-align: center;

            span {
              font-size: 18px;
              height: 35px;
              width: 35px;
              display: inline-block;
              text-align: center;
              line-height: 35px;
              border-radius: 50%;
              color: #fff;
            }
          }
        }
      }

      &.body {
        .table-cell {
          border: solid 2px $color-grey-light;
          display: table-cell;
          position: relative;
          padding: 15px;
          vertical-align: middle;

          &.icon {
            background-color: $color-grey-base;
            vertical-align: middle;
            text-align: center;
            padding-top: 20px;
            width: 40px;

            .icons {
              position: relative;
              text-align: center;
              display: inline-block;
              width: 30px;

              .user {
                position: absolute;
                right: -2px;
                bottom: 15px;
                background: $color-grey-base;
                border: solid 3px $color-grey-base;
                border-radius: 50%;
              }

              .icon {
                font-size: 26px;
              }

              .name {
                display: block;
                margin-top: 3px;
                font-size: 9px;
              }
            }
          }

          &.actions {
            width: 40px;
            text-align: right;
            padding: 8px 0;
          }

          b {
            font-weight: 900;
          }

          .icon-Aula_elipses {
            display: block;
            margin: 15px;
            cursor: pointer;
          }

          .icon-Aula_write {
            display: block;
            cursor: pointer;
          }
        }

        &:hover {
          .table-cell,
          .table-cell-small {
            background: $color-grey-lighter;

            &.icon {
              background: $color-grey-dunkel;

              .icons {
                .user {
                  background: $color-grey-dunkel;
                  border: solid 3px $color-grey-dunkel;
                }
              }
            }

            b {
              font-weight: 900;
            }
          }
        }
      }
    }

    .item {
      padding: 3px 0;
    }

    .collapsed-only,
    .item span:first-child {
      @include breakpoint-xl() {
        display: none !important;
      }
    }

    .not-collapsed {
      display: none !important;

      @include breakpoint-xl() {
        display: table-cell !important;
      }
    }

    /deep/ .dropdown-select {
      padding: 0;

      .dropdown-item {
        padding: 0.6rem;
      }
    }

    /deep/ .dropdown-menu,
    /deep/ .dropdown-menu-right {
      left: auto !important;
      right: -15px;
      top: -6px !important;
    }
  }
}

.b-pagination {
  &.pagination {
    gap: 4px;
  }
  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child .page-link {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .page-link {
      font-weight: bold;
      border: none;
      border-radius: 0;
      background-color: #e9eef1;
      color: var(--color-primary-darker);

      &:hover {
        background-color: #2091a2;
        color: #fff;
      }
    }

    &.active {
      color: #fff;

      .page-link {
        background-color: var(--color-primary-darker);
        color: #fff;
      }
    }
  }
}

// For presence locations only
.presence-locations-results {
  .b-table {
    tbody tr {
      &:hover {
        .presence-location-symbol {
          background-color: #d9e3e9;
        }

        .icon {
          border: 7px solid #e9eef1;
        }
      }
    }
  }

  td {
    &.presence-location-description {
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      max-width: 600px;
    }

    &.presence-location-symbol {
      height: 80px;
      width: 80px;
      background-color: #e9eef1;
      padding: 15px;

      &:hover {
        background-color: #d9e3e9;
      }
    }
  }

  .icon {
    font-size: 28px;
    box-sizing: border-box;
    height: 72px;
    width: 72px;
    color: $color-white;
    padding: 7px 0 0;
    border: 7px solid #d9e3e9;
    background-color: #347da7;
    text-align: center;
    border-radius: 50%;

    &:hover {
      border: 7px solid #e9eef1;
    }
  }

  .btn {
    text-decoration: none;
  }
}

// For administrative authorities only
.administrative-authorities-results {
  padding: 20px;

  .btn {
    text-decoration: none;
  }
}

// For central communication channels only
.communication-channels-results {
  .b-table {
    th:first-child,
    td:first-child {
      font-weight: normal;
    }
  }

  .actions {
    text-align: right;
  }

  tbody tr {
    &:hover {
      button {
        text-decoration: none;
        color: $color-white;
      }
    }
  }
}

// For administrate resources only
.resource-category {
  .btn {
    text-decoration: none;
  }
}

.b-table-stacked-md > tbody > tr {
  @include breakpoint-sm-down() {
    background: $color-grey-light;
    border: solid 2px $color-grey-dark;

    &:hover {
      background: $color-primary-base;
      color: $color-white;
    }
  }
}

.table-document-templates {
  .table-responsive {
    overflow: visible;
  }
  .b-table {
    th {
      &.width-15 {
        width: 15%;
      }
      &.width-20 {
        width: 20%;
      }
    }
    td {
      word-break: break-all;
    }
  }
}
