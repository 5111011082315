// css only spinner from https://loading.io/css/

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-css {
  width: 200px;
  height: 200px;
  margin: auto;
  transform: scale(0.7);
}

.lds-ring {
  height: 100%;
  width: 100%;
}

.lds-ring > div {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  border: 10px solid var(--color-primary-base);
  border-color: var(--color-primary-base) transparent transparent transparent;
  animation: lds-ring 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-ring > div:nth-child(1) {
  border-color: transparent;
  animation: none;
}

.lds-ring > div:nth-child(3) {
  animation-delay: 0.19s;
}

.lds-ring > div:nth-child(4) {
  animation-delay: 0.38s;
}

.lds-ring > div:nth-child(5) {
  animation-delay: 0.57s;
}
