



































































































































































@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
@import '../../assets/scss/components/login/login.scss';
.login-by-id-provider {
  padding: 27px 50px;
  height: 371px;
  width: 371px;
  border: 1px solid $color-white;
  @include breakpoint-sm-down() {
    padding: 27px 40px;
    width: 100%;
  }
  .title {
    height: 29px;
    width: 216px;
    color: $color-white;
    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    margin-bottom: 50px;
  }
  .submit-button-container {
    margin-bottom: 50px;
    .submit-button {
      float: right;
      height: 50px;
      width: 100%;
      border-radius: 5px;
      background-color: $color-grey-lighter;
      color: $color-primary-dark;
    }
  }
  /deep/ .kommnue-ddown-list {
    .el-input__inner {
      border-bottom: 1px solid $color-white;
      border-radius: 0;
      background-color: transparent;
      color: $color-white;
      &:focus-visible {
        outline: 2px solid white !important;
      }
    }
    .el-input__inner::placeholder {
      color: $color-white !important;
    }
  }
  /deep/ .institution-ddown-list {
    .el-input__inner {
      border-bottom: 1px solid $color-white;
      border-radius: 0;
      background-color: transparent;
      color: $color-white;
      &:focus-visible {
        outline: 2px solid white !important;
      }
    }
    .el-input__inner::placeholder {
      color: $color-white !important;
    }
  }
}
