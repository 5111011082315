







































































@import '../assets/scss/core/breakpoints.scss';
@import '../assets/scss/core/variables.scss';

.toastr {
  position: fixed;
  top: 20px;
  right: 20px;
  border-radius: 8px;
  z-index: 9999;

  .alert {
    padding: 25px 50px 25px 25px;
    border: none;
    font-size: 14px;
    text-align: center;
    font-weight: normal;
    box-shadow: 0 2px 4px 0 rgba(166, 166, 166, 0.5);

    .toastr-content {
      align-items: center;
      display: flex;

      .circle {
        align-items: center;
        background: white;
        border-radius: 15px;
        display: flex;
        height: 30px;
        justify-content: center;
        left: 35px;
        margin-right: 20px;
        top: 35px;
        width: 30px;
      }
    }

    .close {
      opacity: 1;
      top: 10px;
      right: 10px;
      padding: 0px;
      color: $color-white;
      font-size: 14px;
    }
  }

  @include breakpoint-sm-down() {
    width: 94%;
    top: 2%;
    right: 3%;
  }

  .alert-dark {
    background-color: #c8d6df;
    border-color: #c8d6df;
  }

  .alert-success {
    color: $color-white;
  }

  .alert-warning {
    background-color: #c8d6df;
    .theme-employee & {
      color: $color-primary-darker-employee;
    }
    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }
    .theme-child & {
      color: $color-primary-darker-child;
    }
    .theme-admin & {
      color: $color-primary-darker-admin;
    }
    .circle i {
      color: $color-alert;
    }
  }
}

//Theme styling
.theme-guardian {
  .toastr {
    .alert-success {
      background-color: $color-primary-base-guardian;
    }
  }
}
.theme-child {
  .toastr {
    .alert-success {
      background-color: $color-primary-base-child;
    }
  }
}
.theme-employee {
  .toastr {
    .alert-success {
      background-color: $color-primary-base-employee;
    }
  }
}
.theme-admin {
  .toastr {
    .alert-success {
      background-color: $color-primary-base;
    }
  }
}

.main-toastr-container {
  .toastr {
    position: relative;
    top: 20px;
    right: 20px;
    border-radius: 8px;
    z-index: 9999;

    @include breakpoint-sm-down() {
      width: 100%;
      top: 0;
      right: 0;
      left: 0;
    }
  }
}
