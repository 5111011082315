:root {
  --tinymce-collection-selected-item-color: #222f3e;
  --tinymce-dialog-header-background-color: #{$color-primary-dark-employee};
  --tinymce-dialog-header-text-color: #fff;
  --tinymce-textfield-border-color: #c5c5c5;
  --tinymce-button-background-color: #{$color-primary-dark-employee};
  --tinymce-collection-enabled-item-background-color: transparent;
  --tinymce-collection-enabled-item-color: #222f3e;
  --tinymce-insert-table-picker-border-color: #f0f1f6;
  --tinymce-active-collection-item-background-color: #ededee;
  --tinymce-navigation-item-color: var(--color-primary-darker);
  --tinymce-grid-list-item-background-color: #{$color-grey-base};
}

.theme-employee {
  --tinymce-dialog-header-background-color: #{$color-primary-dark-employee};
  --tinymce-button-background-color: #{$color-primary-dark-employee};
}

.theme-guardian {
  --tinymce-dialog-header-background-color: #{$color-primary-dark-guardian};
  --tinymce-button-background-color: #{$color-primary-dark-guardian};
}

.theme-child {
  --tinymce-dialog-header-background-color: #{$color-primary-dark-child};
  --tinymce-button-background-color: #{$color-primary-dark-child};
}

.theme-admin {
  --tinymce-dialog-header-background-color: #{$color-primary-dark-admin};
  --tinymce-button-background-color: #{$color-primary-dark-admin};
}

.tox-button--secondary {
  --tinymce-button-background-color: transparent;
}

.aula_editor {

  .mce-branding {
    display: none;
  }

  .mce-tinymce.mce-container.mce-panel {
    border-radius: 5px;
    overflow: hidden;
  }

  .mce-container {
    border: 0;
    box-shadow: none;
  }

  .mce-toolbar {
    background-color: $color-grey-darker;
  }

  .mce-btn {
    border-radius: 5px;
    background-color: transparent;
    color: $color-darkblue;

    .mce-ico {
      .theme-employee & {
        color: $color-primary-darker-employee;
      }

      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }

      .theme-child & {
        color: $color-primary-darker-child;
      }

      .theme-admin & {
        color: $color-primary-darker-admin;
      }

      &.mce-i-bold::before {
        @extend [class^="icon-"];
        @extend .icon-texteditor_bold::before;
      }

      &.mce-i-italic::before {
        @extend [class^="icon-"];
        @extend .icon-texteditor_italics::before;

        position: relative;
        left: 1px;
      }

      &.mce-i-underline::before {
        @extend [class^="icon-"];
        @extend .icon-texteditor_underline::before;

        font-size: 15px;
        position: relative;
        top: -1px;
      }

      &.mce-i-underline::after {
        content: "";
        display: block;
        border-bottom: solid 2px $color-darkblue;
        position: relative;
        left: 1px;
      }

      &.mce-i-strikethrough::before {
        @extend [class^="icon-"];
        @extend .icon-texteditor_linethrough::before;
      }

      &.mce-i-blockquote::before {
        @extend [class^="icon-"];
        @extend .icon-texteditor_quote::before;

        font-size: 14px;
        position: relative;
        left: -3px;
      }

      &.mce-i-backcolor {
        background: none;

        &::before {
          @extend [class^="icon-"];
          @extend .icon-texteditor_highlight_colour::before;

          font-size: 11px;
          position: relative;
          top: -4px;
        }
      }

      &.mce-i-forecolor::before {
        @extend [class^="icon-"];
        @extend .icon-texteditor_letters::before;

        font-size: 11px;
        position: relative;
        top: -4px;
      }

      &.mce-i-letters::before {
        @extend [class^="icon-"];
        @extend .icon-texteditor_letters::before;

        font-size: 13px;
        position: relative;
        left: -1px;
        top: -1px;
      }

      &.mce-i-aligncenter::before {
        @extend [class^="icon-"];
        @extend .icon-texteditor_adjust_center::before;
      }

      &.mce-i-alignleft::before {
        @extend [class^="icon-"];
        @extend .icon-texteditor_adjust_left::before;
      }

      &.mce-i-alignright::before {
        @extend [class^="icon-"];
        @extend .icon-texteditor_adjust_right::before;
      }

      &.mce-i-bullist::before {
        @extend [class^="icon-"];
        @extend .icon-texteditor_bullets::before;
      }

      &.mce-i-numlist::before {
        @extend [class^="icon-"];
        @extend .icon-texteditor_numbers::before;
      }

      &.mce-i-emoticons::before {
        @extend [class^="icon-"];
        @extend .icon-texteditor_Emoji::before;
      }

      &.mce-i-media::before {
        @extend [class^="icon-"];
        @extend .icon-youtube::before;

        font-size: 21px;
        position: relative;
        left: -2px;
        top: -3px;
      }

      &.mce-i-document::before {
        @extend [class^="icon-"];
        @extend .icon-Aula_paper::before;

        font-size: 18px;
        position: relative;
        left: 2px;
        top: -1px;
      }

      &.mce-i-skoletube::before {
        @extend [class^="icon-"];
        @extend .icon-Aula_SkoleTube::before;
      }
    }

    &.mce-active {
      background-color: $color-grey-base;
    }
  }

  .mce-caret {
    border-top-color: $color-darkblue;
  }

  .mce-listbox > button {
    background-color: #fff;
    color: $color-darkblue;
    border-radius: 5px;
  }

  .mce-btn-group:not(:first-child) {
    border-left-color: $color-grey-base;
  }

  .mce-content-body {
    font-family: 'Lato', sans-serif;
    color: $color-primary-darker;
    font-size: 15px;
    overflow-wrap: break-word;
  }

  .mce-colorbutton {
    .mce-preview {
      margin-top: 5px;
      width: 31px;
      height: 3px;
      background: $color-darkblue;
    }

    .mce-caret {
      margin-top: 6px;
    }
  }
}

.mce-container *,
.mce-reset {
  max-width: 100vw !important;
}

.mce-combobox input {
  max-width: 60vw !important;
}

.tox .tox-toolbar {
  .tox-toolbar__group {
    gap: 2px;
  }
  .tox-split-button,
  .tox-tbtn {
    &:hover,
    &:focus-visible {
      background-color: var(--color-grey-base);
    }
    &.tox-tbtn--enabled {
      background-color: var(--color-primary-darker);
      svg {
        fill: var(--color-white);
      }
    }
    &.tox-tbtn--bespoke {
      background-color: var(--color-white);
    }
    &.tox-tbtn--select:focus-visible {
      outline: 1px solid var(--color-primary-dark);
    }
  }
  .tox-tbtn {
    margin-bottom: 0;
    border-radius: 5px;
  }
}

.aula_editor.folding {
  .tox-toolbar {
    display: none;

    .fold-fonts.active {
      background-color: var(--color-primary-darker);
      color: var(--color-white);
    }

    &:first-child {
      display: flex;
      background-color: $color-grey-dunkel;
    }
  }
}

.tox .tox-tbtn--bespoke {
  background-color: $color-white;
  margin-right: 4px;

  &:hover {
    background-color: $color-white;
  }

  &:last-child {
    margin-right: 0;
  }
}

.aula_editor_readonly {
  min-height: 107px;
  border-radius: 5px;
  background-color: $color-grey-light;
  border: 0.5px solid $color-form-border;
  padding: 12px 18px;
  cursor: not-allowed;
}

.mce-fade {
  background-color: #000 !important;
}

.mce-window {
  border-width: 0 !important;
}

.mce-window-head {
  .theme-employee & {
    background-color: $color-primary-base-employee;
  }

  .theme-guardian & {
    background-color: $color-primary-base-guardian;
  }

  .theme-child & {
    background-color: $color-primary-base-child;
  }

  .theme-admin & {
    background-color: $color-primary-dark-admin;
  }

  font-size: 20px;
  padding: 20px;

  .mce-title,
  .mce-ico {
    color: #fff !important;
    font-weight: normal;
    text-transform: uppercase;
  }

  .mce-ico {
    left: 4px;
    top: 20px;
    position: absolute;

    &::before {
      @extend [class^="icon-"];
      @extend .icon-Aula_close::before;
    }
  }
}

.mce-textbox {
  border-radius: 5px;
  color: $color-darkblue;
  outline: 0;
}

.mce-window {
  .mce-foot,
  .mce-foot .mce-container-body {
    height: auto !important;
  }

  .mce-foot {
    border: 0;

    .mce-container-body {
      padding: 20px 14px;
      box-sizing: border-box;
    }

    .mce-btn {
      position: static !important;
      float: right;
      margin-top: 10px;
      margin-right: 15px;
      background-color: transparent !important;
      border: 0;
      width: auto !important;

      button {
        background-color: #fff;
        border-radius: 5px;
        border-width: 0 !important;
        font-size: 15px;
        padding: 14px 44px;
        font-weight: 700;
      }
    }

    .mce-primary {
      button {
        .theme-employee & {
          background-color: $color-primary-light-employee;
        }

        .theme-guardian & {
          background-color: $color-primary-light-guardian;
        }

        .theme-child & {
          background-color: $color-primary-light-child;
        }

        .theme-admin & {
          background-color: $color-primary-dark-admin;
        }

        color: #fff;
      }
    }
  }
}

.mce-grid.emoti a {
  font-size: 26px;
  padding: 1px;
  display: inline-block;
  text-decoration: none !important;
}
