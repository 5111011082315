.search-result-header {
  height: 44px;
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 34px;
  outline: none;
  display: inline-flex;
  strong {
    margin-right: 15px;
  }

  .theme-employee & {
    color: $color-primary-darker-employee;
  }

  .theme-guardian & {
    color: $color-primary-darker-guardian;
  }

  .theme-child & {
    color: $color-primary-darker-child;
  }

  .theme-admin & {
    color: $color-primary-darker-admin;
  }

  @include breakpoint-lg-down() {
    display: none;
  }

  .keysearch {
    line-height: 1.2;
    margin-top: 0;
    margin-left: 10px;
    margin-bottom: 0.5rem;
    @include breakpoint-lg-down() {
      font-size: 24px;
    }
  }
}

.search-result-header-mobile {
  display: block;
  font-size: 12px;
  margin-top: var(--mobile-header-margin-top);
  margin-bottom: 10px;
  margin-inline-start: 0;
  margin-inline-end: 5px;

  &.with-search {
    --mobile-header-margin-top: 80px;
  }

  &.with-search-and-filter {
    --mobile-header-margin-top: 120px;
  }

  @include breakpoint-lg() {
    display: none;
  }
}

.search-result-header-mobile-variables {
  --mobile-header-margin-top: 10px;
}

.search-result-label {
  margin-top: 8px;
  @include breakpoint-lg-down() {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.number-of-results {
  display: none;

  @include breakpoint-lg() {
    display: block;
  }
}

.number-of-results-mobile {
  display: inline-block;

  @include breakpoint-lg() {
    display: none;
  }
}

.search-step-up-container {
  margin: 32px 10px 0 10px;

  .search-step-up {
   position: relative;
   display: flex;
   border: 1px solid var(--color-grey-dunkel);
   border-radius: 6px;

    .icon-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-basis: 20%;
      background: var(--color-grey-dunkel);

      .icon-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: var(--color-white);
      }
    }

   .step-up-text {
      padding: 12px 13px;

      .link-text {
        color: var(--color-primary-dark);
        cursor: pointer;
      }
    }
 }
}

.search-filter-container {
  width: 100%;
  height: 58px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  @include breakpoint-sm-up() {
    height: 60px;
  }

  @include breakpoint-lg() {
    height: auto;
    white-space: normal;
    padding: 10px;
    margin-left: 0;
  }

  @include breakpoint-lg-down() {
    background: $color-grey-light;
  }
}

.group-modal .search-filter-container {
  margin-left: 0;
}

.search-result-container {
  background-color: $color-grey-light;
  min-height: 500px;

  &.active {
    @include breakpoint-lg() {
      margin-right: 360px;
    }
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .search-result-item {
    border-radius: 0;
    min-height: 120px;
    margin: 0 5px 5px;
    border: none;
    font-size: 15px;
    color: $color-primary-darker;
    cursor: pointer;

    &.media {
      min-height: 200px;
    }

    &:hover {
      background-color: $color-grey-base;
    }

    @include breakpoint-lg() {
      margin-bottom: 15px;
      margin: 0 15px 15px 5px;
      max-width: 70vw;
      min-height: 130px;
    }

    .card-body {
      padding: 0;
      display: flex;
      flex-direction: var(--card-body-flex-direction, row);
      justify-content: space-between;

      @include breakpoint-sm-down() {
        width: 100%;
      }

      .search-result-icon-col {
        padding: 25px 15px 0 15px;
        display: none;
        width: 130px;
        min-width: 130px;
        margin: 0;
        text-align: center;
        color: white;
        font-size: 4em;

        &.media {
          display: block;
          height: 200px;
          width: 380px;
          padding: 0;

          @include breakpoint-sm-down() {
            width: 100%;
          }
        }

        .theme-guardian & {
          background-color: $color-primary-dark-guardian;
        }

        .theme-employee & {
          background-color: $color-primary-dark-employee;
        }

        .theme-child & {
          background-color: $color-primary-dark-child;
        }

        @include breakpoint-lg() {
          display: block;
        }

        .media-thumbnails {
          height: 100%;
          width: 100%;
          position: relative;
          overflow: hidden;

          img {
            height: 100%;
            object-fit: cover;
          }

          .thumbnail-box {
            width: 100%;
            height: 100%;
            cursor: pointer;
            position: absolute;
            overflow: hidden;
            min-width: 100px;

            .thumbnail-circle {
              position: absolute;
              border-radius: 50%;
              top: calc(50% - 65px);
              left: calc(50% - 65px);
              margin: 0;
              border: solid 5px rgba(255, 255, 255, 0.5);
              width: 130px;
              height: 130px;

              i {
                font-size: 40px;
                position: absolute;
                top: calc(50% - 20px);
                left: calc(50% - 15px);
              }
            }
          }
        }
      }

      .search-result-text-col {
        padding: 0 15px 10px 15px;
        flex: 1;
        max-width: 100%;
        overflow: hidden;

        @include breakpoint-sm-down() {
          padding-top: 15px;
        }

        @include breakpoint-sm-up() {
          min-height: 120px;
        }

        a {
          text-decoration: none;
        }
      }

      .search-result-right-col-text {
        padding: 15px;
        white-space: nowrap;
        text-align: right;
        width: auto;

        @include breakpoint-lg() {
          display: block;
          line-height: 25px;
        }

        @include breakpoint-sm-up() {
          margin-top: 25px;

          &.contact-info {
            min-width: 300px;
          }
        }

        @include breakpoint-sm-down() {
          &.contact-info {
            font-size: small;
          }
        }

        .search-result-group-button {
          padding-left: 22px !important;
          padding-right: 22px !important;
          width: 160px;
          font-size: small;

          @include breakpoint-lg() {
            font-size: 15px;
          }
        }
      }

      .card-header-text {
        .theme-guardian & {
          color: $color-primary-dark-guardian;
        }

        .theme-employee & {
          color: $color-primary-dark-employee;
        }

        .theme-child & {
          color: $color-primary-dark-child;
        }

        font-size: 16px;
        font-weight: bold;
        padding-top: 5px;
        margin-bottom: 5px;

        @include breakpoint-lg() {
          padding-top: 15px;
          font-size: 20px;
          margin-bottom: 0;
        }
      }

      .card-text {
        padding-bottom: 0;
        font-size: small;
        height: auto;

        @include breakpoint-lg() {
          font-size: 15px;
        }

        text-transform: none;
      }

      .btn-link {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .result-group-header {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 46px;

    @include breakpoint-lg() {
      height: 52px;
    }
  }

  .result-group-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;

    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    height: 35px;

    @include breakpoint-lg() {
      height: 69px;
      -webkit-line-clamp: 3;
    }
  }

  .search-result-contact-mobile {
    display: block;
    font-size: small;
    padding: 0;
    margin: 5px;

    @include breakpoint-lg() {
      display: none;
      margin: 15px 5px 5px 25px;
    }
  }

  .loadMoreResults {
    border-radius: 5px;
    margin: 0 0 5px;
    width: 100%;
    height: 56px;
    background-color: $color-grey-base;
    color: $color-primary-darker;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
  }

  .dropdown-select {
    padding-right: 8px;
    color: $color-primary-darker;

    .dropdown-toggle {
      height: unset;
    }

    .icon-arrow {
      font-size: 5px;
      position: relative;
      top: -1px;
      margin-left: 4px;
    }
    .icon-Aula_up-arrow {
      display: none;
    }

    &.show {
      .icon-Aula_down-arrow {
        display: none;
      }
      .icon-Aula_up-arrow {
        display: inline-block;
      }
    }

    .dropdown-item {
      font-size: 14px;

      &.active {
        font-weight: bold;
      }
    }
  }
}
