






























































@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

.help-box {
  display: inline-block;
  padding: 0;
}
