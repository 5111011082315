.btn-primary-light {
  color: var(--color-primary-darker);
  background-color: var(--color-grey-dark);
}

.btn-primary-darker {
  color: var(--color-white);
  background-color: var(--color-primary-darker);
}

.btn-light {
  color: var(--color-light-button);
  background-color: var(--color-white);
}

.btn-sharp {
  border-radius: 0;
}

.btn-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary-dark);
  color: var(--color-white);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  outline: 3px solid var(--color-white) !important;
  transition-property: transform, background-color;
  transition-duration: 200ms;
  transition-timing-function: var(--ease-in-out);

  &:focus-visible,
  &:hover {
    color: var(--color-white);
    outline: 3px solid var(--color-white);
    transform: scale(105%);
    background-color: var(--color-primary-darker);
  }
}
