
































.aula-img-variables {
  --width: auto;
  --height: auto;
  --object-fit: cover;
  --background-color: transparent;
}
.aula-img {
  object-fit: var(--object-fit);
  overflow: hidden;
  width: var(--width);
  height: var(--height);
  background-color: var(--background-color);
}
