











































































@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
@import '../../assets/scss/components/login/login.scss';
.login-by-id-provider {
  padding: 27px 50px;
  height: 371px;
  width: 371px;
  border: 1px solid var(--color-white);
  @include breakpoint-sm-down() {
    padding: 27px 40px;
    width: 100%;
  }
  .title {
    height: 29px;
    width: 216px;
    color: var(--color-white);
    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    margin-bottom: 50px;
  }
  .submit-button-container {
    margin-bottom: 50px;
    .submit-button {
      float: right;
      height: 50px;
      width: 100%;
      border-radius: 5px;
      background-color: var(--color-grey-lighter);
      color: var(--color-primary-dark);
    }
  }
  /deep/ .institution-down-list {
    --caret-color: var(--color-grey-base);

    .el-input__inner {
      border-bottom: 1px solid var(--color-white);
      border-radius: 0;
      background-color: transparent;
      color: var(--color-white);
      &:focus {
        outline: 2px solid var(--color-white) !important;
      }
    }
    .el-input__inner::placeholder {
      color: var(--color-white) !important;
    }
  }
}
