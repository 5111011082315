@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Baloo';
  src: url('../fonts/Baloo-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aula';
  src:
    url('../fonts/aula.ttf?si2d5q') format('truetype'),
    url('../fonts/aula.woff?si2d5q') format('woff'),
    url('../fonts/aula.svg?si2d5q#aula') format('svg');
  font-weight: normal;
  font-style: normal;
}
