.group-modal {
  padding-left: 0 !important;
  padding-right: 0 !important;

  @include breakpoint-xl() {
    margin-top: 35px;
  }

  .vue-drawer {
    .drawer {
      overscroll-behavior: contain;
    }
  }

  & > .modal-aula {
    z-index: $aula-group-modal-z-index;
    margin: 0 auto;
    max-width: 100%;
    height: 90vh !important;
    max-height: 90vh !important;

    @include breakpoint-xl() {
      max-width: 90%;
      margin: 0 auto;
      left: 5%;
      top: 35px;
      width: 90vw !important;
      max-width: 90vw !important;
      margin: 0;
      max-height: unset;
      transform: unset;
    }

    @include breakpoint-xl-down() {
      height: 100vh !important;
      max-height: 100vh !important;
      width: 100vw !important;
      max-width: 100vw !important;
      top: 0;
    }

    & > .modal-content {
      border-radius: 0;
      height: 100vh;

      & > .modal-body {
        padding: 0;
        padding-top: 0;
        background: transparent;
        max-height: unset;
        overflow: unset;

        @include breakpoint-xl() {
          padding: 15px;
        }
      }
    }
  }

  & > .modal-aula-backdrop {
    z-index: $aula-group-modal-backdrop-z-index;
  }

  @include breakpoint-xs-down() {
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent; /* make scrollbar transparent */
    }
  }

  text-align: left;
  color: $color-primary-darker;

  .group-page-component {
    border: none;
    border-radius: 0;

    & > .card-body {
      background-color: $color-grey-light;
      padding: 0;

      label:not(.custom-control-label) {
        font-weight: normal;
        margin: var(--group-page-label-margin, 30px 0 10px 0);

        @include breakpoint-sm-up() {
          font-weight: 700;
        }
      }
    }
  }

  .group-page-navbar.navbar {
    background: $color-grey-dark;
    border-radius: 0;
    padding: 0;
    border: none;
    align-content: flex-start;
    justify-content: flex-start;
    z-index: 1000;

    @include breakpoint-xl() {
      height: 60px;
      max-height: 60px;
      padding: 0 0 0 160px;
    }

    @include breakpoint-xl-down() {
      overflow-y: hidden;
      overflow-x: auto;
      background: none;

      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    @include breakpoint-tablet-landscape() {
      overflow-y: hidden;
      overflow-x: auto;
      height: 67px;
      max-height: 67px;
      background: none;
    }

    .navbar-nav {
      width: 100%;
      background: $color-grey-dark;
      flex-direction: row;

      @include breakpoint-xl() {
        height: 60px;
        width: unset;
      }

      .b-nav-dropdown {
        position: absolute;
        top: 0;

        .dropdown-menu {
          position: absolute;
          border-radius: 0;
          background: $color-grey-dark;
          border: 0;
          padding: 0;

          .dropdown-item {
            font-weight: normal;
            background: transparent;
            border-radius: 0;
            text-align: center;
            position: relative;
            color: $color-primary-darker;
            border-bottom: 2px solid #f6f7f8;
            height: 60px;
            min-width: 300px;
            text-align: left;
            font-weight: bold;
            padding-top: 15px;

            &:hover {
              color: #fff;

              .theme-employee & {
                background: $color-primary-darker-employee;
              }

              .theme-guardian & {
                background: $color-primary-darker-guardian;
              }

              .theme-child & {
                background: $color-primary-darker-child;
              }

              .theme-admin & {
                background: $color-primary-darker-admin;
              }
            }
          }
        }

        .dropdown-menu-right {
          right: 0;
          left: auto;
          z-index: 1050;
        }
      }

      .badge-notification {
        left: unset;
        right: 2px;
        top: 15px;
      }
      .dropdown-menu{
        .badge-notification {
          right: unset;
          left: 164px;
          top: 14px;
        }
      }
    }

    @-moz-document url-prefix() {
      .navbar-nav {
        margin-top: 0;
      }
    }

    .nav-item {
      margin-bottom: 0;
      width: max-content;
      &.has-badge {
        .nav-link {
          width: 200px;
        }
      }

      &:hover,
      &.active {
        .nav-link {
          background-color: var(--color-primary-darker);
          color: var(--color-white);
        }
      }
    }

    .nav-link {
      min-width: 120px;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 12px;
      font-weight: normal;
      max-height: 48px;
      min-height: 48px;
      border-right: 1px solid $color-white;
      background: transparent;
      width: max-content;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;

      @include breakpoint-xl-down() {
        background: $color-grey-dark;
      }

      @include breakpoint-tablet-landscape() {
        background: $color-grey-dark;
      }

      border-radius: 0;
      text-align: center;
      position: relative;

      .theme-employee & {
        color: $color-primary-darker-employee;
      }

      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }

      .theme-child & {
        color: $color-primary-darker-child;
      }

      .theme-admin & {
        color: $color-primary-darker-admin;
      }

      @include breakpoint-xl() {
        border: none;
        min-height: 60px;
        width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 18px;
        font-weight: bold;
      }
    }
  }
}

//Theme styling
.theme-admin {
  .group-modal > .modal-aula {
    background: $color-primary-base-admin;
  }
}

.theme-guardian {
  .group-modal > .modal-aula {
    background: $color-primary-base-guardian;
  }
}

.theme-employee {
  .group-modal > .modal-aula {
    background: $color-primary-base-employee;
  }
}

.theme-child {
  .group-modal > .modal-aula {
    background: $color-primary-base-child;
  }
}
