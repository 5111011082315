










.icon-container-variables {
  --color: var(--color-primary-darker);
  --background-color: var(--color-white);
  --width: 24px;
}
.icon-container {
  display: grid;
  place-items: center;
  color: var(--color);
  background-color: var(--background-color);
  width: var(--width);
  aspect-ratio: 1/1;
  border-radius: 50%;
}
