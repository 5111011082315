.print-only {
  display: none;
}

@media print {
  @page {
    size: auto !important;
    margin-top: 0.5cm;
    margin-bottom: 0.5cm;
  }

  @page :left {
    margin-left: 0.1cm;
  }

  @page :right {
    margin-left: 0.1cm;
  }

  .print-only {
    display: block !important;
  }

  html {
    -ms-transform: scale(1) !important;
    transform: scale(1) !important;
    -webkit-print-color-adjust: exact;
  }

  body.remove-parent-container-styles {
    padding-left: initial;

    .aula-content,
    .content-wrapper,
    .content-wrapper > main,
    #aula-posts,
    .conversation-holder,
    .messages-overview {
      height: initial !important;
      min-width: auto !important;
      width: auto;
      max-width: initial !important;
      position: initial !important;
      padding-left: initial;
    }
  }

  body,
  .aula-content,
  .content-wrapper,
  .content-wrapper > main,
  #aula-posts,
  .conversation-holder,
  .messages-overview {
    height: auto !important;
    min-width: 1100px !important;
    width: 100%;
    max-width: 100% !important;
    position: relative !important;
    background: transparent !important;
    padding-left: 9px;
  }

  .thread-metadata-sticky {
    display: none;
  }

  .header-media-print {
    display: block;
    padding-top: 90px !important;
  }

  .messages-container {
    height: auto !important;
  }

  .conversation-holder,
  .messages-overview {
    display: block !important;
    overflow: visible !important;
  }

  .subscription-holder {
    display: none !important;
  }

  .documents-secure-list-container {
    .new-document {
      width: 3500px !important;
    }

    .document-sharing {
      .action-area {
        position: absolute !important;
        left: 800px;
        margin-top: -20px;
      }
    }

    .drawer {
      margin-top: -20px;
      margin-left: 40px;
    }

    .time-info-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 20px;
    }

    .document-title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .edit-document {
      display: none;
    }

    .can-edit-title {
      position: absolute !important;
      width: 200px !important;
      left: 730px;
      top: -10px;
    }

    .regarding-child-list {
      width: 30% !important;
    }

    label {
      font-weight: bold !important;
    }
  }

  .conversation-content .message .row {
    display: flex !important;
  }

  .scrollbar {
    overflow: auto !important;
    height: auto !important;
    max-height: none !important;
  }

  .vue-drawer.active {
    left: 0 !important;
    top: 0 !important;
    position: absolute !important;
    width: 100% !important;
    max-width: 100% !important;
    min-height: 100% !important;

    .drawer {
      left: 0 !important;
      top: 90px !important;
      position: absolute !important;
      width: 100% !important;
      max-width: 100% !important;
      min-height: 100% !important;
    }

    .mask {
      display: none !important;
    }
  }

  body .aula-content {
    .no-print,
    #aula-header,
    .aula-menu,
    .content-wrapper > aside,
    #aula-header .menu-row,
    .dropdown-select.nav-item.b-nav-dropdown.dropdown,
    .hide-tablet-up,
    .more-unread-messages-container,
    .subscription-functionalities,
    .new-message,
    .mobile-navigation-container,
    .group-header,
    .group-page-navbar,
    .posts-header button,
    .aula-modal-open main,
    .aula-widget-placeholder,
    .contacts-group-filter,
    .multi-button {
      display: none !important;
    }

    .back-to-gallery {
      display: none !important;
    }

    .row > .dd {
      flex: 100%;
      width: 100% !important;
      width: 1400px !important;
      max-width: 100% !important;
    }

    input[type='text'].form-control,
    input[type='password'],
    input[type='email'],
    input[type='number'],
    input[type='url'],
    input[type='tel'],
    input[type='date'],
    input[type='time'],
    select.form-control,
    textarea.form-control,
    .el-input__inner,
    .aula-calendar #calendar .fc-view-container .fc-event-container .fc-day-grid-event,
    .aula-calendar #group-calendar .fc-view-container .fc-event-container .fc-day-grid-event,
    .aula-calendar #daily-calendar .fc-view-container .fc-event-container .fc-day-grid-event,
    .modal-content,
    .message-body,
    .contact.list-view,
    .table-row {
      border: solid 1px #999 !important;
      box-sizing: border-box;
    }

    .contact.list-view {
      .custom-checkbox,
      .contact-img {
        position: relative;
        left: -10px;
      }

      .custom-checkbox {
        top: -15px;
      }
    }

    .row {
      display: block !important;
    }

    .forwarded-note,
    .unreadFromHere {
      margin-left: 200px !important;
    }

    .subscription-holder {
      width: 99% !important;
    }

    .modal-aula {
      left: 0;
      top: 100px;
      transform: matrix(1, 0, 0, 1, 0, 0) !important;
      width: 100% !important;
      max-width: 100% !important;
      min-height: 100% !important;
      height: auto !important;
      overflow: visible !important;
      background-color: #fff !important;

      .modal-content,
      .modal-body {
        min-height: calc(100% - 100px) !important;
        height: auto !important;
        overflow: visible !important;
      }
    }

    .aula-calendar {
      width: calc(100% - 0.5cm);

      h1,
      .custom-calendar-title,
      .fc-toolbar {
        /* Hide calendar header and toolbar */
        display: none;
      }

      .el-input__inner {
        border: unset !important;
      }

      .fc-button-group,
      .fc button {
        display: unset;
      }
    }

    #calendar {
      a:not(.btn) {
        text-decoration: initial;
      }

      tbody,
      tr,
      td,
      fc-body,
      fc-row {
        background: unset !important;
      }

      .fc-view-container {
        .fc-dayGridMonth-view {
          .fc-content-skeleton,
          .fc-head-container {
            padding-left: 0 !important;

            .fc-week-number {
              background-color: $color-grey-dark !important;
            }
          }
        }

        .fc-head-container {
          background-color: $color-grey-dunkel !important;

          .fc-row {
            border-left: 1px solid $color-grey-dunkel !important;
          }

          padding-left: 60px;

          .fc-resource-cell {
            border-right: 1px solid $color-grey-dunkel;
          }

          th:first-child {
            width: 60px !important;
          }

          .fc-day-header {
            background-color: $color-grey-dunkel !important;

            &.fc-today {
              background-color: $color-darkblue !important;
              color: $color-white !important;
              font-weight: bold;
            }
          }
        }

        .fc-day-grid {
          border-bottom: 1px solid $color-grey-darker !important;

          .fc-week {
            height: 30px !important;
          }

          .fc-day-grid-event {
            width: 100%;
          }
        }

        .fc-time {
          width: 37px !important;
          display: block;
          background-color: $color-grey-dunkel !important;

          .fc-widget-content {
            background-color: $color-grey-light !important;
          }
        }

        .fc-dayGridMonth-view {
          .fc-week {
            height: 165px !important;

            .fc-day {
              height: 165px !important;
            }
          }
        }
      }

      .fc-time-grid-container {
        @media print and (orientation: portrait) {
          height: 1055px !important;
        }

        .fc-time-grid {
          @media print and (orientation: portrait) {
            height: 1055px !important;
          }

          .fc-slats {
            width: 100%;
            max-width: unset !important;
            height: 100%;
            max-height: unset !important;
            display: block !important;

            td {
              width: 100% !important;
            }

            td:first-child {
              width: 35px !important;
            }
          }
        }
      }

      .fc-content-skeleton {
        padding-left: 60px;

        .fc-dayGridMonth-view & {
          padding-left: 0 !important;
        }

        height: 100% !important;

        table {
          border-left: 1px solid $color-grey-dunkel !important;
        }

        table,
        tbody,
        tr,
        td,
        .fc-content-col,
        .fc-event-container {
          height: 100%;
          background: unset !important;
          position: relative;
        }

        .fc-event-container {
          height: 0;

          a {
            position: absolute !important;
          }

          .fc-time-grid-event {
            border: solid 1px $color-darkblue !important;
          }
        }
      }

      .fc-day-grid {
        .fc-content-skeleton {
          background-color: $color-grey-dunkel;

          table td {
            background-color: $color-white !important;
            border-color: $color-black;
          }
        }
      }

      /deep/ .fc-time {
        display: block;
        width: 60px !important;
        background-color: $color-grey-dunkel !important;
      }
    }

    .thread {
      border-left: solid 1px $color-grey-base !important;
    }

    .media-print {
      img {
        margin: 20px 0;
        max-width: 100% !important;
        height: auto !important;
        max-height: 1300px !important;
      }

      text-align: center;
    }

    .album-detail-container {
      top: 100px !important;
      width: 100% !important;
    }

    .aula-album-detail-container .album-detail-container .album-container {
      padding: 15px;
      height: auto !important;
      overflow: auto !important;
    }

    .modal-aula-backdrop {
      background: transparent;
    }

    .user .user-icon {
      border: solid 1px $color-primary-darker-admin;

      &.employee-employee-false {
        border: solid 1px $color-primary-dark-employee;
      }

      &.employee-employee-true {
        border: solid 1px $color-primary-light-employee;
      }

      &.guardian-employee-false {
        border: solid 1px $color-primary-dark-guardian;
      }

      &.guardian-guardian-true {
        border: solid 1px $color-primary-light-guardian;
      }
    }

    .group-modal .modal-content,
    .group-modal .modal-body {
      border: 0 !important;
    }

    .conversation .conversation-content .message-from-person .message-body::after {
      border-right-color: #666 !important;
    }

    .conversation .message-from-me .message-body::after {
      border-left-color: #666 !important;
    }
  }

  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .safari {
      .aula-calendar {
        width: calc(100% - 0.5cm) !important;
      }
    }
  }

  @supports ((-ms-ime-align: auto)) {
    body {
      #aula-print-logo {
        display: block !important;
      }

      .aula-content {
        .aula-calendar {
          width: calc(100% - 0.5cm) !important;

          .fc-button-group,
          .fc button {
            display: inline-block;
          }
        }
      }
    }
  }
}

@media print and (orientation: landscape) {
  @-moz-document url-prefix() {
    body {
      zoom: 50% !important;
    }
  }

  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .safari {
      .aula-calendar {
        width: 100%;
      }
    }
  }
}
