







































































































































































































































































































@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

footer {
  position: relative;
}

.modal-aula {
  min-width: 300px;
  .modal-header {
    padding-right: 60px;
    z-index: $modal-sticky-header-z-index;
    position: sticky;
    top: 0;
  }
}
.aula-spinner {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.icon-Aula_close {
  position: absolute;
  right: 15px;
  top: 20px;
  cursor: pointer;
  z-index: 1000;
}
.modal-title {
  text-transform: uppercase;
}
.btn-with-loader {
  position: relative;
}
