.text-grey {
  color: var(--color-grey);
}

.text-alert {
  color: var(--color-alert);
}

.text-decoration-underline {
  text-decoration: underline;
}
