#aula-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: $header-top-menu-z-index;

  .avatar {
    --background-color: var(--color-grey-light);
    --color: var(--color-primary-darker);
  }

  .filter-item-container .avatar {
    --width: 36px;
    --height: 36px;
    --font-size: 0.9rem;
  }

  .inactive-filter {
    background-color: var(--color-primary-dark);
    .avatar {
      --opacity: 0.5;
    }
  }

  .menu-icon .user-icon {
    width: var(--default-profile-image-size);
    height: var(--default-profile-image-size);
  }

  &.group-dropdown-open {
    // z-index must be higher than multiButtonMobile widget
    z-index: $header-top-menu-dropdown-open-z-index;
  }

  .notice-boards & {
    position: relative;
  }

  #top-bar {
    height: var(--menu-top-bar-height);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back-portal {
      color: var(--color-white);
    }
  }

  .logo-outer {
    .logo {
      cursor: pointer;
      color: var(--color-white);
      text-decoration: none;
      font-size: 2em;
      padding: 0 15px;
      &:hover {
        background-color: unset;
      }
    }
  }

  .back {
    display: block;

    @include breakpoint-xl() {
      display: none;
    }
  }

  .filter-row {
    display: inline-flex;
    vertical-align: middle;
  }

  .slider{
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    gap: 10px;
    height: 100%;

    @include breakpoint-sm-down() {
        margin-top: 10px;
        gap: 12px;
    }

    .global-filter {
      justify-content: flex-end;
      .group-home-child-badge{
        top: 35px;
        left: 45px;
        .badge {
          width: 20px;
          height: 20px;
          background-color: $color-alert;
          i {
            font-size: 9px;
          }
        }
      }
    }
    .group-home{
      margin-top: 27px;
      margin-right: 20px;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      color: $color-primary-dark-guardian;
      &.active{
        color: $color-primary-darker-guardian;
      }
    }
  }

  .global-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: var(--menu-top-bar-button-width);
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .custom-control-indicator {
      @include breakpoint-xs-down() {
        width: 15px;
        height: 15px;

        &::after {
          font-size: 15px;
          left: -1px;
          top: -4px;
        }
      }
    }

    .filter-checkbox {
      position: absolute;
      top: 1px;
      left: 4px;
      z-index: 1001;
    }
  }

  div:not(.slider) > .global-filter {
    border-left: 1px solid $color-white;
    width: var(--menu-top-bar-button-width);
  }

  .menu-row {
    padding-right: 0;
    position: static;
    display: flex;
    justify-content: flex-end;
    height: 100%;

    .btn [class^=icon-] {
      font-size: 1.15rem;
      line-height: 0;

      @include breakpoint-xl() {
        font-size: 2rem;
        line-height: initial;
      }
    }

    .global-filter i {
      font-size: var(--default-menu-icon-size);
    }

    .mobile-groups-profile {
      height: 100%;
    }
  }

  .small-text {
    margin-top: 10px;

    @include breakpoint-xl() {
      display: block;
    }
  }
}

//Theme styling
.theme-guardian {
  .filter-item-container {
    .custom-checkbox .custom-control-label::before,
    .custom-checkbox .custom-control-label::after {
      border-color: $color-form-border !important;
      color: $color-primary-light-guardian !important;
    }
    .custom-checkbox .custom-control-input:active ~ .custom-control-label::before,
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
      border-color: $color-primary-base-guardian !important;
    }

    .custom-checkbox .custom-control-input:active ~ .custom-control-label::after,
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
      color: $color-white !important;
    }
  }

  #top-bar {
    background-color: $color-primary-dark-guardian;
  }

  .global-filter {
    .badge {
      background-color: $color-primary-dark-guardian;
      border: 1px solid $color-white;
    }

    &.active-filter {
      background-color: $color-primary-dark-guardian;
    }

    &:focus-visible {
      background: $color-primary-darker-guardian;
    }
  }

  .filter-dropdown .global-filter {
    &.active-filter {
      background-color: $color-primary-darker-guardian;
    }
  }

  .header-dropdown-menu {
    &.mobile {
      background-color: $color-grey-base;

      .header-dropdown-title {
        background-color: $color-primary-dark-guardian;
      }

      .header-dropdown-item {
        background-color: $color-grey-base;
        color: $color-primary-darker-guardian;
      }
    }
  }
}

.theme-employee {
  .filter-item-container {
    .custom-checkbox .custom-control-label::before,
    .custom-checkbox .custom-control-label::after {
      border-color: $color-form-border !important;
      color: $color-primary-light-employee !important;
    }

    .custom-checkbox .custom-control-input:active ~ .custom-control-label::before,
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
      border-color: $color-primary-base-employee !important;
    }

    .custom-checkbox .custom-control-input:active ~ .custom-control-label::after,
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
      color: $color-white !important;
    }
  }

  #top-bar {
    background-color: $color-primary-dark-employee;
  }

  .global-filter {
    .badge {
      background-color: $color-primary-dark-employee;
      border: 1px solid $color-white;
    }

    &.active-filter {
      background-color: $color-primary-dark-employee;

      .badge {
        background-color: $color-primary-dark-employee;
      }
    }

    &:focus-visible {
      background: $color-primary-darker-employee;
    }
  }

  .filter-dropdown .global-filter {
    &.active-filter {
      background-color: $color-primary-darker-employee;
    }
  }

  .header-dropdown-menu {
    &.mobile {
      background-color: $color-grey-base;

      .header-dropdown-title {
        background-color: $color-primary-dark-employee;
      }

      .header-dropdown-item {
        background-color: $color-grey-base;
        color: $color-primary-darker-employee;
      }
    }
  }
}

.theme-child {
  .filter-item-container {
    .custom-checkbox .custom-control-label::before,
    .custom-checkbox .custom-control-label::after {
      border-color: $color-form-border !important;
      color: $color-primary-light-child !important;
    }
    .custom-checkbox .custom-control-input:active ~ .custom-control-label::before,
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
      border-color: $color-primary-base-child !important;
    }

    .custom-checkbox .custom-control-input:active ~ .custom-control-label::after,
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
      color: $color-white !important;
    }
  }

  #top-bar {
    background-color: $color-primary-dark-child;
  }

  .global-filter {
    &.active-filter {
      background-color: $color-primary-dark-child;
      border: 1px solid $color-white;
    }

    &:hover {
      background-color: $color-primary-dark-child;
    }

    &:focus-visible {
      background: $color-primary-darker-child;
    }
  }

  .filter-dropdown .global-filter {
    &.active-filter {
      background-color: $color-primary-darker-child;
    }
  }

  .header-dropdown-menu {
    &.mobile {
      background-color: $color-grey-base;

      .header-dropdown-title {
        background-color: $color-primary-dark-child;
      }

      .header-dropdown-item {
        background-color: $color-grey-base;
        color: $color-primary-darker-child;
      }
    }
  }
}

.theme-admin {
  #top-bar {
    background-color: $color-primary-darker;
  }
}
