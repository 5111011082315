















































































































































.aula-select {
  border-radius: var(--aula-select-border-radius, 5px);
  &[disabled='disabled'] {
    --caret-color: var(--color-grey-dark);
  }
  .search-icon {
    position: absolute;
    z-index: 1;
    --font-size: 18px;
    top: 17px;
    right: 25px;
  }
  .button-close {
    position: absolute;
    top: 12px;
    right: 47px;
    height: 28px;
    width: 28px;
    opacity: 0.8;
    background-color: transparent;

    &:hover {
      opacity: 1;
    }

    .icon-container {
      width: 100%;
      height: 100%;
      .aula-icon {
        --font-size: 18px;
      }
    }
  }
  /deep/ .el-select {
    border-color: var(--border-color, transparent);
    border-width: var(--border-width, 1px);
    border-style: var(--border-style, solid);

    .el-input .el-select__caret {
      color: var(--caret-color, var(--color-primary-darker));
      font-weight: var(--caret-weight, bold);
      font-size: var(--caret-size, 16px);
    }

    &[data-show-search-icon] {
      .el-input .el-select__caret {
        display: none;
      }
    }
  }
}

/* el-select Popper configuration */
.appended-to-parent /deep/ .el-select-dropdown {
  position: fixed !important;
  top: auto !important;
  left: auto !important;
}
