



















.aula-icon-variables {
  --font-size: 1rem;
}
.aula-icon {
  font-size: var(--font-size);
}
