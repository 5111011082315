.drawer-wrapper {
  width: 100%;
  height: 100%;
}

.vue-drawer {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  &.active {
    height: 100%;

    @include breakpoint-lg-down() {
      height: 100vh;
      position: absolute;
    }
  }
}

.vue-drawer > .main {
  //height: 100vmax;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform ease-in 1s, visibility 1s;
}

.group-modal .vue-drawer > .main {
  position: relative;
}

.vue-drawer > .main > .mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity ease-in 0.5s, visibility ease-in 0.5s;
  background-color: rgba(0, 0, 0, 0.2);

  &.active {
    visibility: visible;
    opacity: 1;
    z-index: 1040;
  }
}

.vue-drawer {
  > .drawer {
    @include breakpoint-sm-down() {
      width: 100%;
    }

    @include breakpoint-sm-up() {
      width: 100%;
    }

    @include breakpoint-lg() {
      width: 85%;
    }

    @include breakpoint-xl() {
      width: 80%;
      max-width: 1100px;
      &.drawer-wide {
        max-width: none;
      }
      background-color: $color-grey-light;
    }

    @include breakpoint-tablet-portrait() {
      width: 90%;
    }

    background-color: var(--color-grey-light);
    position: absolute;
    top: 0;
    height: 100%;
    overflow: hidden;
    visibility: hidden;
    transition-property: transform, visibility;
    transition-duration: 500ms;
    transition-timing-function: var(--ease-in-out);
    display: flex;
    flex-direction: column;

    > header {
      background: var(--background-color,var(--color-grey-base));
      color: var(--color-primary-darker);
      min-height: 57px;
      padding: 1rem;
      text-align: center;

      h1 {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        margin: auto;
      }

      .aula-close {
        color: var(--color-white);
        cursor: pointer;
        padding: 10px 10px;

        @include breakpoint-sm-down() {
          display: none;
        }
      }

      .aula-close-arrow {
        color: var(--color-primary-darker);
        font-weight: 900;
        font-size: 28px;
        padding: 10px 0;

        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);

        @include breakpoint-sm-up() {
          display: none;
        }
      }

      @include breakpoint-sm-up() {
        background: $color-primary-base;
        color: $color-white;
        padding: 18px 15px 18px 50px;
        text-align: left;


        h1 {
          font-size: 15px;
          font-weight: normal;
          text-transform: uppercase;
          margin-bottom: 0;
        }
      }
    }

    .drawer-content {
      overflow-y: auto;
      overflow-x: hidden;

      > * {
        padding: 10px 25px;

        @include breakpoint-sm-up() {
          padding: 25px;
        }

        @include breakpoint-lg() {
          padding: 25px;
        }

        @include breakpoint-xl() {
          padding: 25px 110px;
        }

        @include breakpoint-tablet-portrait() {
          padding: 25px;
        }

        @include breakpoint-tablet-landscape() {
          padding: 25px;
        }
      }

      label:not(.custom-control-label) {
        font-weight: normal;
        margin-bottom: 10px;
        margin-top: 30px;

        @include breakpoint-sm-up() {
          font-weight: 700;
        }
      }

      input[type='text'].form-control,
      input[type='password'],
      input[type='email'],
      input[type='number'],
      input[type='url'],
      input[type='tel'],
      input[type='date'],
      input[type='time'],
      select.form-control,
      textarea.form-control {
        margin-bottom: 0;
      }

      .el-input.is-disabled {
        .el-input__inner {
          border: 1px solid var(--color-grey-darker);
          color: var(--color-dark-grey);
          background-color: var(--color-grey-light);
        }
        .el-input__icon {
          color: var(--color-dark-grey);
        }
      }
    }
  }
}

.vue-drawer > .drawer-left {
  z-index: 1050;
  left: 0;
  transform: translateX(-102%);
}

.vue-drawer > .drawer-right {
  z-index: var(--modal-z-index);
  right: 0;
  transform: translateX(102%);
}

.vue-drawer > .overlap-header {
  @include breakpoint-sm-up() {
    position: fixed;
  }

  @include breakpoint-sm-down() {
    position: fixed;
  }
}

.drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  flex-shrink: 0;

  .drawer-title {
    flex-grow: 1;

    @include breakpoint-lg-down() {
      margin-left: 0;
      .title {
        justify-content: center;
      }
    }

    .title {
      display: flex;
    }
  }

  .btn-link {
    padding-left: 8px;
    padding-right: 8px;
    &:hover {
      background-color: var(--color-primary-dark);
    }
  }
}

.header-draft {
  height: var(--menu-top-bar-height);
  flex-shrink: 0;
  @include breakpoint-sm-up() {
    width: 100%;
    background-color: transparent;
  }

  @include breakpoint-sm-down() {
    display: none;
  }
}

.vue-drawer > .active {
  visibility: visible;
  transform: unset;
}

//Theme styling
.theme-guardian {
  .vue-drawer {
    > .drawer {
      header {
        @include breakpoint-sm-up() {
          background-color: var(--background-color,var(--color-primary-base));
        }
      }
    }
  }

  .header-draft {
    @include breakpoint-sm-up() {
      background-color: $color-primary-dark-guardian;
    }

    @include breakpoint-sm-down() {
      display: none;
    }
  }
}

.theme-employee {
  .vue-drawer {
    > .drawer {
      header {
        @include breakpoint-sm-up() {
          background-color: var(--background-color,var(--color-primary-base));
        }
      }
    }
  }

  .header-draft {
    @include breakpoint-sm-up() {
      background-color: $color-primary-dark-employee;
    }
  }
}

.theme-child {
  .vue-drawer {
    > .drawer {
      header {
        @include breakpoint-sm-up() {
          background-color: var(--background-color,var(--color-primary-base));
        }
      }
    }
  }

  .header-draft {
    @include breakpoint-sm-up() {
      background-color: $color-primary-dark-child;
    }
  }
}

.theme-admin {
  .vue-drawer {
    > .drawer {
      header {
        background-color: var(--background-color,var(--color-primary-dark));

        @include breakpoint-sm-down() {
          background-color: var(--background-color,var(--color-primary-darker)) !important;
          color: $color-white;

          i {
            color: $color-white;
          }
        }
      }

      a {
        cursor: pointer;
        color: $color-primary-dark;
        &:hover {
          color: $color-primary-darker;
        }
      }
    }
  }

  .header-draft {
    @include breakpoint-sm-up() {
      background-color: var(--color-primary-darker);
    }
  }
}

.vue-drawer .drawer-content  {
  .sending-documents-metadata {
    padding-bottom: 0;
  }
  .document-preview-table {
    padding-bottom: 0;
  }
  .sending-documents-footer,
  .remove-association-documents-footer {
    padding-top: 0;
  }
  .remove-association-documents-metadata,
  .remove-association-documents-confirm {
    padding-bottom: 0;
  }
}
