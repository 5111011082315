.alert {
  color: var(--base-color, var(--color-alert));
  background: transparent;
  border: none;
  position: relative;
  padding-left: 45px;
  word-break: keep-all;

  &.alert-danger {
    --base-color: var(--color-alert);
    --icon-background-color: var(--color-alert);
    --icon-color: var(--color-white);
  }
  &.alert-info {
    --base-color: var(--color-primary-darker);
    --icon-background-color: var(--color-white);
    --icon-color: var(--color-primary-darker);
  }
  &.alert-open-hours-validate {
    margin-top: 10px;
    margin-left: 150px;
  }

  &.alert-danger,
  &.alert-info {
    &::before {
      content: '\e92d';
      font-family: 'aula' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      color: var(--icon-color, var(--color-white));
      background: var(--icon-background-color, var(--color-alert));
      position: absolute;
      width: 38px;
      height: 38px;
      padding: 8px 0 0 18px;
      border-radius: 50%;
      left: 0;
      top: 3px;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.badge-notification {
  &.inline {
    display: inline;
  }

  &.documents {
    left: -10px;
    top: 6px;
  }

  &.more-menu-badge {
    @include breakpoint-xl-down() {
      left: 5%;
    }

    top: 0;
  }

  &.dashboard {
    position: static;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: var(--color-alert);
    color: var(--color-white);
    border-radius: 50%;
    text-align: center;
    vertical-align: center;
  }

  &.submenu {
    position: relative;
    display: inline-block;
    right: unset !important;
    left: unset !important;
    top: unset !important;
    margin-left: 5px;
  }
}
.mandatory {
  color: $color-alert;
  font-size: 21px;
  line-height: 0.8;
  font-weight: 700;
}

.badge {
  &.badge-danger {
    background-color: var(--color-alert);
  }
}
