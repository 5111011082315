@import './mixins';

.btn {
  border-width: 0;
  font-size: 14px;
  padding: 14px 44px;

  &:active:not(:focus-visible) {
    box-shadow: none !important;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
}

.btn-sm {
  padding: 8px;

  i {
    font-size: 10px;
    margin-left: 5px;
  }
}

.btn-primary {
  background: $color-primary-darker;
  border-radius: 4px;
  color: $color-white;

  &:hover {
    background: $color-primary-darker;
    border: 0;
    color: $color-white;
    border-radius: 4px;
  }
}

.btn-collapse-link {
  .theme-employee & {
    color: $color-primary-darker-employee;
  }

  .theme-guardian & {
    color: $color-primary-darker-guardian;
  }

  .theme-child & {
    color: $color-primary-darker-child;
  }

  .theme-admin & {
    color: $color-primary-darker-admin;
  }
}

.btn-aula-outline {
  background-color: transparent;
  border: 1px solid $color-primary-darker;
  color: $color-primary-darker;
}

.btn-grey {
  background-color: $color-grey-base;
  color: $color-darkblue;
}

.btn-white {
  background-color: $color-white;
  color: $color-primary-dark-guardian;
}

.icon-Aula_search {
  cursor: pointer;
}

.icon-reverse {
  background: $color-primary-darker;
  color: $color-white;
  padding: 5px;
  border-radius: 50%;
}

.btn-link {
  color: var(--btn-link-color, var(--color-primary-darker));
  padding: 0;
  vertical-align: inherit;
  border-radius: 4px;
  transition: background-color 150ms ease-in;

  &:hover,
  &:focus,
  &:focus-visible {
    text-decoration: none;
  }
  &:hover {
    color: var(--color-primary-dark);
  }

  @include mouse-hover {
    text-decoration: none;
    color: var(--btn-link-color, var(--color-primary-darker));
    background-color: var(--color-grey-light);
  }

  &[data-color="base"] {
    --btn-link-color: var(--color-primary-base);
  }
}

.btn-secondary {
  background: $color-primary-darker;
  color: $color-white;
  border-radius: 4px;

  &.dropdown-toggle {
    max-width: var(--dropdown-toggle-button-max-width, unset);
    &:hover {
      color: unset;
    }
  }
}

.btn-light-blue {
  .theme-guardian & {
    background-color: $color-primary-base-guardian;
  }

  .theme-child & {
    background-color: $color-primary-base-child;
  }

  .theme-employee & {
    background-color: $color-primary-base-employee;
  }

  color: $color-white;
  border-radius: 5px;
}

.btn-light-green {
  background-color: $color-primary-light-employee;
  color: $color-white;
  border-radius: 5px;
}

.show > .btn-primary.dropdown-toggle {
  background: $color-primary-darker;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  color: unset;
}

.btn-primary:active,
.btn-primary.active {
  background: $color-primary-darker;
}

input[type='text'].form-control,
input[type='password'],
input[type='email'],
input[type='number'],
input[type='url'],
input[type='tel'],
input[type='date'],
input[type='time'],
select.form-control,
textarea.form-control {
  border: 1px solid var(--input-border-color, $color-white);
  padding: 12px var(--input-padding-right, 18px) 12px 18px;
  border-radius: 5px;
  background-color: var(--input-background-color, var(--color-white));
  outline: 0;
  min-height: var(--input-min-height, 50px);
  font-size: 14px;
  background-image: none;

  &.is-valid {
    border-color: transparent;
  }

  .theme-employee & {
    color: $color-primary-darker-employee;
  }

  .theme-guardian & {
    color: $color-primary-darker-guardian;
  }

  .theme-child & {
    color: $color-primary-darker-child;
  }

  .theme-admin & {
    color: $color-primary-darker-admin;
  }

  &:focus-visible {
    box-shadow: none;
    outline: 1px solid var(--color-primary-dark);
  }

  &:disabled {
    border-color: 1px solid $color-form-border;
    background-color: $color-grey-light;
  }

  &[aria-invalid] {
    border: 1px solid var(--color-alert);
  }
}

.is-invalid ~ .invalid-feedback {
  color: var(--color-alert);
  font-size: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  &::before {
    content: '\e92d';
    font-family: 'aula', Lato, serif;
    background-color: var(--color-alert);
    color: var(--color-white);
    display: grid;
    place-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

div,
i,
a,
button,
textarea,
select,
.fc-button {
  &:focus-visible {
    outline: 0;

    .theme-employee & {
      box-shadow: 0 0 3px 1px $color-primary-darker-employee;
    }

    .theme-guardian & {
      box-shadow: 0 0 3px 1px $color-primary-darker-guardian;
    }

    .theme-child & {
      box-shadow: 0 0 3px 1px $color-primary-darker-child;
    }

    .theme-admin & {
      box-shadow: 0 0 3px 1px $color-primary-darker-admin;
    }
  }
}

a.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus-visible {
    .theme-employee & {
      box-shadow: 0 0 2px 0.1rem $color-primary-light-employee !important;
    }

    .theme-guardian & {
      box-shadow: 0 0 2px 0.1rem $color-primary-light-guardian !important;
    }

    .theme-child & {
      box-shadow: 0 0 2px 0.1rem $color-primary-light-child !important;
    }

    .theme-admin & {
      box-shadow: 0 0 2px 0.1rem $color-primary-light-admin !important;
    }
  }
}

.header-dropdown,
th {
  &:focus-visible {
    outline: 0;

    .theme-admin & {
      border: 1px solid $color-primary-darker-admin;
    }

    .theme-employee & {
      border: 1px solid $color-primary-darker-employee;
    }

    .theme-guardian & {
      border: 1px solid $color-primary-darker-guardian;
    }

    .theme-child & {
      border: 1px solid $color-primary-darker-child;
    }
  }
}

input[type='text'],
textarea {
  &::-ms-clear {
    display: none;
  }
}

.input-group-addon {
  background: #fff;
  border: none;
  min-height: 50px;
}

.el-input__inner {
  border: none;
  padding: 6px 18px;
  border-radius: 5px;
  min-height: 50px;
  background-color: var(--input-background-base-color, var(--color-white));

  .vacation-intervals & {
    @include breakpoint-lg-down() {
      min-height: 25px;
      height: 25px;
      line-height: 25px;
      padding: 0 18px;
    }
  }
}

.el-input__suffix-inner .el-icon-arrow-up {
  .vacation-intervals & {
    @include breakpoint-lg-down() {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.el-input__inner,
.el-input__prefix {
  .presence-week-picker & {
    display: none;
  }
}

.el-select {
  width: 100%;
  border-radius: 5px;

  &:focus-within {
    outline: 1px solid var(--color-primary-dark);
  }
  .el-select__tags {
    padding-right: var(--select-input-tags-padding-right, unset);
  }

  .el-tag {
    height: 36px;
    padding: 6px 10px;
    margin: 2px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: unset;

    .el-select__tags-text {
      font-size: 12px;
      color: $color-darkblue;
    }
    .el-tag__close {
      color: $color-white;
      background-color: $color-darkblue;
      font-size: 14px;
    }
  }

  &.time {
    .vacation-intervals & {
      @include breakpoint-lg-down() {
        max-width: 110px;
      }
    }
  }

  .el-input.is-disabled input.el-input__inner {
    background-color: var(--color-grey-light);
    color: var(--color-primary-darker);
    border: 1px solid var(--color-grey-darker);
  }
}

.modal-content-text {
  .el-input__inner {
    background-color: $color-grey-light;
  }
}

.error-text {
  display: none;
}

.information-icon {
  height: 30px;
  width: 30px;
  font-size: 15px;
  top: 3px;
  text-align: center;
  background-color: $color-white;
  color: $color-help-text !important;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  vertical-align: text-bottom;
}

.questionmark-icon {
  height: 30px;
  width: 30px;
  padding: 0 !important;
  text-align: center;
  background-color: var(--questionmark-icon-background-color, var(--color-white));
  color: var(--questionmark-icon-color, var(--color-primary-darker)) !important;
  display: inline-block;
  border-radius: 50%;

  &:focus {
    text-decoration: none;
  }

  &:hover {
    height: 30px;
    width: 30px;
    text-align: center;
    background-color: var(--questionmark-icon-background-color, var(--color-white));
    border-radius: 50%;
    text-decoration: none;
  }
}

label {
  font-weight: bold;
  text-transform: uppercase;
}

.error {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'],
  input[type='url'],
  input[type='tel'],
  input[type='date'],
  input[type='time'],
  select.form-control,
  textarea {
    border-color: $color-alert;
  }

  .error-text {
    position: relative;
    top: -5px;
    display: block;
    color: $color-alert;
    margin-bottom: 12px;
  }
}

[class^='icon-'],
[class*=' icon-'] {
  &.in-alert-circle {
    display: inline-block;
    font-size: 18px;
    height: 34px;
    line-height: 32px;
    width: 34px;
    border-radius: 50%;
    color: $color-white;
    background-color: $color-alert;
    text-align: center;
    position: relative;
    top: 2px;
    margin-right: 5px;

    &.white-bg {
      color: $color-darkblue;
      background-color: $color-white;
    }
  }
}

.custom-checkbox,
.custom-radio {
  padding: 0 0 0 25px;
}

.custom-checkbox,
.custom-checkbox * {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  background-image: none !important;
  font-weight: bold;
  cursor: pointer;
}

.custom-radio,
.custom-radio * {
  cursor: pointer;
}

.custom-switch {
  .custom-control-label{
    &::before,
    &::after {
      cursor: pointer;
    }
  }
}

.custom-checkbox {
  .custom-control-input:active ~ .custom-control-label::after,
  .custom-control-input:checked ~ .custom-control-label::after {
    @include add-icon('Aula_check');
  }
}

.custom-radio {
  .custom-control-label::before,
  &.no-disabled-style .custom-control-input:disabled:not(:checked) ~ .custom-control-label::before {
    background-color: var(--color-grey-dim);
    border: none;
  }
}

.custom-checkbox.no-disabled-style {
  .custom-control-input:disabled:not(:checked) ~ .custom-control-label::before {
    background-color: $color-white !important;
  }
}

.sensitive-msg-container {
  .custom-control-label {
    font-size: 17px;
    @include breakpoint-sm-down() {
      font-size: 14px;
    }
    &:before {
      top: 2px;
    }
    &:after {
      top: 2px;
    }
  }
  .icon-Aula_lock {
    color: $color-alert;
  }
}

.custom-checkbox,
.custom-radio {
  position: relative;

  .custom-control-label {
    text-transform: none !important;
  }

  &.message-select-checkbox.custom-control {
    width: 35px;
    height: 35px;
    top: 10px;
  }
  &.message-select-checkbox .custom-control-label {
    margin-top: 12px;
  }

  .custom-control-label::before {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    color: $color-form-border;
    box-shadow: 0 0 transparent;
    position: absolute;
    top: 0;
    left: -28px;
  }

  .custom-control-label::after {
    width: 23px;
    height: 23px;
    background-image: none !important;
    color: $color-form-border;
    font-size: 24px;
    position: absolute;
    top: 0;
    left: -28px;
  }

  .custom-control-input:focus-visible ~ .custom-control-label::before {
    .theme-employee & {
      box-shadow: 0 0 2px 0.01rem $color-primary-darker-employee;
    }

    .theme-guardian & {
      box-shadow: 0 0 2px 0.01rem $color-primary-darker-guardian;
    }

    .theme-child & {
      box-shadow: 0 0 2px 0.01rem $color-primary-darker-child;
    }

    .theme-admin & {
      box-shadow: 0 0 2px 0.01rem $color-primary-darker-admin;
    }
  }

  .custom-control-input:active ~ .custom-control-label::before,
  .custom-control-input:checked ~ .custom-control-label::before {
    outline: 0;

    .theme-employee & {
      border-color: $color-primary-dark-employee;
      background: $color-primary-dark-employee;
    }

    .theme-guardian & {
      border-color: $color-primary-dark-guardian;
      background: $color-primary-dark-guardian;
    }

    .theme-child & {
      border-color: $color-primary-dark-child;
      background: $color-primary-dark-child;
    }

    .theme-admin & {
      border-color: $color-primary-dark-admin;
      background: $color-primary-dark-admin;
    }
  }

  .custom-control-input:active ~ .custom-control-label::after,
  .custom-control-input:checked ~ .custom-control-label::after {
    outline: 0;

    .theme-employee & {
      color: $color-primary-light-employee;
      border-color: $color-primary-dark-employee;
    }

    .theme-guardian & {
      color: $color-primary-light-guardian;
      border-color: $color-primary-dark-guardian;
    }

    .theme-child & {
      color: $color-primary-light-child;
      border-color: $color-primary-dark-child;
    }

    .theme-admin & {
      color: $color-primary-light-admin;
      border-color: $color-primary-dark-admin;
    }
  }

  &.checked-is-disabled {
    .custom-control-input:active ~ .custom-control-label::after,
    .custom-control-input:checked ~ .custom-control-label::after {
      .theme-employee & {
        color: $color-primary-light-employee !important;
      }

      .theme-guardian & {
        color: $color-primary-light-guardian !important;
      }

      .theme-child & {
        color: $color-primary-light-child !important;
      }

      .theme-admin & {
        color: $color-primary-light-admin !important;
      }
    }
  }

  &:not(.no-disabled-style) .custom-control-input:disabled ~ .custom-control-label {
    color: var(--input-disabled-color, var(--color-grey)) !important;
  }

  &:not(.no-disabled-style) .custom-control-input:disabled ~ .custom-control-label::before {
    border: solid 1px $color-grey-darker !important;
    background-color: transparent !important;
  }

  &:not(.no-disabled-style) .custom-control-input:disabled ~ .custom-control-label::after {
    color: var(--input-disabled-color, var(--color-grey)) !important;
  }
}

.custom-radio {
  &:not(.no-disabled-style) .custom-control-input:disabled ~ .custom-control-label::before {
    border-width: 4px !important;
  }
}

.custom-checkbox {
  .custom-control-label::after {
    left: -29px;
  }

  .custom-control-input:active ~ .custom-control-label::after,
  .custom-control-input:checked ~ .custom-control-label::after {
    color: $color-white !important;
  }

  &:not(.no-disabled-style) .custom-control-input:disabled ~ .custom-control-label {
    color: var(--input-disabled-color, var(--color-grey)) !important;
  }

  &:not(.no-disabled-style) .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: var(--color-white) !important;

    .permission-table & {
      border: solid 1px $color-darkblue !important;
    }
  }

  &:not(.no-disabled-style) .custom-control-input:disabled ~ .custom-control-label::after {
    color: $color-dark-grey !important;

    .permission-table & {
      color: $color-darkblue !important;
    }
  }
}

.custom-radio {
  .custom-control-label::after {
    color: $color-white;

    @include add-icon('Aula_black_circle');

    font-size: 11px;
    width: 23px;
    height: 23px;
    padding: 6px;
  }

  &:not(.no-disabled-style) .custom-control-input:disabled ~ .custom-control-label::after {
    color: transparent !important;
  }
}

.smallest > .custom-control-label {
  @include breakpoint-lg-down() {
    &::before {
      width: 18px;
      height: 18px;
    }

    &::after {
      font-size: 14px;
      left: -27px;
      top: 2px;
    }
  }
}

.el-switch {
  .el-switch__core {
    height: 31px;
    width: 60px !important;
    border-radius: 20px;
    border-color: $color-grey;
    background-color: $color-grey;

    &::after {
      margin-left: 0;
      width: 27px;
      height: 27px;
      transition: left 0.3s;
      border: 0 !important;
      left: 1px;
      top: 1px;
      position: absolute;
      padding-left: 4px;
      padding-top: 4px;
    }
  }

  &.is-checked {
    .el-switch__core::after {
      right: 1px;
      left: unset;
      padding-left: 8px;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

.button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
  background-color: $color-primary-light;
  border: solid 1px $color-primary-light;
  border-radius: 5px;
  color: $color-white;
  padding: 12px;
  outline: 0;
  margin-bottom: 10px;

  &:hover {
    background-color: $color-primary-base;
    border-color: $color-primary-base;
  }

  &.primary {
    background-color: $color-primary-darker;
    border-color: $color-primary-darker;
  }

  &:disabled {
    background-color: transparent;
    border-color: $color-form-border;
    color: $color-form-border;
  }
}

@mixin aula-circle($radius, $backgroundColor) {
  width: $radius;
  height: $radius;
  border-radius: $radius;
  background-color: $backgroundColor;
  box-shadow: var(--box-shadow-base);
}

.aula-circle-large {
  @include aula-circle(65px, $color-primary-light);

  @include breakpoint-lg() {
    display: none;
  }
}

@include breakpoint-lg-down() {
  .group-modal .multi-button {
    z-index: 2000 !important;

    .tool-link {
      z-index: 2000 !important;
      bottom: 100px;
    }

    .multi-button-backdrop {
      z-index: 1999 !important;
    }
  }
}

.icon-Aula_plus.in-circle {
  color: $color-white;
  border-radius: 50%;
  padding: 6px;
  font-size: 12px;
  display: none;

  .theme-employee & {
    background-color: $color-primary-darker-employee;
  }

  .theme-guardian & {
    background-color: $color-primary-darker-guardian;
  }

  .theme-child & {
    background-color: $color-primary-darker-child;
  }

  .theme-admin & {
    background-color: $color-primary-darker-admin;
  }

  @include breakpoint-lg() {
    display: inline;
  }
}

.tool-link {
  color: $color-primary-darker;
  cursor: pointer;
  font-size: 14px;
  z-index: 1000;
  padding: 8px;

  &:hover {
    text-decoration: none;
  }

  .icon {
    font-size: 14px;
    position: relative;
  }

  @include breakpoint-lg() {
    position: static;
  }

  @include breakpoint-lg-down() {
    position: fixed;
    bottom: 66px;
    font-size: 0;
    right: 33px;

    &.large {
      right: 20px;
      bottom: 73px;
    }

    footer & {
      position: static;
      margin-left: 40%;
    }
  }
}

.aula-circle-large {
  .aula-circle-small {
    .icon {
      @include breakpoint-lg-down() {
        top: 20px;
      }
    }
  }
}

.aula-circle-small {
  @include aula-circle(55px, $color-primary-dark);

  box-shadow: none;
  position: relative;
  top: 5px;
  font-size: 12px;
  left: 5px;
  color: white;
  text-align: center;
}
//Theme styling
.theme-admin {
  .btn-primary {
    background-color: $color-primary-dark-employee;

    &:hover {
      background: $color-primary-base-employee;
    }
  }

  .btn-link,
  a.btn-link {
    cursor: pointer;
  }

  .btn-secondary,
  .btn-primary:not([disabled]):not(.disabled):active {
    background: $color-primary-darker-admin;

    &:hover {
      background: $color-primary-dark-admin;
    }
  }

  .show > .btn-primary.dropdown-toggle {
    background: $color-primary-darker-admin;
  }

  .btn-primary:active,
  .btn-primary.active {
    background: $color-primary-darker-admin;
  }

  .aula-circle-large {
    background-color: $color-primary-light-admin;
  }

  .aula-circle-small {
    background-color: $color-primary-dark-admin;
  }

  .el-switch.is-checked .el-switch__core {
    background-color: $color-primary-dark-admin;
  }

  .is-checked {
    .el-switch__core {
      border-color: $color-primary-dark-admin;

      .el-switch__button {
        border-color: $color-primary-dark-admin;
      }

      &::after {
        color: $color-primary-dark-admin;
      }
    }
  }

  .el-date-picker,
  .el-date-range-picker {
    td.available span {
      &:hover {
        background-color: $color-primary-base-employee !important;
      }
    }

    td.start-date span,
    td.end-date span {
      background-color: $color-primary-dark-employee !important;
      color: $color-white;
    }
  }

  .elPickerActive {
    .el-icon-time,
    .el-icon-date {
      color: $color-picker-button-blue !important;
    }
  }
}

.theme-guardian {
  .btn-primary {
    background-color: $color-primary-dark-guardian;

    &:hover {
      background: $color-primary-base-guardian;
    }
  }

  .btn-secondary,
  .btn-primary:not([disabled]):not(.disabled):active {
    background: $color-primary-darker-guardian;
  }

  .show > .btn-primary.dropdown-toggle {
    background: $color-primary-darker-guardian;
  }

  .btn-primary:active,
  .btn-primary.active {
    background: $color-primary-darker-guardian;
  }

  .aula-circle-large {
    background-color: $color-primary-light-guardian;
  }

  .aula-circle-small {
    background-color: $color-primary-dark-guardian;
  }

  .el-switch.is-checked .el-switch__core {
    background-color: $color-primary-dark-guardian;
  }

  .is-checked {
    .el-switch__core {
      border-color: $color-primary-dark-guardian;

      .el-switch__button {
        border-color: $color-primary-dark-guardian;
      }

      &::after {
        color: $color-primary-dark-guardian;
      }
    }
  }

  .el-date-picker,
  .el-date-range-picker {
    td.available span {
      &:hover {
        background-color: $color-primary-light-guardian !important;
      }
    }
  }

  .elPickerActive {
    .el-icon-time,
    .el-icon-date {
      color: $color-picker-button-blue !important;
    }
  }
}

.theme-employee {
  .btn-primary {
    background-color: $color-primary-dark-employee;

    &:hover {
      background: $color-primary-base-employee;
    }
  }

  .btn-secondary,
  .btn-primary:not([disabled]):not(.disabled):active {
    background: $color-primary-darker-employee;
  }

  .show > .btn-primary.dropdown-toggle {
    background: $color-primary-darker-employee;
  }

  .btn-primary:active,
  .btn-primary.active {
    background: $color-primary-darker-employee;
  }

  .aula-circle-large {
    background-color: $color-primary-light-employee;
  }

  .aula-circle-small {
    background-color: $color-primary-dark-employee;
  }

  .el-switch.is-checked .el-switch__core {
    background-color: $color-primary-dark-employee;
  }

  .is-checked {
    .el-switch__core {
      border-color: $color-primary-dark-employee;

      .el-switch__button {
        border-color: $color-primary-dark-employee;
      }

      &::after {
        color: $color-primary-dark-employee;
      }
    }
  }

  .el-date-picker,
  .el-date-range-picker {
    td.available span {
      &:hover {
        background-color: $color-primary-light-employee !important;
      }
    }
  }

  .elPickerActive {
    .el-icon-time,
    .el-icon-date {
      color: $color-picker-button-green !important;
    }
  }
}

.theme-child {
  .btn-primary {
    background-color: $color-primary-dark-child;

    &:hover {
      background: $color-primary-base-child;
    }
  }

  .btn-secondary,
  .btn-primary:not([disabled]):not(.disabled):active {
    background: $color-primary-darker-child;
  }

  .show > .btn-primary.dropdown-toggle {
    background: $color-primary-darker-child;
  }

  .btn-primary:active,
  .btn-primary.active {
    background: $color-primary-darker-child;
  }

  .aula-circle-large {
    background-color: $color-primary-light-child;
  }

  .aula-circle-small {
    background-color: $color-primary-dark-child;
  }

  .el-switch.is-checked .el-switch__core {
    background-color: $color-primary-dark-child;
  }

  .is-checked {
    .el-switch__core {
      border-color: $color-primary-dark-child;

      .el-switch__button {
        border-color: $color-primary-dark-child;
      }

      &::after {
        color: $color-primary-dark-child;
      }
    }
  }

  .el-date-picker,
  .el-date-range-picker {
    td.available span {
      &:hover {
        background-color: $color-primary-light-child !important;
      }
    }
  }

  .elPickerActive {
    .el-icon-time,
    .el-icon-date {
      color: $color-picker-button-blue !important;
    }
  }
}

.el-date-editor {
  border-radius: 5px;
  &.el-input:focus-within {
    outline: 1px solid var(--color-primary-dark);
  }
  .el-icon-date::before {
    font-family: 'aula';

    @extend .icon-Aula_calendar::before;

    font-size: 1.25rem;
    position: relative;
  }

  .el-icon-circle-close {
    transition: none;
    position: relative;
    font-size: 21px;
    bottom: 4px;
  }

  .el-icon-time::before {
    font-size: 21px;
    position: relative;
  }

  .el-input__prefix {
    top: 1px;
    left: 11px;
  }

  .el-input__icon {
    color: var(--color-primary-darker);
  }

  .el-input__inner {
    padding-left: 42px;
  }

  .el-input__suffix-inner .el-input__icon {
    margin-top: 4px;
  }

  .el-input__icon::before {
    bottom: -1px !important;
  }
}

.el-date-picker,
.el-date-range-picker {
  width: 360px;
  border-radius: 10px;

  @include breakpoint-xs-down() {
    width: 100vw;
    border-radius: 0;
    box-shadow: none;
  }

  .el-year-table {
    td.available.current.today {
      .cell:hover {
        color: $color-white;
      }
    }
  }

  &.el-popper {
    margin-top: -5px;
  }

  .popper__arrow {
    display: none;
  }

  .el-date-picker__header {
    height: 40px;
  }

  .el-date-picker__header-label {
    display: inline-block;
    height: 37px;
    line-height: 37px;
    padding: 0 9px;
    font-size: 14px;
    border-radius: 18.5px;

    &:hover {
      background-color: $color-dropdown-focus;
      color: inherit;
    }

    &::after {
      font-family: 'aula';

      @extend .icon-Aula_down-arrow::before;

      font-size: 4px;
      position: relative;
      left: 5px;
      bottom: 2px;
    }
  }

  .el-picker-panel__content {
    width: 93%;
    padding-left: 20px;
  }

  td,
  th {
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    color: $color-darkblue;
  }

  td.disabled div {
    background-color: transparent;
  }

  td.next-month {
    display: none;
  }

  td.prev-month div {
    display: none;
  }

  td span {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }

  td.available {
    &:hover {
      color: inherit;
    }

    &.in-range {
      background-color: $color-dropdown-focus;
    }
  }

  td.current:not(.disabled) span {
    background-color: $color-dropdown-focus;
    color: inherit;
  }

  td.available span {
    &:hover {
      color: $color-white;
      background-color: $color-primary-light;
    }
  }

  .el-date-table td div {
    height: 50px;
    width: 50px;
    padding: 0;
  }

  .el-date-table td {
    width: 50px;
    padding: 0;

    &.current span {
      color: $color-white;

      .theme-employee & {
        background-color: $color-primary-base-employee;
      }

      .theme-guardian & {
        background-color: $color-primary-base-guardian;
      }

      .theme-child & {
        background-color: $color-primary-base-child;
      }

      .theme-admin & {
        background-color: $color-primary-dark-admin;
      }
    }

    &.today span {
      background-color: $color-dropdown-focus;

      .theme-employee & {
        color: $color-primary-darker-employee;
      }

      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }

      .theme-child & {
        color: $color-primary-darker-child;
      }

      .theme-admin & {
        color: $color-primary-darker-admin;
      }

      &:hover {
        color: $color-white !important;
      }
    }

    &.today {
      &.current,
      &.in-range {
        span {
          color: $color-white;
        }
      }
    }
  }

  th,
  td {
    border: none;
  }

  .el-date-table th {
    font-size: 12px;
    text-align: center;
    color: $color-date-weekday;
    font-weight: bold;
  }

  .el-icon-d-arrow-left,
  .el-icon-d-arrow-right {
    display: none;
  }

  .el-icon-arrow-left,
  .el-icon-arrow-right {
    font-family: 'aula' !important;
    margin: 0;
    padding: 0;
    height: 37px;
    width: 37px;
    border-radius: 18px;

    &:hover {
      background-color: $color-dropdown-focus;
    }
  }

  .el-icon-arrow-left::before,
  .el-icon-arrow-right::before {
    @extend .icon-Aula_black_circle;

    color: $color-dropdown-focus;
    font-size: 37px;
    content: '';
  }

  .el-icon-arrow-left::after,
  .el-icon-arrow-right::after {
    position: relative;
    color: $color-primary-darker;
    bottom: 11px;

    .group-modal & {
      bottom: 23px;
    }
  }

  .el-icon-arrow-left::after {
    @extend .icon-Aula_back-triangle::before;

    right: 22px;

    .group-modal & {
      right: 0;
    }
  }

  .el-icon-arrow-right::after {
    @extend .icon-Aula_forward-triangle::before;

    left: -22px;

    .group-modal & {
      left: 0;
    }
  }

  .el-month-table,
  .el-year-table {
    td {
      padding: 3px;

      .cell {
        width: 100%;
        background-color: $color-grey-light;
        border-radius: 5px;
      }
    }

    td:not(.disabled) {
      .cell {
        &:hover {
          color: $color-white;
          background-color: $color-primary-light;
        }
      }
    }

    td.current:not(.disabled) .cell {
      color: inherit;
    }
  }

  &.presence-choose-week-popper {
    margin-top: 10px !important;
  }
}

.el-date-range-picker {
  width: 720px;

  .el-picker-panel__content {
    float: left;
    width: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 16px;
  }

  .el-date-table td div {
    height: 45px;
    width: 45px;
    padding: 0;
  }
}

.vue__time-picker {
  font-family: 'Lato';

  .display-time::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--input-placeholder-color, var(--color-grey-dim)) !important;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--input-placeholder-color, var(--color-grey-dim)) !important;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--input-placeholder-color, var(--color-grey-dim)) !important;
  }

  input.display-time,
  &.no-disabled-style input.display-time:disabled {
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 5px;
    color: var(--base-color, var(--color-grey-dim));
    font-size: var(--font-size, 14px);
    padding: 6px 18px;
    background-color: var(--background-color, var(--color-white));

    #message-autoreply & {
      padding-left: 15px;
      padding-right: 15px;
    }

    &:focus-visible {
      border: 0;
      outline: 0;

      .theme-employee & {
        box-shadow: 0 0 2px 0.01rem $color-primary-darker-employee;
      }

      .theme-guardian & {
        box-shadow: 0 0 2px 0.01rem $color-primary-darker-guardian;
      }

      .theme-child & {
        box-shadow: 0 0 2px 0.01rem $color-primary-darker-child;
      }

      .theme-admin & {
        box-shadow: 0 0 2px 0.01rem $color-primary-darker-admin;
      }
    }
  }

  input.display-time:disabled:not(.skip-error-style) {
    color: var(--color-dark-grey);
    background-color: var(--color-grey-light);
    border: 1px solid var(--color-grey-darker);
    cursor: not-allowed;
  }

  &.time-picker .dropdown {
    margin: 18px 0;
    border-radius: 5px;
    transform: var(--drop-down-transform, unset);

    ul {
      li:not([disabled]).active,
      li:not([disabled]).active:hover,
      li:not([disabled]).active:focus-visible,
      li:not([disabled]).active:focus,
      {
        background-color: var(--color-primary-darker);
      }

      li[disabled] {
        display: none;
      }
    }
  }
}

.specific-open-hours-drawer {
  .vue__time-picker {
    input.display-time {
      padding-left: 18px;
    }
  }
}

.elPickerActive {
  .el-icon-time,
  .el-icon-date {
    color: $color-picker-button !important;
  }
}

.el-date-editor.el-input .el-input__prefix {
  right: 12px;
  top: 2px;
  left: unset;
  color: #000;
}

.el-date-editor.el-input .el-input__suffix {
  right: 40px;
  top: 2px;
  color: #000;
}

.el-input--prefix .el-input__inner {
  padding-left: 18px;
  padding-right: 45px;
  #message-autoreply & {
    padding-left: 15px;
  }
}

.el-select-dropdown {
  border-radius: 0 0 6px 6px;
  box-shadow: 0px 1px 8px rgba(116, 116, 116, 0.5);
  &.popper-higher {
    min-height: 250px;

    .el-scrollbar,
    .el-scrollbar__wrap {
      max-height: 500px;
      height: 100%;
    }
  }
  .el-scrollbar {
    padding-bottom: 8px;
  }
  .el-scrollbar-dropdown__wrap {
    overflow: hidden;
  }
  .el-select-dropdown .el-scrollbar .el-scrollbar__bar {
    display: none;
  }
  .el-scrollbar__bar.is-vertical, .el-scrollbar__bar.is-horizontal {
    display: none;
  }
  .el-scrollbar__wrap {
    margin-right: 0 !important;
    overflow-x: hidden;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: thin;
  }
  .el-scrollbar .el-scrollbar__bar {
    opacity: 1;
  }
  &.el-popper[x-placement^='bottom'] {
    margin-top: 2px;
  }

  &.el-popper[x-placement^='top'] {
    margin-bottom: 2px !important;
  }

  .popper__arrow {
    display: none;
  }
}

.el-date-table {
  td.start-date span,
  td.end-date span {
    color: $color-white;
    .theme-employee & {
      background-color: $color-primary-dark-employee;
    }
    .theme-guardian & {
      background-color: $color-primary-dark-guardian;
    }
    .theme-child & {
      background-color: $color-primary-dark-child;
    }
    .theme-admin & {
      background-color: $color-primary-dark-admin;
    }
  }
}

input[type='text'],
textarea {
  &::placeholder {
    color: $color-help-text !important;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color-help-text !important;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color-help-text !important;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color-help-text !important;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color-help-text !important;
  }
}

.mce-edit-area {
  span {
    color: $color-help-text !important; /* Override text color */
  }
}

.input-group {
  border-width: var(--input-group-border-width, unset);
  border-style: var(--input-group-border-style, unset);
  border-color: var(--input-group-border-color, unset);
  border-radius: 5px;
  overflow: hidden;

  &.search-module {
    border-radius: 5px;
    position: relative;

    &:focus-within {
      outline: 1px solid var(--color-primary-dark);
    }
    .input-group-prepend {
      position: absolute;
      right: 15px;
      top: var(--padding-top, 15px);
    }
  }
}

.input-group.search-input-group {
  .input-group-prepend {
    position: absolute;
  }
}

.el-date-editor .el-input__icon,
.input-group-append .icon-Aula_edit_pen,
.input-group-prepend .icon-Aula_search,
.input-group-prepend .icon-tag {
  cursor: pointer;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.btn:disabled {
  pointer-events: none;
}

.spinner-border {
  color: var(--color-primary-dark);
}

textarea.form-control {
  &:focus-visible {
    border-color: var(--color-lightblue);
  }
}

.aula-search-popper {
  --highlighted-color: var(--color-primary-darker);
}

.resource-search {
  .el-select-dropdown__item {
    height: unset;
  }
  .resource-availability {
    padding-left: 26px;
    font-size: 14px;
    line-height: unset;
    margin-top: -14px;
  }
}

.progress-bar {
  background-color: var(--color-primary-dark);
}
