.content-link-in-text {
  color: $color-primary-base;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.content-link-bottom {
  color: $color-primary-base;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.functional-link {
  font-weight: 900;
  cursor: pointer;

  .theme-employee & {
    color: $color-primary-darker-employee;
  }

  .theme-guardian & {
    color: $color-primary-darker-guardian;
  }

  .theme-child & {
    color: $color-primary-darker-child;
  }

  .theme-admin & {
    color: $color-primary-darker-admin;
  }

  &:hover {
    text-decoration: underline;
  }
}

help-text-link {
  color: $color-help-text;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.cursor-pointer {
  cursor: pointer;
}

blockquote {
  display: block;
  background-color: rgba(0, 0, 0, 0.05);
  border-left: solid 5px;
  padding: 20px 15px 10px 15px;
  margin: 0 0 10px 0;
  line-height: 1.5;
  position: relative;

  &::before {
    font-family: 'Times New Roman', Times, serif;
    position: absolute;
    left: 5px;
    top: -10px;
    font-size: 40px;
    content: '\201C';
  }

  &::after {
    font-family: 'Times New Roman', Times, serif;
    position: absolute;
    right: 10px;
    bottom: -30px;
    font-size: 40px;
    content: '\201D';
  }
}

@mixin placeholder($color, $size:"") {
  &::-webkit-input-placeholder {
    color: $color !important;

    @if $size != "" {
      font-size: $size;
    }
  }

  &:-moz-placeholder {
    color: $color !important;

    @if $size != "" {
      font-size: $size;
    }
  }

  &::-moz-placeholder {
    color: $color !important;

    @if $size != "" {
      font-size: $size;
    }
  }

  &:-ms-input-placeholder {
    color: $color !important;

    @if $size != "" {
      font-size: $size;
    }
  }
}
