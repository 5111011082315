@import '../../core/icons.scss';
@import '../../core/mixins.scss';

.media-detail-modal,
.notice-board-media-container {
  .modal-aula {
    width: 70vw;
    max-width: 70vw;
    margin: auto;
    max-height: 90vh;
    background-color: $color-grey-light;
    top: 0;

    @include breakpoint-lg() {
      top: 10vh;
      /**
       * {@link https://jira.ncaula.com/browse/AULA-55019}
       * Remove transform to fix an issue with blurry text when it moves to the sub-pixel position
       */
      transform: none;
      left: 15vw;
    }

    @include breakpoint-lg-down() {
      width: 100vw;
      max-width: 100vw;
      max-height: 100vh;
    }

    > .modal-content {
      max-height: 90vh;

      @include breakpoint-lg-down() {
        width: unset;
        max-height: unset;
        height: unset;
      }

      .modal-body {
        padding: 0;
        max-height: unset;
      }
    }
  }

  .open-media-detail {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
    width: 109px;
    border-radius: 35px;
    padding: 9px 28px;
    background-color: $color-grey-base;
    color: $color-primary-darker;
    z-index: 1000;
    box-shadow: var(--box-shadow-base);
  }

  .media-carousel {
    background: var(--color-white);

    .notice-boards & {
      background: transparent;
    }

    .carousel-inner {
      @include breakpoint-lg() {
        width: calc(100% - 100px);
        margin: 0 auto;
      }
      .notice-boards & {
        width: 100%;
        height: 100%;
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      width: 50px;
      min-width: 50px;

      @include breakpoint-lg-down() {
        min-width: 45px;
      }
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      height: 50px;
      width: 50px;
      background: black;
      border-radius: 50%;
      opacity: 0.3;
      background-image: none;
      position: relative;

      @include breakpoint-lg-down() {
        height: 40px;
        width: 40px;
      }

      @include breakpoint-lg-down() {
        display: none;
      }
    }

    .carousel-control-next-icon::after {
      @include add-icon('Aula_forward-arrow');

      font-size: 20px;
      color: $color-white;
      margin: auto;
      display: block;
      margin-top: 14px;
      opacity: 1;

      @include breakpoint-lg-down() {
        font-size: 15px;
        margin-top: 10px;
      }
    }

    .carousel-control-prev-icon::after {
      @include add-icon('Aula_back-arrow');

      font-size: 20px;
      color: $color-white;
      margin: auto;
      display: block;
      margin-top: 14px;
      opacity: 1;

      @include breakpoint-lg-down() {
        font-size: 15px;
        margin-top: 10px;
      }
    }

    &.media-detail .carousel-item {
      height: 70vh;
      width: 100%;
      text-align: center;

      .audio {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .carousel-caption {
        right: 0;
        top: 0;
        left: 0;
        color: $color-bg-black;
        text-align: right;
        max-height: 90px;

        i {
          margin-left: 10px;
          cursor: pointer;
          color: $color-white;
          font-size: 30px;
          position: absolute;
          bottom: 35px;
        }
      }

      img,
      video {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        &.w-100 {
          width: unset !important;
        }
      }

      .img {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  .information-overlay {
    position: absolute;
    height: 100%;
    z-index: $media-information-overlay-z-index;
  }

  .media-details {
    background: $color-white;
    padding: 20px;
    height: fit-content;
    padding-top: 80px;
    font-size: 14px;

    @include breakpoint-lg-down() {
      position: absolute;
      width: 100%;
      top: 0;
      background-color: var(--color-translucent-white);
      padding-top: 20px;
      box-shadow: var(--box-shadow-base);
    }

    label {
      text-transform: unset;
      font-weight: normal;
    }

    .existing-tag {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-right: 8px;

      .icon-Aula_close {
        top: 5px;
      }
    }

    .media-actions {
      position: absolute;
      left: 15px;
      top: 15px;

      @include breakpoint-lg-down() {
        right: 12px;
        left: unset;
        top: unset;
        bottom: 20px;
      }

      .dropdown-select {
        .dropdown-menu {
          @include breakpoint-lg-down() {
            right: 0;
            left: auto !important;
          }
        }
      }
    }

    .media-tags,
    .media-shared-with {
      .media-tag-recipients {
        min-height: 42px;
      }

      .icon-Aula_down-arrow {
        font-size: 0.5rem;
      }

      .dropdown-select {
        &.tag-panel {
          .dropdown-menu {
            background-color: var(--color-grey-light);
            min-width: var(--tag-media-dropdown-min-with,500px) !important;

            .el-input__inner {
              background: var(--color-white);
            }
          }
        }
        .dropdown-menu {
          padding-left: 10px;
          padding-right: 10px;
          min-width: 270px !important;

          .el-input__inner {
            background: $color-grey-light;
          }
        }
      }
    }

    .media-comments {
      input[type='text'] {
        background: $color-grey-light;
      }
    }

    .comments-container {
      input[type='text'] {
        background: $color-grey-light;
      }
    }
  }

  .thumbnails-container {
    padding: 5px 0 5px 0;
    background-color: var(--color-grey-light);
    display: flex;
    flex-wrap: wrap;

    .media-thumbnails {
      display: flex;
      flex: 0 0 92%;
      gap: 3px;
      overflow-x: hidden;

      @include breakpoint-lg-down() {
        flex: 0 0 100vw;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
      }

      .thumbnail-box {
        @include default-thumbnail();

        display: inline-block;
        width: 100px;
        height: 100px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        min-width: 100px;

        @include breakpoint-lg-down() {
          height: 100px;
        }

        &.opacity {
          opacity: 0.6;
        }

        .thumbnail-circle {
          width: 50px;
          height: 50px;
          top: calc(50% - 25px);
          left: calc(50% - 25px);

          i {
            font-size: 20px;
            position: absolute;
            top: 10px;
            left: 30%;
          }
        }
      }
    }
  }

  .media-thumbnails-prev,
  .media-thumbnails-next {
    cursor: pointer;
    position: relative;
    flex: 0 0 4%;

    i {
      position: absolute;
      top: 40%;
      left: 50%;
    }
  }

  &.modal-fullscreen {
    .carousel-item {
      height: 100vh !important;
    }
  }
}
.attachment-media {
  display: inline-flex;
  position: relative;
  height: 250px;

  &.first-thumbnail {
    height: 400px;
  }

  @include breakpoint-lg-down() {
    height: 150px;

    &.first-thumbnail {
      max-height: 150px;
    }
  }

  .media-remaining {
    position: absolute;
    inset: 0 15px;
    display: grid;
    place-items: center;
    color: $color-white;
    font-size: 20px;
    text-shadow: 1px 1px 4px #000;
    background: rgba(0, 0, 0, 0.3);
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }

  .thumbnail-circle {
    width: 100px;
    height: 100px;
  }

  .download-link {
    position: absolute;
    right: 25px;
    top: 10px;
    width: 25px;
    height: 25px;
    background: $color-white;
    opacity: 0.8;
    border-radius: 50%;
    padding-left: 5px;
    cursor: pointer;
    display: none;
  }

  &:hover {
    .download-link {
      display: block;
    }
  }
}
