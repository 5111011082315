























































































































































































































































































@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
@import '../../assets/scss/components/login/login.scss';

.main-toastr-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;

  @include breakpoint-sm-down() {
    width: auto;
    top: 20px;
    right: 20px;
    left: 20px;
  }
}
