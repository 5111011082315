


















button.btn-clear {
  background-color: transparent;
  &:not(:disabled):hover,
  &:not(:disabled):active {
    background-color: transparent;
  }
}
