@import '../core/icons.scss';

.calendar-container {
  height: 100%;

  .dropdown-select.show {
    .btn-secondary.dropdown-toggle {
      z-index: 1005 !important;
    }

    .dropdown-menu.show {
      z-index: 1004;
    }
  }

  &.hide-container {
    @include breakpoint-lg-down() {
      height: 0;
      overflow: hidden;
    }
  }

  .event-button-space {
    margin-left: 20px;
  }

  .month-button-visible {
    visibility: visible;
  }

  .month-button {
    visibility: hidden;
  }

  .event-button-space-50 {
    margin-left: 50px;
  }

  .check-box-icon-accepted-event {
    position: absolute;
    bottom: 1px;
    font-size: 20px;
  }

  .el-switch .el-switch__core {
    bottom: 5px !important;
  }

  .el-switch-single-event {
    margin-left: 20px !important;
    bottom: 5px !important;
  }

  .event-break-line {
    margin: 10px -10px 10px -10px;
    border-bottom: 1px solid #fff;
  }

  .custom-calendar-title {
    position: absolute;
    top: 60px;
    left: 20px;
    font-family: Lato;
    font-size: 20px;
    font-weight: 900;
    line-height: 44px;
    text-align: center;

    .notice-boards & {
      top: -15px;
      left: 0;
      font-size: 26px;
    }

    &.month {
      text-transform: capitalize;
    }

    @include breakpoint-lg-down() {
      top: 430px;
      left: unset;
      right: 20px;
      z-index: 1034;
      font-size: 12px;
    }
  }

  .aula-circle-calendar {
    font-size: 12px;
    margin-top: 13px;
  }

  .btn-link.disabled {
    color: red;
  }

  .delegated-access-text {
    color: #b50050;
    padding: 0 25px;
    margin-top: 20px;
    cursor: pointer;

    @include breakpoint-lg-down() {
      margin-bottom: 10px;
    }

    .circle {
      display: inline;
      width: 25px;
      background: #b50050;
      border-radius: 50%;
      margin-right: 10px;
      height: 25px;
      font-size: 1.5em;
      padding: 3px;
      margin-top: -15px;

      i {
        margin: 12px;
        color: #fff;
      }
    }
  }
}

.delegated-access-modal {
  .el-input__inner {
    background: $color-grey-light;
  }

  .modal-aula {
    @include breakpoint-lg {
      min-width: 500px;
    }
  }

  .modal-body {
    min-height: 450px;
  }
}

.el-picker-panel {
  .el-picker-panel__content {
    /deep/ .start-date,
    .end-date {
      span {
        color: $color-darkblue;
        background-color: $color-grey-base !important;
      }
    }

    .is-week-mode {
      .el-date-table__row {
        .today span {
          .theme-employee & {
            color: $color-primary-darker-employee;
          }

          .theme-guardian & {
            color: $color-primary-darker-guardian;
          }

          .theme-child & {
            color: $color-primary-darker-child;
          }

          .theme-admin & {
            color: $color-primary-darker-admin;
          }
        }

        td div {
          height: 45px;
        }

        &:hover {
          td div,
          td span {
            .theme-employee & {
              background-color: $color-primary-base-employee;
            }

            .theme-guardian & {
              background-color: $color-primary-base-guardian;
            }

            .theme-child & {
              background-color: $color-primary-base-child;
            }

            .theme-admin & {
              background-color: $color-primary-base-admin;
            }
            color: $color-white;
          }

          td {
            &:last-child div {
              border-top-right-radius: 25px;
              border-bottom-right-radius: 25px;
            }

            &:first-child div {
              border-top-left-radius: 25px;
              border-bottom-left-radius: 25px;
            }
          }

          td.today span {
            color: $color-white;
          }
        }

        &.current {
          td {
            &.end-date div {
              border-top-right-radius: 25px;
              border-bottom-right-radius: 25px;
            }

            &.start-date div {
              border-top-left-radius: 25px;
              border-bottom-left-radius: 25px;
            }
          }
        }
      }
    }
  }
}

.aula-calendar {
  position: relative;
  padding: 20px 20px 60px 20px;

  .notice-boards & {
    padding: 0;
  }

  .el-picker-panel {
    @include breakpoint-lg-down() {
      border: 0;
      border-top: 1px solid $color-grey-base;

      .el-picker-panel__content {
        padding-left: 20px;

        > .today {
          color: $color-darkblue;
        }
      }
    }
  }

  &.daily-overview {
    padding: 4px;
    box-shadow: var(--box-shadow-base);
    transition: right 200ms ease;
  }

  @include breakpoint-lg-down() {
    padding: 420px 0 0 0;
  }

  .datetime-input {
    position: absolute;
    left: 66px;
    top: 40px;
    width: 0;
    height: 0;

    .el-input__inner {
      background: transparent;
      border: none;
      padding: 0;
      width: 0;
      height: 0;
    }

    .el-input__prefix {
      display: none;
    }
  }

  #calendar {
    &.fc-unthemed  {
      th,
      td {
        border-color: var(--color-grey-base);
      }
      .fc-list-empty {
        .notice-boards & {
          background-color: $color-white;
          text-align: left;
        }
      }
    }

    .fc-view-container {
      .fc-axis,
      td,
      th,
      .fc-row {
        @include breakpoint-lg-down() {
          border: 0 !important;
        }
      }

      .fc-dayGridMonth-view {
        .fc-day-header {
          text-align: left;

          span {
            font-weight: 400;

            @include breakpoint-lg-down() {
              font-size: 11px;
            }
          }
        }

        .fc-widget-header {
          .fc-day-header {
            padding: 19px 8px 19px 10px;
            line-height: 20px;
          }
        }

        .fc-week-number {
          padding: 10px 0 0 0;
          width: 58px !important;
        }

        .fc-today {
          .fc-day-number {
            width: 35px;
            height: 30px;
            padding: 0;
            padding-top: 5px;
            border-radius: 50%;
            z-index: 1000;
            text-align: center;
          }
        }
      }
    }
  }

  #daily-calendar {
    display: inline;
    .fc-header-toolbar {
      @include breakpoint-xs-down() {
        display: block;
        &:before {
          top: 0;
          left: 52px;
        }
      }
    }
  }

  #calendar,
  #daily-calendar {
    .fc-view-container {
      .fc-view .fc-popover.fc-more-popover {
        width: var(--fcDayColumnWidth);
        margin-top: var(--fcPopoverOffsetTop);
        .fc-header.fc-widget-header {
          background-color: $color-grey-dark;
          .fc-title::first-letter {
            text-transform: uppercase;
          }
        }

        .fc-event-container {
          padding: 2px;

          .fc-day-grid-event:not(:first-child) {
            border-top: 1px solid var(--color-white);
          }
        }
      }

      .fc-more-cell .fc-more {
        margin: 0;
        padding: 2px 3px;
        display: block;
        width: auto;
        height: 100%;
      }
    }
  }

  #calendar,
  #group-calendar,
  #daily-calendar,
  #planning-assistant {
    &.fc-unthemed td.fc-today {
      background: transparent !important;
    }

    .fc-header-toolbar {
      margin-bottom: 1em;

      @include breakpoint-lg-down() {
        display: none;
      }

      h2 {
        font-weight: normal;
      }

      text-transform: uppercase;

      .fc-button {
        text-shadow: none;
        box-shadow: none;
        background: transparent;
        text-transform: uppercase;
        border: none;

        &.fc-button-active {
          pointer-events: none;
        }
      }

      .fc-icon {
        font-size: unset;
        font-family: 'aula' !important;
        width: unset;
        height: unset;
      }

      .fc-left {
        left: 75px;
        top: 0px;

        &--on-month-calender {
          left: 100px;
        }

        position: relative;

        h2 {
          margin-left: 7px;
          font-family: Lato;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          display: inline;
        }

        .fc-button-group {
          margin-top: -7px;
        }

        .fc-button-primary {
          &.fc-prev-button,
          &.fc-next-button {
            font-size: 0.8em;
          }

          .theme-employee & {
            color: $color-primary-darker-employee;
          }

          .theme-guardian & {
            color: $color-primary-darker-guardian;
          }

          .theme-child & {
            color: $color-primary-darker-child;
          }

          .theme-admin & {
            color: $color-primary-darker-admin;
          }
        }

        .icon-Aula_down-arrow {
          font-size: 6px;
        }

        .fc-openCalendar-button {
          background: white;
          padding: 0 4px 2px 5px;
          margin-top: -7px;
          height: 1.5em;

          &:hover {
            .theme-employee & {
              background-color: $color-primary-darker-employee;
            }

            .theme-guardian & {
              background-color: $color-primary-darker-guardian;
            }

            .theme-child & {
              background-color: $color-primary-darker-child;
            }

            .theme-admin & {
              background-color: $color-primary-darker-admin;
            }

            color: white;
          }
        }
      }

      .fc-right {
        @include breakpoint-xl-down() {
          padding-top: 20px;
        }

        @include breakpoint-lg-down() {
          padding-top: 0;
        }

        @include breakpoint-lg-down() {
          padding-top: 20px;
        }

        .fc-today-button {
          padding: 0;
        }

        .fc-button-active {
          &::after {
            top: 105%;
            left: 50%;
            border: solid transparent;
            content: ' ';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-top-color: $color-primary-darker;
            border-width: 8px;
            margin-left: -8px;
            z-index: 1002;
          }
        }
      }
    }

    .fc-view-container {
      @include breakpoint-lg-down() {
        background: $color-grey-light;
      }

      .notice-boards & {
        background-color: var(--color-white);
      }

      .fc-row {
        border-bottom: 1px solid $color-grey-base;
      }

      .fc-bgevent {
        background: transparent !important;
      }

      .fc-day-header {
        border-right: 2px solid $color-grey-light;
      }

      .fc-axis {
        border-right: 2px solid $color-grey-light;

        &.fc-time {
          span {
            position: relative;
          }
        }
      }

      .fc-head-container {
        .fc-day-header:not(:first-child) {
          text-transform: uppercase;
          color: $color-white;
          font-size: 14px;
          font-weight: 400;
        }

        tr:nth-child(2) .fc-week-number span {
          display: none;
        }

        th:first-child {
          background-color: $color-primary-light-admin;
        }

        .fc-day-header {
          padding: 17px 0;
          font-weight: normal;
        }

        .fc-resource-cell {
          padding-top: 10px;
          padding-bottom: 10px;
          position: relative;

          .notice-boards &,
          .group-modal & {
            display: none;
          }
          .calendar-resource {
            --width: 40px;
            --height: 40px;
            --font-size: 12px;
            --background-color: var(--color-grey-dark);
            --color: var(--color-primary-darker);

            margin: auto;
          }
        }
      }

      .fc-axis,
      .fc-week-number {
        padding: 0 10px 0 11px;
        font-size: 13px;

        @include breakpoint-lg-down() {
          padding: 0 10px 0 0;
        }
      }

      .fc-time.fc-widget-content,
      .fc-week-number {
        background-color: $color-grey-dark;
        text-transform: uppercase;
      }

      .fc-row.fc-week.fc-widget-content {
        min-height: auto;

        .fc-content-skeleton {
          padding-bottom: 0;
        }
      }

      .fc-divider.fc-widget-header {
        display: none;
      }

      .fc-event-container {
        margin: 0;

        .fc-event.fc-time-grid-event,
        .fc-event.fc-timeline-event,
        .fc-day-grid-event {
          &.accepted::before,
          &.tentative::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 8px;
            content: '';
          }
        }

        .fc-event.fc-time-grid-event,
        .fc-event.fc-timeline-event {
          background: $color-white !important;
          border-width: 0;
          border-radius: 0;
          cursor: pointer;
          margin: 0 !important;
          color: $color-primary-darker;
          font-size: 14px;
          font-weight: 700;
          padding-left: 8px;

          &.accepted::before {
            background: var(--color-grey-dunkel);
          }

          &.tentative::before {
            background: repeating-linear-gradient(
              135deg,
              var(--color-white),
              var(--color-white) 5px,
              var(--color-grey-dunkel) 5px,
              var(--color-grey-dunkel) 10px
            );
          }

          &.event,
          &.holiday,
          &.other,
          &.excursion,
          &.school_home_meeting,
          &.parental_meeting,
          &.performance_meeting {
            background: $color-grey-medium !important;
          }

          &.event-waiting-response {
            color: $color-conversation-left !important;
            font-weight: 500;
          }

          &.old-event,
          &.deleted-lesson {
            text-decoration: line-through;
          }

          &.lesson,
          &.event {
            box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.5);
            transition: box-shadow ease 150ms;

            &:hover {
              box-shadow: var(--box-shadow-base);
            }
          }
        }

        .fc-day-grid-event {
          position: relative;
          border: 0;
          margin: 0;
          color: $color-grey-light !important;
          font-size: 14px;
          font-weight: normal;
          height: 100%;
          border-radius: 0;
          padding-left: 8px;
          padding-right: 5px;

          &.accepted::before {
            background: var(--color-primary-darker);
          }

          &.tentative::before {
            background: repeating-linear-gradient(
              135deg,
              var(--color-white),
              var(--color-white) 5px,
              var(--color-primary-darker) 5px,
              var(--color-primary-darker) 10px
            );
          }
        }

        .day-view-details {
          font-size: 12px;
        }
      }

      .user-img {
        height: 36px;
        width: 36px;
        max-height: 36px;
        max-width: 36px;
        position: relative;
        object-fit: cover;
        border-radius: 50%;
        outline: 2px solid var(--color-grey-light);
        overflow: hidden;
      }

      .user-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 36px;
        width: 36px;
        background-color: var(--color-white);
        color: var(--color-primary-dark);
        border: 2px solid var(--color-primary-dark);
        outline: 2px solid var(--color-white);

        span {
          text-align: center;
          font-size: 1rem;

          &.length3 {
            font-size: 0.95rem;
          }

          &.length4 {
            font-size: 0.85rem;
          }

          &.length5 {
            font-size: 0.75rem;
          }
        }
      }

      .fc-resourceTimeGridWeek-view {
        .fc-day-grid {
          tr {
            background-color: $color-grey-base;
          }

          .fc-axis {
            background-color: $color-primary-light-admin;
          }
        }

        .fc-event-container {
          .week-view-event-group {
            .week-view-title-event {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }

      .fc-dayGridMonth-view {
        th,
        td {
          border-right: 0 !important;
        }

        .fc-day-number {
          float: left !important;
          color: $color-primary-darker-admin;
          font-weight: 500;
          padding: 5px 0 0 10px;
          font-size: 18px;
        }

        .fc-event-container {
          .month-view-title-event {
            flex: 70%;
            justify-content: flex-start;
            text-indent: 0;
            overflow: hidden;
            line-height: 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .month-view-count-event {
            flex: auto;
            min-width: 40px;
            text-align: center;
            padding: 1px 0;
            background-color: $color-grey-base;
          }

          .month-view-event-group {
            width: 96%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 38px;
            font-weight: 500;
            font-size: 15px;
            line-height: 36px;
            padding: 0 0 0 5px;
            color: $color-darkblue;
            background-color: $color-grey-light;
            border: 1px solid $color-white;
          }

          .day-view-title-event {
            flex-grow: 8;
            text-indent: 3px;
          }

          .day-view-event-group {
            display: flex;
          }
        }

        .fc-row.fc-week.fc-widget-content {
          min-height: 166px;
        }

        .fc-day-grid-event.sum-event {
          background-color: transparent !important;
        }
      }

      .fc-resourceTimeGridFiveDay-view {
        .fc-day-grid {
          tr {
            background-color: $color-grey-base;
          }

          .fc-axis {
            background-color: $color-primary-light-admin;
          }
        }

        .fc-event-container {
          .week-view-event-group {
            .week-view-title-event {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }

      .fc-resourceTimeGridDay-view {
        .fc-day-grid {
          tr {
            background-color: $color-grey-base;
          }

          .fc-axis {
            background-color: $color-primary-light-admin;
          }
        }

        .fc-widget-header {
          .fc-day-header {
            border: 0;
            padding: 17px 8px 17px 14px;
            text-align: left;
          }
        }
      }

      .fc-list-view {
        border: none;
        .notice-boards & {
          border: 0;
        }
      }

      .fc-list-table {
        table-layout: fixed;
        .fc-list-heading {
          .fc-widget-header {
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            z-index: 10;
            background: $color-white;
            text-transform: uppercase;
            padding: 12px 20px;
            font-size: 14px;
            color: $color-primary-darker-guardian;
          }

          .notice-boards & {
            display: none;
          }
        }

        .fc-list-item {
          &.tentative .day-view-event-group .day-view-time-event::before,
          &.accepted .day-view-event-group .day-view-time-event::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 10px;
            content: '';
          }
          &.accepted .day-view-event-group .day-view-time-event::before {
            background: var(--color-grey-dunkel);
          }

          &.tentative .day-view-event-group .day-view-time-event::before {
            background: repeating-linear-gradient(
              135deg,
              var(--color-white),
              var(--color-white) 5px,
              var(--color-grey-dunkel) 5px,
              var(--color-grey-dunkel) 10px
            );
          }

          .event-list-item {
            padding: 0;
          }

          .day-view-event-group {
            display: flex;
            gap: 8px;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: stretch;
            align-content: flex-start;
            background-color: var(--color-grey-lighter);

            .day-view-time-event,
            .day-view-date-event {
              background: var(--color-grey-base);
              padding: 12px;
              font-size: 12px;
              min-width: 50px;
              text-align: center;
              position: relative;
              border-bottom: 1px solid var(--color-white);

              .notice-boards & {
                text-align: left;
                margin-right: 5px;
                font-size: 20px;
                background: transparent;
                border: 0;
                padding-left: 0;
                min-width: 70px;
                @include breakpoint-xl() {
                  min-width: 150px;
                }
              }
            }

            .day-view-time-event {
              .notice-boards & {
                min-width: 50px;
                @include breakpoint-xl() {
                  min-width: 150px;
                }
              }

              &.mobile-whole-day {
                padding-top: 20px;
              }
            }

            .day-view-title-event {
              text-align: left;
              padding: 10px;
              font-size: 13px;
              margin: 0 auto 0 0;
              position: relative;

              .day-view-details {
                color: $color-conversation-left !important;
              }

              .day-view-substitute {
                color: $color-alert !important;
              }

              .notice-boards & {
                font-size: 20px;
              }
            }

            .mobile-view-title-event {
              font-size: 14px;
              flex-grow: 1;
              align-self: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              .day-view-details {
                color: $color-conversation-left !important;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .day-view-substitute {
                color: $color-alert !important;
              }
            }

            .mobile-view-picture-container {
              display: flex;
              align-items: center;
              direction: rtl;
              padding-right: 16px;
              margin-right: 8px;
            }

            .mobile-view-picture-event {
              margin-right: -16px;
            }
          }

          &.event-waiting-response {
            .day-view-title-event {
              opacity: 0.8;
              color: $color-conversation-left !important;
              font-weight: 500;
              margin-left: 10px;

              &::before {
                content: '';
                display: block;
                height: 0.65em;
                width: 0.65em;
                background: red;
                border-radius: 50%;
                position: absolute;
                margin-right: 10px;
                top: 15px;
                left: -2px; // size of bullet
              }
            }
          }

          &.old-event,
          &.deleted-lesson {
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

.aula-calendar__arrow-icon {
  position: absolute;
  bottom: 9px;
  left: 76px;
  background: white;
  padding: 10px 3px 2px 4px;
  height: 4em;
  border-radius: 3px;
}

.aula-calendar {
  #daily-calendar {
    .fc-axis,
    .fc-week-number {
      padding: 0 5px 0 5px !important;
    }

    .fc-view-container .fc-axis {
      border-right: none !important;
      width: 41px !important;
      text-align: center;
    }

    .fc-header-toolbar {
      background-color: $color-grey-dark !important;
      border-bottom: none !important;
      margin-bottom: 0;

      .fc-right {
        display: none;
      }

      .fc-left {
        left: 0;
        top: 0;

        .fc-button-group {
          margin-top: 13px;
        }

        .fc-today-button {
          color: $color-white !important;
        }
      }

      .fc-left {
        display: inline-flex;

        > button {
          font-size: 12px;
          height: 63px;
          width: 53px;
          color: $color-white;
          border-radius: 0;
          opacity: 1;

          .theme-employee & {
            background: $color-primary-darker-employee;
          }

          .theme-guardian & {
            background: $color-primary-darker-guardian;
          }

          .theme-child & {
            background: $color-primary-darker-child;
          }

          .theme-admin & {
            background: $color-primary-darker-admin;
          }
        }

        h2 {
          margin: auto 5px auto 25px !important;
          font-size: 14px !important;
          color: $color-primary-darker-guardian;
        }

        .fc-button-group {
          margin: auto 0;
        }
      }
    }

    .fc-content {
      margin: auto 5px !important;
      height: 100%;
      display: flex;

      .user-icon {
        width: 20px;
        height: 20px;
        margin-left: auto;
        align-self: center;
        span {
          font-size: 8px;
        }
      }

      .centered {
        text-align: center;
      }

      .vertical-middle {
        margin: auto;
      }

      .fc-title {
        font-size: 12px;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }

      .fc-info {
        font-size: 11px;
        font-weight: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .fc-event-container {
      .fc-event.fc-time-grid-event {
        cursor: default !important;
      }
    }
  }

  &.planning-assistant-modal {
    .modal-aula {
      transform: unset;
      min-width: 100vw;
      min-height: 100vh;
      top: 0;
      left: 0;

      .modal-body {
        padding-top: 40px;
      }

      .modal-footer {
        padding-bottom: 40px;
      }
    }

    .planning-assistant-datepicker {
      position: absolute !important;
      top: 60px !important;
      left: 160px !important;
    }

    .planning-assistant-timepickers {
      position: absolute;
      left: 320px;
      top: 30px;
      display: flex;
      z-index: 2;
    }

    #planning-assistant {
      .fc-header-toolbar {
        .fc-left {
          left: 0;
          top: 0;
          margin-bottom: 20px;
        }
      }

      .fc-view-container {
        td {
          border: 1px solid $color-grey-light;
        }

        .fc-head {
          .fc-resource-area {
            .fc-cell-content {
              font-size: 15px;
              text-transform: uppercase;
            }
          }
        }

        .fc-body {
          .fc-resource-area {
            .fc-cell-content {
              padding-top: 2px;
              background: $color-grey-dark;

              .fc-cell-text {
                width: 230px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .add-resource-btn {
                position: absolute;
                right: 10px;
              }

              .remove-resource-btn,
              .remove-invitee-btn {
                position: absolute;
                right: 15px;
              }

              .add-resource-btn .icon-Aula_plus.in-circle {
                background-color: white;

                .theme-employee & {
                  border: 1px solid $color-primary-darker-employee;
                  color: $color-primary-darker-employee;
                }

                .theme-guardian & {
                  border: 1px solid $color-primary-darker-guardian;
                  color: $color-primary-darker-guardian;
                }

                .theme-child & {
                  border: 1px solid $color-primary-darker-child;
                  color: $color-primary-darker-child;
                }

                .theme-admin & {
                  border: 1px solid $color-primary-darker-admin;
                  color: $color-primary-darker-admin;
                }
              }
            }

            .fc-widget-content {
              .fc-cell-content {
                background: white;
              }
            }
          }

          .fc-time-area {
            .fc-divider div {
              background: $color-grey-dark;
            }

            .fc-widget-content div {
              background: white;
            }
          }

          .fc-event-container {
            .fc-timeline-event {
              background: $color-grey-light !important;
              font-size: 12px;
            }
          }
        }
      }

      .add-resource-btn {
        .icon-plus {
          font-size: 10px;
        }
      }
    }

    .vue__time-picker {
      .display-time {
        background: $color-grey-light;
      }
    }

    .aula-search {
      .el-input__inner {
        background: $color-grey-light;
      }
    }
  }
}

.calendar-popover {
  border-radius: 0;
  font-size: 13px;
  z-index: 1052;

  @include breakpoint-lg() {
    border-radius: 5px;
    min-width: 300px;
    min-height: unset;
    max-width: 600px;

    .popover-event-container {
      max-height: calc(100vh - 20vh - 100px);
    }

    .modal-aula {
      max-width: 600px;
      width: 50vw;
      border-radius: 5px;
      overflow: inherit;
    }
  }

  &.multiple-events {
    .popover-header {
      background-color: var(--color-primary-dark);
      color: var(--color-white);
    }

    .popover-content {
      color: var(--color-primary-darker);
    }
  }

  &.single-event {
    .popover-header {
      color: var(--color-primary-darker);
    }

    .popover-content {
      color: var(--color-primary-darker);
    }
  }

  .modal-aula .modal-body {
    padding: 0;
    overflow: initial;
  }

  .popover-header {
    padding: 10px;
    font-weight: bold;
    border-bottom: none;
    background-color: var(--color-primary-dark);

    @include breakpoint-lg() {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .popover-header-text {
      color: var(--color-white);
    }

    .popover-header_close_icon {
      color: var(--color-white);
      padding: 10px 5px;
      margin-left: 8px;
    }

    @include breakpoint-lg-down() {
      width: 100%;
      top: 0;
      padding: 0;
      z-index: 1060;
      background-color: var(--color-primary-darker);
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .text {
        color: $color-white;
        line-height: 57px;
        text-align: center;
        font-size: 14px;
      }

      .icon-Aula_arrow_new_item {
        color: $color-white;
        font-weight: 900;
        font-size: 28px;
        display: inline-block;
        line-height: 57px;
        vertical-align: top;
        float: left;
        margin: 0 -48px 0 20px;
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
      }

      .action-button {
        position: absolute;
        height: 57px;
        top: 0;
        right: 18px;
        font-size: 13px;
        text-transform: capitalize;
        font-weight: normal;
      }
    }
  }

  .popover-content {
    background: $color-white;
    padding: 0;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;

    @include breakpoint-lg() {
      max-height: calc(80vh - 250px);
    }

    .divider {
      .divider-popover {
        display: block;
        height: 1px;

        .divider-popover-left-column {
          width: 35%;
          height: 100%;
          float: left;
          background: $color-grey-base;
          border-bottom: 1px solid white;
          border-top: 1px solid white;
        }

        .divider-popover-right-column {
          width: 65%;
          height: 100%;
          float: right;
          background: white;
          border-bottom: 1px solid $color-grey-base;
          border-top: 1px solid $color-grey-base;
        }
      }

      &--state {
        height: 12px !important;
      }
    }

    .popover-row {
      position: relative;
    }

    .popover-left-column {
      padding: 13px 10px;
      background-color: $color-grey-base;
      float: left;
      height: 100%;
      min-height: 49px;
      width: 35%;
      position: absolute;
    }

    .popover-right-column {
      padding: 13px 10px;
      background-color: $color-white;
      float: right;
      height: auto;
      min-height: 49px;
      width: calc(65% - 45px);
    }

    .without-icon {
      width: 65% !important;
    }

    .popover-icon-column {
      padding: 13px 13px;
      float: right;
      font-size: 1.5em;
      max-width: 45px;
      cursor: pointer;
    }

    .popover-title {
      color: $color-primary-darker;
      font-family: Lato;
      font-size: 18px;
      font-weight: 900;
      line-height: 22px;
      text-align: center;
    }

    .popover-text-editor {
      min-height: 100px;
      border-radius: 8px;
      background-color: transparent;
      line-height: normal;

      iframe {
        max-width: 100%;
      }

      a {
        text-decoration: underline;
        color: var(--color-primary-dark);
      }
    }

    .popover-line {
      width: 100%;
      border-top: 1px solid #d9e3e9;
      margin: 20px 0;
    }

    @include breakpoint-lg-down() {
      background-color: $color-grey-light !important;
    }
  }

  .popover-participant-seats-variables {
    --modal-inner-input-background-color: var(--color-white);
  }

  .popover-participant-seats {
    display: flex;
    gap: 20px;
    padding: 10px;
    border-top: 1px solid var(--color-white);
    .seat-select {
      max-width: 130px;

      input {
        background-color: var(--modal-inner-input-background-color);
      }
    }
  }

  .popover-footer {
    bottom: 0;
    background: $color-grey-base;
    padding: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid var(--color-white);

    .dropdown-select button.dropdown-item {
      padding-left: 10px;
      &:hover {
        background-color: var(--color-grey-light) !important;
      }
    }

    button {
      min-height: 30px;
      padding: 0 5px;
      font-size: 14px;
      line-height: 22px;
      font-family: Lato;
      color: $color-primary-darker;

      &.dropdown-item {
        height: 40px;
      }

      .icon-Aula_check {
        font-size: 18px;
      }

      .button-text {
        display: flex;
        align-items: center;
        gap: 3px;
        font-size: 12px;
        color: var(--color-primary-dark);
        text-transform: uppercase;
      }

      &:disabled {
        .button-text {
          color: $color-primary-darker;
        }
      }

      .theme-guardian &,
      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }

      .theme-employee & {
        color: $color-primary-darker-employee;
      }

      .icon-Aula_forward-arrow {
        margin-left: 5px;
        font-size: 8px;
      }
    }

    @include breakpoint-lg-down() {
      width: 100%;

      .popover-mobile-button {
        width: 100%;
        height: 60px;
        bottom: 110px;
        background: $color-grey-base;

        button {
          width: 100%;
          height: 60px;

          .icon-Aula_check {
            position: absolute;
            top: 22px;
          }
        }
      }

      .delete-button {
        color: $color-alert !important;
      }
    }
  }

  &.multiple-events {
    &.month {
      width: 300px;

      .popover-header-text {
        text-transform: uppercase;
      }
    }
  }

  &.single-event {
    .modal-body {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: $color-grey-base;

      @include breakpoint-lg-down() {
        overflow: auto;
      }

      .popover-content {
        padding: 15px 10px 50px 10px;
        background-color: $color-grey-base;
        line-height: 20px;
        height: 100%;
      }

      .popover-alerts {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .divider-popover {
    display: none;
  }
}

.title-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  min-width: 0;

  &.one-line {
    > div {
      display: flex;
      min-width: 0;
      flex-shrink: 0;

      &.has-badge {
        max-width: calc(100% - 18px);
      }
      &:not(.has-badge) {
        max-width: 100%;
      }

      :not(.icon) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .icon {
        padding-left: 5px;
        margin: auto 0;
        flex-shrink: 0
      }
    }

    > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-shrink: 1;
    }
  }

  &.two-lines {
    .event-badge {
      margin: auto 2px;
      flex-shrink: 0;
    }

    > div {
      display: flex;
      flex-direction: column;
      width:100%;

      > div {
        display: flex;
        min-width: 0;

        span:not(.icon) {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        span.icon {
          flex-shrink: 0;
          padding-left: 5px;
          margin: auto 0;
        }
      }
    }
  }
}

.vacation-request-details {
  font-size: 14px;

  div .row .col {
    line-height: 25px;
  }

  .modal-aula {
    width: 50vw;

    @include breakpoint-lg-down() {
      width: 100vw;
      height: calc(100% - 40px) !important;
      background: $color-grey-modal-light;
    }
  }

  .modal-body {
    @include breakpoint-lg-down() {
      padding: 0;
      background: $color-grey-modal-light;
    }
  }

  .show-more-pending-answers {
    cursor: pointer;
    i {
      font-size: 7px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .icon-Aula_up-arrow {
    font-size: 8px;
  }

  .week-overview {
    .week-header {
      background: $color-grey-dark;
      padding: 10px 20px;
      font-weight: 700;
      font-size: 16px;
    }

    .week-days {
      .week-day {
        padding: 5px;
        @include breakpoint-lg-down() {
          border-bottom: 1px solid $color-grey-dunkel;
        }
      }

      .icon-Aula_down-arrow {
        font-size: 8px;
      }
    }
  }
}

.popover-footer--for-non-schedule {
  background-color: white !important;
  height: 49px;
}

.calendar-datepicker {
  top: 65px !important;
  left: 30px !important;

  @include breakpoint-lg-down() {
    top: -31px !important;
    left: -56px !important;
    min-height: 423px;
    width: 100vw;
    position: absolute !important;
    z-index: 1000 !important;
    margin: -10px 0 0 -10px !important;
  }
}

.group-calendar-datepicker {
  @include breakpoint-lg-down() {
    top: -35px !important;
    left: -65px !important;
    min-height: 423px;
    width: 100vw;
    position: absolute !important;
    z-index: 1000 !important;
  }
}

.calendar-drawer {
  padding-bottom: 50px;
  .drawer-content {
    .el-select {
      margin-top: 0;
    }

    .el-input--prefix .el-input__inner {
      padding-left: 15px;
      padding-right: 35px;

      @include breakpoint-tablet-portrait() {
        padding-left: 10px;
      }
    }

    .no_date_icon {
      .el-input__prefix {
        right: 10px !important;
        top: 3px !important;

        &::before {
          content: none;
        }
      }
    }

    .el-date-editor.el-input {
      width: 100%;

      .el-input__prefix {
        right: 12px;
        top: 2px;
        left: unset;
        color: #000;
      }

      .el-input__suffix {
        right: 40px;
        top: 2px;
        color: #000;
      }
    }

    .meeting_date {
      margin-left: 0;

      .el-input__inner {
        padding-left: 0 !important;
        border: 0;
        background: transparent;
        text-transform: capitalize;
        font-weight: bold;
      }

      .el-input__suffix {
        display: none;
      }

      .el-input__suffix-inner {
        display: none;
      }
    }

    .mobile_switch {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 3px 0;
      border-top: 1px #fff solid;

      label {
        margin-top: 5px !important;
      }
    }

    .line {
      color: var(--color-white);
      border-color: var(--color-white);
      margin-top: 25px;
      margin-bottom: 25px;

      @include breakpoint-lg() {
        margin-top: 30px;
        margin-bottom: 30px;
        color: var(--color-grey-dark);
        border-color: var(--color-grey-dark);
      }
    }

    .el-dialog-container {
      position: relative;
    }

    .el-dialog__wrapper {
      position: absolute;
      height: 600px;
    }

    .el-dialog {
      border-radius: 0.25rem;

      @include breakpoint-lg-down() {
        width: 90% !important;
      }
    }

    .el-dialog__header {
      border-radius: 0.25rem 0.25rem 0 0;
      background-color: $color-grey-base;
      text-transform: uppercase;

      .el-dialog__title {
        color: $color-darkblue;
      }
    }

    .el-dialog__body {
      padding: 10px 0 0 0;
      color: $color-darkblue;
    }

    .series_form,
    .choose_institution {
      input[type='text'],
      input[type='number'] {
        background: $color-grey-lighter;
      }

      @include breakpoint-lg-down() {
        padding: 10px 20px;
      }

      padding: 10px 30px;

      .form-inline {
        margin-bottom: 10px;
      }

      .form-inline .text_inline {
        width: 80px;
        margin-left: 10px;
        margin-right: 10px;
      }

      .form-inline .dropdown_inline {
        width: 100px;
        margin-left: 10px;
        margin-right: 10px;
      }

      .custom-checkbox {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .calendar-event-form {
      input:disabled:not(.el-select__input) {
        border: 1px solid $color-form-border;
        border-radius: 5px;
        background-color: $color-grey-light;
      }
    }

    .institution {
      @include breakpoint-lg-down() {
        margin-top: 30px;
        display: block;
        border-color: #fff;
      }

      .el-tag--resource {
        background-color: $color-grey-base;
        font-size: 16px;
        border-color: $color-grey-base;
        height: auto;
        padding: 10px 10px 10px 25px;
        width: 100%;
        margin-bottom: 10px;

        .theme-employee & {
          color: $color-primary-darker-employee;
        }

        .theme-guardian & {
          color: $color-primary-darker-guardian;
        }

        .theme-child & {
          color: $color-primary-darker-child;
        }

        .theme-admin & {
          color: $color-primary-darker-admin;
        }

        .active {
          @include add-icon('Aula_check');

          position: absolute;
          padding-top: 5px;
          margin-left: -25px;
          font-size: 22px;
        }

        &:hover {
          color: #b4bccc;
          background-color: #fff;
        }
      }
    }

    .event_resources {
      @include breakpoint-lg-down() {
        margin-top: 10px;
      }

      .el-tag--resource {
        background-color: $color-grey-base;
        border-color: $color-grey-base;
        height: auto;
        padding: 10px 10px 10px 35px;
        min-width: 180px;
        margin: 3px;
        word-wrap: break-word;
        white-space: normal;

        @include breakpoint-lg-down() {
          line-height: 25px;
        }

        .theme-employee & {
          color: $color-primary-darker-employee;
        }

        .theme-guardian & {
          color: $color-primary-darker-guardian;
        }

        .theme-child & {
          color: $color-primary-darker-child;
        }

        .theme-admin & {
          color: $color-primary-darker-admin;
        }

        @include breakpoint-lg-down() {
          display: block;
          border-color: #fff;
        }

        &:hover {
          color: #b4bccc;
          background-color: #fff;
        }

        &::before {
          @include add-icon('Aula_check');

          position: absolute;
          padding-top: 5px;
          margin-left: -25px;
          font-size: 22px;
        }

        .el-icon-close {
          font-size: 16px;
          float: right;
          margin-right: 10px;
          margin-top: 7px;
          color: #b4bccc;

          &:hover {
            color: $color-primary-darker;
            background-color: transparent;
          }

          .active {
            color: $color-primary-darker;
            background-color: transparent;
          }
        }
      }
    }

    .wraptext {
      .el-tag {
        margin-top: 5px;
        height: auto !important;
        width: auto !important;
        white-space: normal !important;
      }
    }

    .modal-fullscreen {
      .modal-aula-backdrop {
        @include breakpoint-lg {
          top: 0%;
        }

        @include breakpoint-lg-down() {
          top: 0%;
        }
      }

      .modal-aula {
        @include breakpoint-lg() {
          top: 5%;
        }

        @include breakpoint-lg-down() {
          top: 0%;
        }
      }
    }
  }
}
//Theme styling
.theme-guardian {
  #calendar .fc-view-container .fc-dayGridMonth-view {
    .fc-widget-header {
      .fc-week-number {
        &.fc-widget-header {
          padding: 0;
          width: 58px !important;
        }
      }

      .fc-day-header {
        border: 0;
      }
    }

    .fc-day-number {
      color: $color-primary-darker-guardian !important;
    }

    .fc-today {
      .fc-day-number {
        background-color: $color-primary-dark-guardian !important;
        color: $color-white !important;
      }
    }
  }

  #calendar,
  #group-calendar,
  #daily-calendar,
  #planning-assistant {
    .fc-header-toolbar {
      border-bottom: 2px solid $color-primary-darker-guardian;

      .fc-right {
        .fc-button {
          color: $color-primary-dark-guardian;
          font-family: Lato;
          font-size: 13px;
          font-weight: bold;
          margin-top: 5px;
        }

        .fc-button-active {
          color: $color-primary-darker-guardian;
          font-family: Lato;
          font-size: 13px;
          font-weight: bold;
        }
      }
    }

    .fc-view-container {
      .fc-head-container {
        .fc-day-header:not(:first-child) {
          background: $color-grey-dark;
          color: $color-primary-darker-child;

          &.fc-today {
            background: $color-primary-darker-guardian;
            color: $color-white;
          }
        }
      }

      /* td.fc-axis {
        background-color: $color-primary-light-guardian;
      } */
      .fc-event-container {
        .fc-event {
          color: $color-primary-darker-guardian !important;

          .day-view-details {
            color: $color-conversation-left !important;
          }

          .day-view-substitute {
            color: $color-alert !important;
          }
        }

        .fc-day-grid-event {
          color: $color-grey-light !important;

          .day-view-details {
            color: $color-grey-light !important;
          }

          background-color: $color-primary-base-guardian !important;
        }
      }
    }
  }
}

.theme-employee {
  #calendar .fc-view-container .fc-dayGridMonth-view {
    .fc-widget-header {
      .fc-week-number {
        &.fc-widget-header {
          padding: 0;
          width: 58px !important;
        }
      }

      .fc-day-header {
        border: 0;
      }
    }

    .fc-day-number {
      color: $color-primary-darker-employee !important;
    }

    .fc-today {
      .fc-day-number {
        background-color: $color-primary-dark-employee !important;
        color: $color-white !important;
      }
    }
  }

  #calendar,
  #group-calendar,
  #daily-calendar,
  #planning-assistant {
    .fc-header-toolbar {
      border-bottom: 2px solid $color-primary-darker-employee;

      .fc-right {
        .fc-button {
          color: $color-primary-dark-employee;
          font-weight: bold;
          font-family: Lato;
          font-size: 13px;
          margin-top: 5px;
        }

        .fc-button-active {
          color: $color-primary-darker-employee;
        }
      }
    }

    .fc-view-container {
      .fc-head-container {
        .fc-day-header:not(:first-child) {
          background: $color-grey-dark;
          color: $color-primary-darker-employee;

          &.fc-today {
            background: $color-primary-darker-employee;
            color: $color-white;
          }
        }
      }

      .fc-event-container {
        .fc-event {
          color: $color-primary-darker-employee !important;

          .day-view-details {
            color: $color-conversation-left !important;
          }

          .day-view-substitute {
            color: $color-alert !important;
          }
        }

        .fc-day-grid-event {
          background-color: $color-primary-base-employee !important;
          color: white !important;

          .day-view-details {
            color: $color-grey-light !important;
          }
        }
      }
    }
  }
}

.theme-child {
  #calendar .fc-view-container .fc-dayGridMonth-view {
    .fc-widget-header {
      .fc-week-number {
        &.fc-widget-header {
          padding: 0;
          width: 58px !important;
        }
      }

      .fc-day-header {
        border: 0;
      }
    }

    .fc-day-number {
      color: $color-primary-darker-child !important;
    }

    .fc-today {
      .fc-day-number {
        background-color: $color-primary-dark-child !important;
        color: $color-white !important;
      }
    }
  }

  #calendar,
  #group-calendar,
  #daily-calendar,
  #planning-assistant {
    .fc-header-toolbar {
      border-bottom: 2px solid $color-primary-darker-child;

      .fc-right {
        .fc-button {
          color: $color-primary-dark-child;
          font-family: Lato;
          font-size: 13px;
          font-weight: bold;
          margin-top: 5px;
        }

        .fc-button-active {
          color: $color-primary-darker-child;
          font-family: Lato;
          font-size: 13px;
          font-weight: bold;
        }
      }
    }

    .fc-view-container {
      .fc-head-container {
        .fc-day-header:not(:first-child) {
          background: $color-grey-dark;
          color: $color-primary-darker-child;

          &.fc-today {
            background: $color-primary-darker-child;
            color: $color-white;
          }
        }
      }

      .fc-event-container {
        .fc-event {
          color: $color-primary-darker-child !important;

          .day-view-details {
            color: $color-conversation-left !important;
          }

          .day-view-substitute {
            color: $color-alert !important;
          }
        }

        .fc-day-grid-event {
          background-color: $color-primary-base-child !important;
          color: #f6f7f8 !important;

          .day-view-details {
            color: $color-grey-light !important;
          }
        }
      }
    }
  }
}

.event-badge {
  position: relative;
  top: 0;
  background-color: $color-alert;
  color: $color-white;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  font-size: 10px;
  margin-right: 3px;
}

.send-reminder-modal {
  --color-base: var(--color-grey-light);
  --padding-top: 0;
}
