html,
body {
  height: 100%;

  &.notice-boards {
    overflow: hidden;
  }
}

.aula-content {
  --menu-item-height: 80px;
  --menu-item-width: 80px;
  --menu-icon-size: var(--default-menu-icon-size);

  @include breakpoint-lg-down() {
    --menu-item-height: 55px;
    --menu-item-width: 100%;
    --menu-icon-size: 1.1rem;
  }
  &[data-menu-size="large"] {
    @include breakpoint-lg() {
      --menu-item-height: 90px;
      --menu-item-width: 90px;
    }
  }
}

.content-wrapper {
  @include breakpoint-lg() {
    margin-left: var(--menu-item-width);
    display: flex;
  }

  .notice-boards & {
    margin-left: unset;
  }

  main {
    position: relative;
    max-width: 100%;
    margin-top: 0;
    width: 1px;
    flex: 1;

    @include breakpoint-lg-down() {
      width: 100%;
    }
  }

  aside {
    position: relative;
    display: none;
    z-index: 1003;

    @include breakpoint-lg() {
      display: block;
      width: $layout-right-normal;
    }

    @include breakpoint-xl() {
      width: $layout-right-wide;
    }
  }
}

.administration-toolbar,
.group_home_admin_toolbar {
  background: $color-grey-base;
  padding: 4px;
  min-height: 58px;
  display: flex;
  align-items: center;
  .row {
    width: 100%;
  }
}

.administration-main-content,
.group_home_admin_main_content {
  min-height: 203px;
  padding: var(--default-layout-padding);

  @include breakpoint-lg() {
    &.groups-result,
    &.users-result {
      min-height: 600px;
    }
  }
}

.page-layout-padding {
  @include breakpoint-lg() {
    padding: var(--page-layout-padding, var(--default-layout-padding));
  }
}
.page-layout-padding[data-layout='posts'] {
  --page-layout-padding: 48px 106px 48px 48px;
}
.page-layout-padding[data-layout='group-search'] {
  --page-layout-padding: 48px 293px 48px 48px;
}
.page-layout-padding[data-layout='group-administration'] {
  --page-layout-padding: 48px 48px 0 48px;
}
.page-layout-padding[data-view='mobile'] {
  @include breakpoint-lg-down() {
    --page-layout-padding: 16px;
    padding: var(--page-layout-padding, var(--default-layout-padding));
  }
}
