






















































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.user {
  position: relative;

  &.inline {
    display: inline-block;
    margin-left: 1px;
  }
}

.user-icon {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 50px;
  width: 50px;

  @include breakpoint-lg-down() {
    width: 35px;
    height: 35px;

    .larger-mobile-user-icon & {
      width: 45px;
      height: 45px;
    }
  }

  .large-user-photo & {
    border-radius: 0;
    height: 200px;
    width: 200px;

    @include breakpoint-sm-down() {
      height: 120px;
      width: 120px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
      object-fit: contain;
      object-position: 50% 50%;

      .not-present & {
        opacity: 0.6;
      }
    }
  }
}

.user-icon .shortname {
  text-transform: uppercase;
  position: absolute;
  display: block;
  line-height: 50px;
  top: calc(50% - 25px);
  width: 100%;
  height: 50px;
  overflow: hidden;
  text-align: center;
  color: $color-white;

  font-size: 1.2em;

  &.length3 {
    font-size: 1em;
  }

  &.length4 {
    font-size: 0.75em;
  }

  &.length5 {
    font-size: 0.6em;
  }
}

.small.user-icon span {
  font-size: 0.7em;
  height: 30px;
}

.user-img {
  position: absolute;
  z-index: $user-img-z-index;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  top: 0;
  left: 0;
  border-radius: 50%;
  overflow: hidden;
  max-height: 52px;
  max-width: 52px;
  object-fit: cover;
  object-position: 50% 4%;
}

.small {
  width: 30px;
  height: 30px;
}

.user-icon {
  background-color: var(--color-primary-dark);
}

//Coloring for posts icon
//institutionRole-postOwnerRole-isOwnPost
.guardian-guardian-true,
.child-child-true {
  background: $color-primary-light-guardian !important;
}

.guardian-guardian-false,
.child-child-false,
.guardian-child-false,
.child-guardian-false {
  color: $color-white !important;
  background: $color-primary-darker-guardian !important;
}

.guardian-employee-false,
.child-employee-false {
  color: $color-white !important;
  background: $color-primary-dark-guardian !important;
}

.employee-employee-true {
  color: $color-white !important;
  background: $color-primary-dark-employee !important;
}

.employee-guardian-false,
.employee-child-false {
  color: $color-white !important;
  background: $color-primary-darker-employee !important;
}

.employee-employee-false {
  color: $color-white !important;
  background: $color-primary-dark-employee !important;
}
