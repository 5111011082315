.create-album-modal {
  > .modal-aula {
    max-height: unset;
    transform: unset;

    > .modal-content {
      .theme-guardian & {
        background-color: $color-primary-base-guardian;
      }

      .theme-child & {
        background-color: $color-primary-base-child;
      }

      .theme-employee & {
        background-color: $color-primary-base-employee;
      }

      overflow: hidden;
      border-radius: 0;

      > .modal-body {
        padding: 0;
        overflow-y: auto;
        max-height: initial;
        -webkit-overflow-scrolling: touch;
        --color-base: var(--color-grey-light);
      }

      .album-details {
        min-height: 180px;
        padding: 30px 50px 20px 50px;
        overflow: auto;

        .icon-Aula_close {
          position: absolute;
          right: 40px;
          top: 30px;
          cursor: pointer;
        }

        .icon-Aula_app_arrow_slideback {
          position: absolute;
          cursor: pointer;
          left: 20px;
          top: 20px;
          z-index: $aula-modal-button-z-index;
        }

        .alert.alert-danger {
          margin-top: -5px;
        }

        input,
        select,
        textarea {
          border: 0;
          font-size: 14px;
        }

        .el-select__tags > input.el-select__input {
          background-color: transparent;
        }

        .el-input__inner {
          margin-bottom: 0;
        }

        @include breakpoint-lg-down() {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $color-grey-base;
          padding: 20px 10px;

          button {
            position: fixed;
            top: 20px;
            right: 20px;
            background: transparent;
            padding: 0;
          }

          h1 {
            font-size: 16px;
            text-transform: uppercase;
            text-align: center;
          }

          .icon-Aula_app_arrow_slideback {
            color: $color-darkblue;
          }

          .next-btn {
            font-weight: bold;
            color: $color-primary-base;
          }

          .alert.alert-danger {
            margin-top: -25px;
          }

          input,
          select,
          textarea {
            border: 0;
            font-size: 14px;

            &.el-select__input {
              min-height: 30px;
            }
          }
        }

        @include breakpoint-lg() {
          color: $color-white;

          .theme-guardian & {
            background-color: $color-primary-dark-guardian;
          }

          .theme-child & {
            background-color: $color-primary-dark-child;
          }

          .theme-employee & {
            background-color: $color-primary-dark-employee;
          }

          .icon-Aula_close {
            color: $color-white;
          }

          .alert.alert-danger {
            color: $color-white;
          }

          input,
          select,
          textarea {
            color: $color-white;

            @include placeholder($color-white);

            .theme-guardian & {
              background-color: $color-primary-base-guardian;
            }

            .theme-child & {
              background-color: $color-primary-base-child;
            }

            .theme-employee & {
              background-color: $color-primary-base-employee;
            }
          }
        }
      }

      .multiple-media-counter {
        top: 0;

        .theme-guardian & {
          color: $color-primary-darker-guardian;
        }

        .theme-child & {
          color: $color-primary-darker-child;
        }

        .theme-employee & {
          color: $color-primary-darker-employee;
        }

        position: absolute;
        padding: 10px 10px 10px 30px;

        i {
          font-size: 22px;
          position: absolute;
          left: 5px;
        }

        @include breakpoint-lg-down() {
          position: relative;
          background-color: transparent;
          padding-left: 30px;
          top: 0;
          color: $color-darkblue;

          i {
            font-size: 22px;
            position: absolute;
            color: $color-lightblue;
          }
        }
      }

      .multiple-media-data {
        min-height: 200px;
        padding: 50px 50px;
        overflow-y: auto;
        overflow-x: hidden;

        .theme-guardian & {
          color: $color-primary-darker-guardian;
        }

        .theme-child & {
          color: $color-primary-darker-child;
        }

        .theme-employee & {
          color: $color-primary-darker-employee;
        }

        background-color: $color-grey-dunkel;

        .icon-Aula_close {
          position: absolute;
          right: 40px;
          top: 30px;
          cursor: pointer;
        }

        .thumb img {
          object-fit: contain;
        }

        .input-group.search-module .input-group-prepend {
          left: 10px;
          max-width: 20px;
          z-index: 1000;
        }

        input {
          background-color: $color-grey-medium;
          border: 0;

          .theme-guardian & {
            color: $color-primary-darker-guardian;
            @include placeholder($color-primary-darker-guardian);
          }

          .theme-child & {
            color: $color-primary-darker-child;
            @include placeholder($color-primary-darker-child);
          }

          .theme-employee & {
            color: $color-primary-darker-employee;
            @include placeholder($color-primary-darker-employee);
          }

          font-size: 14px;

          &.el-select__input {
            background: transparent;
          }
        }

        .el-select__input,
        .el-input__inner {
          text-indent: 20px;
        }

        .el-select__tag {
          padding-left: 30px;
        }

        @include breakpoint-lg-down() {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $color-grey-medium;
          padding: 20px 10px;

          input {
            background-color: $color-white;

            @include placeholder($color-help-text);

            &.el-select__input {
              background: transparent;
            }
          }

          button {
            position: fixed;
            top: 20px;
            right: 20px;
            background: transparent;
            padding: 0;
            color: $color-picker-button-green;
          }
        }
      }

      .album-medias {
        padding: 20px 50px;

        @include breakpoint-lg-down() {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $color-grey-base;
          padding: 20px 10px;

          .album-tag-media-mobile {
            position: fixed;
            top: 20px;
            right: 10px;
            background: transparent;
            padding: 0;
            color: $color-picker-button-green;
          }
        }

        .medias {
          @include breakpoint-lg-down() {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 70vh;
            padding-bottom: 100px;
            -webkit-overflow-scrolling: touch;
          }
        }

        h2 {
          .theme-guardian & {
            color: $color-primary-darker-guardian;
          }

          .theme-child & {
            color: $color-primary-darker-child;
          }

          .theme-employee & {
            color: $color-primary-darker-employee;
          }
        }

        .btn-link {
          color: $color-primary-darker-guardian;

          @include breakpoint-lg-down() {
            color: $color-primary-base-employee;
          }

          > i:not(.icon-Aula_close) {
            background: $color-primary-darker-guardian;
            color: $color-white;
            padding: 5px;
            border-radius: 50%;
          }
        }

        .gallery-consent-delay {
          width: 40vw;
          float: right;

          @include breakpoint-lg-down() {
            width: 60vw;
          }

          @include breakpoint-sm-down() {
            width: 90vw;
          }
        }
      }
    }
  }
}

.media-data-container {
  display: flex;

  .album-media-data {
    background: $color-white;
    border-radius: 8px;
    margin-bottom: 2px;
    flex: 1;
    min-width: 0;
    height: fit-content;
    padding: 5px;

    &.selected {
      box-shadow: 0 2px 8px 0 rgba(166, 166, 166, 0.5);
    }

    @include breakpoint-lg-down() {
      border: 0;
      max-width: calc(100vw - 38px);

      .media-thumbnail.opacity {
        &.selected {
          opacity: 0.5;
        }

        .unselected-placeholder,
        .icon-Aula_check {
          background: $color-white;
          padding: 3px;
          border-radius: 50%;
          color: $color-primary-light;
          position: absolute;
          left: calc(50% - 14px);
          top: calc(50% - 14px);
          font-size: 28px;
          z-index: 1000;
        }
        .unselected-placeholder {
          width: 34px;
          height: 34px;
          opacity: 0.5;
        }
      }

      .message & {
        background-color: $color-white;
      }
    }

    .media-item {
      display: flex;
      align-items: center;
      position: relative;

      @include breakpoint-lg-down() {
        flex-direction: column;
      }

      .aula-spinner {
        width: 60px;
        height: 60px;
      }

      .media-thumbnail {
        $media-thumbnail-image-size-mobile: 200px;
        $media-thumbnail-image-size-mobile-large: 45vw;

        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 8px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background-color: var(--color-grey-base);

        &.image {
          width: 100px;
          height: 100px;

          @include breakpoint-lg-down() {
            width: $media-thumbnail-image-size-mobile;
            height: $media-thumbnail-image-size-mobile;
            &.no-media-data {
              width: 100%;
              height: $media-thumbnail-image-size-mobile;
              img {
                &.vertical-rotated {
                  max-width: $media-thumbnail-image-size-mobile;
                }
                width: $media-thumbnail-image-size-mobile-large;
                height: $media-thumbnail-image-size-mobile;
              }
            }
          }

          &.thumbnail-large {
            width: 400px;
            height: 400px;

            @include breakpoint-lg-down() {
              width: calc(100vw - 38px);
              height: calc(100vw - 38px);
            }
          }
        }

        img {
          object-fit: contain;
          width: 100px;
          height: 100px;

          @include breakpoint-lg-down() {
            width: $media-thumbnail-image-size-mobile;
            height: $media-thumbnail-image-size-mobile;
          }

          &.thumbnail-large {
            width: 400px;
            height: 400px;

            @include breakpoint-lg-down() {
              width: calc(100vw - 38px);
              height: calc(100vw - 38px);
            }
          }
        }

        i {
          font-size: 20px;
        }

        .thumbnail-circle {
          width: 50px;
          height: 50px;
          border-width: 3px;
        }
      }

      .media-data {
        flex: 1;
        padding: 10px;
        overflow: visible;
        min-width: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-self: flex-start;
        @include breakpoint-xs-down() {
          max-width: 100%;
        }

        .media-upload-preview & {
          height: 170px;
        }

        .media-attachment-name {
          font-weight: bold;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .icon-Aula_down-arrow {
          font-size: 0.4rem;
        }

        .dropdown-select .dropdown-menu {
          padding-left: 10px;
          padding-right: 10px;
        }

        .existing-tag {
          display: flex;
          justify-content: space-between;
          position: relative;

          .icon-Aula_close {
            top: 5px;
          }
        }
        input,
        .el-select__input,
        textarea {
          font-size: 14px;
          background-color: $color-grey-light;

          @include placeholder($color-primary-darker-guardian);

          &.el-select__input {
            background: transparent;
          }
        }
      }

      .media-name {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 14px;
        font-weight: bold;
        flex: 1;
        overflow-wrap: anywhere;

        .theme-guardian & {
          color: $color-primary-darker-guardian;
        }

        .theme-child & {
          color: $color-primary-darker-child;
        }

        .theme-employee & {
          color: $color-primary-darker-employee;
        }
      }

      .media-remove {
        cursor: pointer;
        position: absolute;
        z-index: $media-icon-button-z-index;
        bottom: 5px;
        right: 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        padding: 4px;
        background: white;
        opacity: 0.7;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint-lg() {
          position: unset;
          margin-right: 10px;
        }

        i {
          color: $color-primary-darker;
        }
      }
    }
  }
}

.gallery-consent-delay {
  position: relative;
  top: -20px;

  .alert.alert-danger::before {
    top: 14px;
  }
}

.tool-link.large {
  bottom: 25px;
}
