.header-dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--menu-top-bar-button-width);
  height: 100%;
  cursor: pointer;

  i {
    font-size: var(--default-menu-icon-size);
  }

  .header-icon {
    max-width: 30px;
  }
  .badge-notification {
    top: 5px;
    left: 5px;
  }
}

.header-dropdown-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: -1;
  right: 0;
  left: inherit !important;
  top: var(--menu-top-bar-height);
  overflow-y: auto;
  overflow-x: hidden;
  transform: none;
  height: calc(100vh - var(--menu-top-bar-height));
  width: 100%;
  border: none;
  background: var(--color-grey-base);
  color: var(--color-primary-darker);
  box-shadow: var(--box-shadow-base);

  .header-icon {
    max-width: 30px;
  }

  @include breakpoint-lg() {
    width: 360px;
  }

  .header-dropdown-close {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
    line-height: 0px;
  }

  .header-dropdown-item {
    background: $color-grey-dark;
    min-height: 60px;
    max-height: 60px;
    padding: 18px 15px 18px 15px;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 2px;
    font-weight: bold;

    .theme-employee & {
      color: $color-primary-darker-employee;
    }

    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }

    .theme-child & {
      color: $color-primary-darker-child;
    }

    .theme-admin & {
      color: $color-primary-darker-admin;
    }

    &:hover {
      color: $color-white;

      .theme-employee & {
        background-color: $color-primary-darker-employee;
      }

      .theme-guardian & {
        background-color: $color-primary-darker-guardian;
      }

      .theme-child & {
        background-color: $color-primary-darker-child;
      }

      .theme-admin & {
        background-color: $color-primary-darker-admin;
      }

      button {
        color: $color-white;
      }
    }

    &.active {
      background-color: $color-primary-darker;
      color: $color-white;
    }
  }

  &.mobile {
    background-color: $color-primary-dark-guardian;

    .header-dropdown-title {
      background-color: $color-primary-dark-guardian;
      color: $color-white;
      font-size: 13px;
      padding: 17px;

      .header-dropdown-close {
        top: -5px;
      }
    }

    .header-dropdown-item {
      margin-bottom: 0px;
      border-bottom: 1px solid $color-grey-dark;
      position: relative;
    }
  }
}

.header-dropdown-menu-search {
  -ms-overflow-style: none; // IE 10+
  overflow: -moz-scrollbars-none; // Firefox
  &::-webkit-scrollbar {
    display: none;
  }

  right: 0;
  left: inherit !important;
  transform: none;
  width: 100%;
  border: none;
  background: $color-grey-light;
  border-radius: 0;
  padding-top: 0;
  color: $color-primary-darker;
  z-index: 1001;
  text-align: left;
  position: absolute;
  height: 58px;


  @include breakpoint-lg() {
    overflow: auto;
    height: calc(100vh - var(--menu-top-bar-height));
    z-index: -1;
    width: 360px;
    background: $color-grey-base;
    box-shadow: var(--box-shadow-base);
  }

  .header-icon {
    max-width: 30px;
  }

  .header-dropdown-close {
    position: absolute;
    cursor: pointer;
    color: $color-white;
    right: 10px;
    top: 30px;

    @include breakpoint-lg-down() {
      top: 15px;
    }
  }

  &.mobile {
    background-color: $color-primary-dark-guardian;

    .header-dropdown-title {
      background-color: $color-primary-dark-guardian;
      color: $color-white;
      font-size: 13px;
      padding: 17px;
    }

    .header-dropdown-item {
      background-color: $color-primary-light-guardian;
      color: $color-white;
      margin-bottom: 1px;
    }
  }
}

.group-modal .header-dropdown-menu-search {
  z-index: 1001;
}

.header-dropdown-item-search {
  position: relative;
  float: none;
  display: inline-block;
  margin-right: 0;
  height: 40px;
  color: $color-primary-darker;
  background-color: $color-grey-base;
  padding: 10px;
  text-transform: uppercase;
  font-size: 13px;
  margin-bottom: 10px;

  &.active {
    background-color: $color-grey-dark;
    color: $color-primary-darker;
    border: none;
  }

  &.active::after {
    position: absolute;
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    border-top-color: $color-grey-dark;
    border-width: 10px;
    margin-left: -10px;
  }

  @include breakpoint-lg() {
    display: block;
    background: transparent;
    border: 1px solid #c8d6df;
    border-radius: 5px;
    height: 100%;
    color: $color-primary-darker;
    padding: 17px 29px 19px 21px;
    cursor: pointer;
    text-transform: none;
    font-size: 15px;
    margin-bottom: 5px;

    &:hover {
      .theme-employee & {
        background-color: $color-primary-darker-employee;
      }

      .theme-guardian & {
        background-color: $color-primary-darker-guardian;
      }

      .theme-child & {
        background-color: $color-primary-darker-child;
      }

      color: $color-white;
      box-shadow: 0 1px 4px 0 rgba(166, 166, 166, 0.5);

      button {
        color: $color-white;
      }
    }

    &.active,
    &.active:hover {
      background-color: $color-white;
      color: $color-primary-darker;
    }

    &.active::after {
      display: none;
    }
  }
}

.header-dropdown-title {
  padding: 10px;
  height: 50px;
  font-size: 18px;
}

.header-dropdown-item:hover {
  background-color: $color-primary-darker;
  color: $color-white;
}

.dropdown-menu {
  border: none;
  box-shadow: var(--box-shadow-base);
  padding: var(--dropdown-menu-padding, unset);

  &.file-picker-dropdown {
    min-width: max-content;
  }

  .dropdown-header {
    background-color: var(--color-grey-light);
    padding: 0.7rem 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--color-primary-darker);
  }
  .dropdown-item {
    padding: 0.5rem 1rem;
    &:active {
      background-color: var(--color-grey-light);
    }
    &:disabled,
    &.disabled {
      cursor: not-allowed;
      pointer-events: auto;
      background-color: var(--color-grey-light);
      .aula-icon {
        color: var(--color-grey);
      }
      color: var(--color-grey);
    }
  }
  .roles-list {
    max-height: var(--dropdown-menu-max-height, unset);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.dropdown-select {
  padding: var(--dropdown-select-padding,0 5px);

  &.right {
    position: absolute !important;
    right: 0 !important;
    left: auto !important;

    .dropdown-menu {
      transform: translate3d(-55px, -18px, 0) !important;
    }
  }

  &.dropup {
    button.dropdown-toggle::after {
      display: none;
    }
    .dropdown-menu {
      transform: unset !important;
      top: unset !important;
      bottom: 0;
      padding-bottom: 36px;
    }
  }

  .dropdown-menu-right {
    margin-right: -4px;
  }

  .dropdown-toggle > button,
  > button {
    font-size: inherit;
    background-color: var(--dropdown-button-background, transparent) !important;
    border: 0;
    padding: 0;
    margin-bottom: 0;

    &.dropdown-toggle {
      padding: var(--dropdown-toggle-padding, 0 5px);
      height: 30px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
    .theme-employee & {
      color: $color-primary-darker-employee;
    }

    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }

    .theme-child & {
      color: $color-primary-darker-child;
    }

    .theme-admin & {
      color: $color-primary-darker-admin;
    }

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

    &::after {
      display: none;
    }
  }

  &.show {
    .dropdown-toggle {
      position: relative;
      z-index: 1001;
      .theme-guardian & {
        color: $color-primary-base-guardian !important;
      }
      .theme-child & {
        color: $color-primary-base-child !important;
      }
      .theme-employee & {
        color: $color-primary-base-employee !important;
      }
    }
  }

  &:not(.dropup) .dropdown-menu {
    max-width: var(--dropdown-max-width, 120px) !important;
    margin-left: -8px;
    margin-top: -40px;
    padding-top: 48px;
    padding-bottom: 12px;
  }
  &.dropright .dropdown-menu {
    margin-top: -4px;
    margin-left: -28px;
  }

  .dropdown-menu {
    border: none;
    min-width: var(--dropdown-menu-min-width, 10rem);

    .theme-employee & {
      color: $color-primary-darker-employee;
    }

    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }

    .theme-child & {
      color: $color-primary-darker-child;
    }

    .theme-admin & {
      color: $color-primary-darker-admin;
    }
  }

  .dropdown-item {
    background-color: transparent;
    padding: 10px 16px 10px 28px;
    font-size: 0.875rem;
    position: relative;

    .theme-employee & {
      color: $color-primary-darker-employee;
    }

    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }

    .theme-child & {
      color: $color-primary-darker-child;
    }

    .theme-admin & {
      color: $color-primary-darker-admin;
    }

    &:hover {
      background-color: $color-dropdown-focus;
    }

    &.active {
      position: relative;

      .theme-employee & {
        color: $color-primary-darker-employee;
      }

      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }

      .theme-child & {
        color: $color-primary-darker-child;
      }

      .theme-admin & {
        color: $color-primary-darker-admin;
      }

      &::before {
        @include add-icon('Aula_check');

        font-size: 16px;
        position: absolute;
        left: 5px;
        top: 12px;

        .theme-employee & {
          color: $color-primary-light-employee;
        }

        .theme-guardian & {
          color: $color-primary-light-guardian;
        }

        .theme-child & {
          color: $color-primary-light-child;
        }

        .theme-admin & {
          color: $color-primary-light-admin;
        }
      }
    }

    &.disabled,
    &.is-disabled {
      cursor: not-allowed;
      color: $color-help-text;
      background-color: $color-grey-light;

      span {
        color: $color-help-text !important;
      }
    }
  }
}

.dropdown-item {
  .theme-employee & {
    color: $color-primary-darker-employee;
  }

  .theme-guardian & {
    color: $color-primary-darker-guardian;
  }

  .theme-child & {
    color: $color-primary-darker-child;
  }

  .theme-admin & {
    color: $color-primary-darker-admin;
  }
}

.dropdown-simple {
  .dropdown-toggle {
    &::after {
      border: none;
      margin-left: 0;
    }
  }

  .dropdown-menu {
    padding: 0;
    border-radius: 0;

    .dropdown-item {
      min-height: 15px;
      padding: 5px;
    }
  }
}

.dropdown-menu.role-assignment-menu {
  padding: 12px 12px;
  min-width: 400px;

  .roles-list {
    --dropdown-menu-max-height: 400px;

    padding-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .custom-checkbox {
      min-height: auto;
    }
  }
}

.consent-age-dropdown {
  width: 65px;
  padding: 0;
  display: inline-block;
}

//Theme styling
.theme-admin {
  .global-filter.active-filter {
    background: $color-primary-darker-admin;
  }

  .header-dropdown {
    background: $color-primary-dark-admin;

    &:hover,
    &:focus-visible,
    &.active {
      background: $color-primary-darker-admin;
    }

    &.active::after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: $color-primary-darker-admin;
      border-width: 10px;
      margin-left: -10px;
      z-index: 1001;
    }
  }
}

.theme-guardian {
  .global-filter.active-filter {
    background: $color-primary-darker-guardian;
  }

  .header-dropdown {
    background: $color-primary-dark-guardian;

    &:hover,
    &:focus-visible,
    &.active {
      background: $color-primary-darker-guardian;
    }

    &.active::after {
      @include breakpoint-lg() {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: $color-primary-darker-guardian;
        border-width: 10px;
        margin-left: -10px;
        z-index: 1001;
      }
    }
  }
}

.theme-employee {
  .global-filter.active-filter {
    background: $color-primary-darker-employee;
  }

  .header-dropdown {
    background: $color-primary-dark-employee;

    &:hover,
    &:focus-visible,
    &.active {
      background: $color-primary-darker-employee;
    }

    &.active::after {
      @include breakpoint-lg() {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: $color-primary-darker-employee;
        border-width: 10px;
        margin-left: -10px;
        z-index: 1001;
      }
    }
  }
}

.header-dropdown {
  .early-student & {
    /deep/ .menu-icon {
      transition: transform 0.1s ease-in-out;
    }

    &:hover {
      background-color: $color-primary-dark-child;

      /deep/ .menu-icon {
        transform: scale(1.3);
      }
    }
  }
}

.theme-child {
  .global-filter.active-filter {
    background: $color-primary-darker-child;
  }

  .header-dropdown {
    background: $color-primary-dark-child;

    &:hover,
    &:focus-visible,
    &.active {
      background: $color-primary-darker-child;
    }

    &.active::after {
      @include breakpoint-lg() {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: $color-primary-darker-child;
        border-width: 10px;
        margin-left: -10px;
        z-index: 1001;
      }
    }
  }
}

.el-select-dropdown__list {
  padding: 0 0 2px;
}

.b-dropdown {
  &.handle-more-options {
    button.dropdown-toggle {
      background-color: unset;
      color: $color-white;
      padding: 5px;
      display: flex;
      align-items: center;
      i {
        font-size: 0.4rem;
        margin-right: 10px;
      }
    }
  }
}

.dropdown-toggle::after {
  font-family: 'aula';
  content: '\e937';
  font-size: 0.5rem;
  border: none;
  margin: 0 4px;
}
