.tabs-nav {
  border-bottom-width: var(--border-bottom-width, 2px);
  border-bottom-color: var(--color-primary-darker);
  border-bottom-style: solid;
}

.toolbar {
  .buttons {
    gap: 12px;
  }
}

.tab-item {
  font-weight: bold;
  position: relative;
  color: var(--color-primary-dark);
  font-size: 14px;
  border-radius: 0;
  padding: 0.4rem 0;

  &:hover, &:focus, &:not(:disabled):not(.disabled):active {
    color: var(--color-primary-darker);
    border: 0;
  }

  &.active-tab {
    color: var(--color-primary-darker);

    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-left: var(--icon-size, 8px) solid transparent;
      border-right: var(--icon-size, 8px) solid transparent;
      border-top: var(--icon-size, 8px) solid var(--color-primary-darker);
      left: calc(50% - var(--icon-size, 8px));
      bottom: calc((var(--icon-size, 8px) + 1px) * -1);
      z-index: 1;
      clear: both;
    }
  }
}
