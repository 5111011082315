#aula-consent,
#aula-additional-data {
  background-color: $color-grey-light;

  .consent-header-container {
    position: relative;
    margin-bottom: 20px;
    min-height: 50px;

    .consent-header {
      display: flex;

      .consent-title {
        flex-grow: 1;
      }
    }

    button {
      height: 50px;
    }

    .consent-header-mobile {
      position: fixed;
      top: 0;
      left: 0;
      z-index: $consent-header-mobile-z-index;
      width: 100%;
      border-bottom: 1px solid $color-grey-base;

      .consent-view-mode-header {
        .theme-guardian & {
          background-color: $color-primary-darker-guardian;
        }

        .theme-child & {
          background-color: $color-primary-darker-child;
        }

        .theme-employee & {
          background-color: $color-primary-darker-employee;
        }

        color: $color-white;

        .text-key {
          font-weight: bold;
          font-size: 15px;
        }

        .right-text {
          font-size: 13px;
          text-transform: none;
        }
      }

      .consent-edit-mode-header {
        color: $color-white;

        .text-key {
          font-weight: bold;
          font-size: 15px;
        }

        .right-text {
          font-weight: bold;
        }

        .right-text,
        .left-text {
          font-size: 13px;
        }
      }

      .btn-revision-history {
        margin-top: -3px;
        height: 36px;
      }
    }
  }

  .consent-table-mobile {
    position: fixed;
    top: 58px;
    left: 0;
    height: calc(100vh - 58px);
    width: 100%;
    z-index: 1033;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* hide scrollbars in IE 10+ */
    &.view-revision-history {
      top: 92px !important;
    }

    &::-webkit-scrollbar {
      width: 0; /* remove scrollbar space */
      background: transparent; /* optional: just make scrollbar invisible */
    }

    @-moz-document url-prefix() {
      /* detect Firefox to hide the scrollbar */
      margin-right: -17px;
    }

    &.view-mode {
      background-color: $color-grey-light;
    }
  }
}
