



















































































@import '../assets/scss/core/_variables.scss';
@import '../assets/scss/core/_breakpoints.scss';
@import '../assets/scss/components/alerts/_alerts.scss';

.mobile-topbar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 58px;
  height: 58px;
  width: 100vw;
  text-transform: uppercase;
  padding: 0 20px;
  background-color: var(--color-primary-darker);

  .first-column {
    max-width: 75px;
    margin-right: auto;
    padding: 0 5px;
  }

  .mid-column {
    padding: 0 5px;
    flex: 1;
    min-width: 0;
  }

  .last-column {
    max-width: 80px;

    .last-column-text-container {
      position: relative;
      min-height: 40px;
      min-width: 35px;
    }

    .aula-spinner {
      position: absolute;
      right: calc(50% - 30px);
      top: -10px;
      width: 60px;
      height: 60px;
    }
  }
}
