






































































@import '../assets/scss/core/breakpoints.scss';

.close-button {
  color: var(--color-darkblue);
  align-self: flex-start;
  padding-top: 3px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 10px;

  .header-title {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.aula-popover-variables {
  // general
  --aula-popover-desktop-min-width: 520px;
  --aula-popover-mobile-min-width: 300px;
  --aula-popover-color: var(--color-primary-darker);
  // header
  --aula-popover-header-text-transform: none;
  --aula-popover-header-background: var(--color-white);
  // content
  --aula-popover-content-background: var(--color-grey-base);
  --aula-popover-content-line-height: 1.1rem;

  &[data-popover='help-box'] {
    --aula-popover-desktop-min-width: 280px;
    --aula-popover-mobile-min-width: 280px;
    --aula-popover-header-text-transform: uppercase;
  }

  &[data-popover='group-members-overlay'] {
    --aula-popover-header-text-transform: uppercase;
    --aula-popover-content-line-height: 22.5px;
  }

  &[data-popover='message-recipients-overlay'] {
    --aula-popover-content-line-height: 22.5px;
    --aula-popover-margin-top: 12px;
  }
}

.aula-popover {
  font-family: 'Lato', sans-serif;
  height: auto;
  min-width: var(--aula-popover-mobile-min-width);
  box-shadow: var(--box-shadow-base);
  border: none;
  border-radius: 8px;
  margin-top: var(--aula-popover-margin-top);

  @include breakpoint-sm-up() {
    min-width: var(--aula-popover-desktop-min-width);
  }

  /deep/ .popover-header {
    padding: 16px 24px;
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    line-height: 24px;
    background-color: var(--aula-popover-header-background);
    border-radius: 8px 8px 0 0;
    text-transform: var(--aula-popover-header-text-transform);
    color: var(--aula-popover-color);
  }

  /deep/ .popover-body {
    padding: 0;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.02rem;
    line-height: var(--aula-popover-content-line-height);
    font-size: 16px;
    background-color: var(--aula-popover-content-background);
    border-radius: 0 0 8px 8px;
    max-height: 60vh;
    color: var(--aula-popover-color);
  }

  .aula-popover-body {
    padding: 24px;
    overflow-y: auto;
    max-height: inherit;

    &.scrollbar::-webkit-scrollbar {
      background-color: var(--color-grey-light);
    }
  }

  &[x-placement='left'] > /deep/ .arrow::after {
    border-left-color: var(--aula-popover-content-background);
  }
  &[x-placement='left'] > /deep/ .arrow::before {
    border-left-color: var(--color-grey-darkest);
  }

  &[x-placement='right'] > /deep/ .arrow::after {
    border-right-color: var(--aula-popover-content-background);
  }
  &[x-placement='right'] > /deep/ .arrow::before {
    border-right-color: var(--color-grey-darkest);
  }

  &[x-placement='bottom'] > /deep/ .arrow::after {
    border-bottom-color: var(--color-white);
  }
  &[x-placement='bottom'] > /deep/ .arrow::before {
    border-bottom-color: var(--color-grey-darkest);
  }

  &[x-placement='top'] > /deep/ .arrow::after {
    border-top-color: var(--aula-popover-content-background);
  }
  &[x-placement='top'] > /deep/ .arrow::before {
    border-top-color: var(--color-grey-darkest);
  }
}
