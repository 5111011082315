.aula-search-outer {
  .aula-search {
    i {
      position: absolute;
      right: 10px;
      z-index: 1;
      font-size: 18px;

      &.icon-Aula_search {
        top: 17px;
      }
    }
  }
}

.el-select-dropdown {
  border: none;
  color: $color-primary-darker;

  @include breakpoint-xs-down() {
    max-width: 100vw !important;
  }

    max-width: var(--select-dropdown-max-width,500px) !important;
    width: 0;

  .el-select-group__wrap::after {
    display: none;
  }

  .el-select-group__wrap:not(:last-of-type) {
    padding-bottom: 0;
  }

  &.is-multiple {
    .el-select-dropdown__item {
      padding-right: 50px;
      padding-left: 33px;
      color: $color-primary-darker;

      &::before {
        background: $color-white;
        color: transparent;
        border: 1px solid $color-help-text;
      }

      &.selected,
      &.is-selected,
      &[data-selected="true"] {
        color: $color-primary-darker;
        &::before {
          color: white;
          background: var(--color-primary-dark);
        }
      }
    }
    .el-select-dropdown__list{
      padding: 0 0 2px;
    }
  }

  .el-select-dropdown__item {
    color: $color-primary-darker;
    font-size: 15px;
    min-height: 40px;
    padding-top: 3px;

    &.child {
      padding-left: 60px;
    }

    &.child-member {
      padding-left: 90px;
    }

    &.is-hidden {
      display: none;
    }

    &.with-checkbox::before {
      position: relative;
      background: transparent;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-right: 5px;
      color: transparent;
      padding: 3px;
      font-family: element-icons;
      content: "\e6da";
      font-size: 12px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &.no-checkbox {
      &::before {
        content: "";
        width: 0;
        height: 0;
        padding: 0;
        border: 0;
      }
    }

    i {
      position: absolute;
      right: 10px;
      font-size: 18px;

      &.get-child-relatives {
        margin-top: 4px;
        padding: 5px;
        font-size: 1rem;
      }
    }

   span {
     position: relative;
     z-index:0;
   }
  }

  .el-select-dropdown__item.selected {
    &::after {
      display: none;
    }
  }

  .el-select-group__title {
    background: $color-grey-base;
    text-transform: uppercase;
    color: $color-darkblue;
    font-size: 14px;
    padding-left: 10px;
    line-height: 40px;
    font-weight: 700;
    .custom-control-label {
      &:before, &:after {
        top: 7px;
      }
    }
  }
}
